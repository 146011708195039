import type { Entity } from '@apsys/gazelle'

import type { ContentLayoutEntity } from './registry'
import { CONTENT_LAYOUT_REGISTRY } from './registry'

import type { Loader as ContentLoader } from 'schemas/eileen-service'
import type { ISlottedContent } from 'schemas/ocelot-content'
import { debugLog } from 'utils/debug'
import type { ConfigOptions } from 'utils/settings'

export type SlottedContentEntity = Entity<any> & ISlottedContent
/**
 * A content slot name.
 *
 * A list of valid slot names for Empower apps is documented in ADR192.
 *
 * @see https://artprocessors.atlassian.net/wiki/spaces/APS/pages/1830158375/ADR192+Configurable+feature+flags+content+slots+and+class-names+in+Empower
 */
export type OneOrMoreSlotNames = string | string[]

type Props = {
  /** Content loader */
  contentBundle: ContentLoader | null
  /** Entity to retrieve the slot for */
  entity: SlottedContentEntity
  /** Slot name, or list of slot names to try in order */
  slotName: OneOrMoreSlotNames
  /** Settings block (from {@link useSettings}) */
  settings: ConfigOptions
}

/**
 * Retrieve the content layout for the first slot name that has content.
 *
 * Will return the first content layout for `entity` that is implemented,
 * in the bundle and has content (checked via the `hasContent` method of the
 * respective layout).
 */
export const getContentLayout = ({
  contentBundle,
  entity,
  slotName,
  settings,
}: Props): ContentLayoutEntity | null => {
  if (!contentBundle) return null

  const slotNames = Array.isArray(slotName) ? slotName : [slotName]

  // For every slot
  for (const candidate of slotNames) {
    // Find this content slot
    for (const { slot, content: contentRef } of entity.content) {
      if (candidate !== slot) continue

      // If we have a handler for this layout type
      const hasContent = CONTENT_LAYOUT_REGISTRY.get(
        contentRef.typename,
      )?.hasContent

      if (!hasContent) continue

      // And if it has published content
      const content = contentBundle.get(contentRef)
      if (!content) continue

      // And the layout itself has content
      if (!hasContent({ layout: content, contentBundle, settings })) {
        continue
      }

      debugLog('Rendering contentSlot:', slot)
      return content
    }
  }

  return null
}
