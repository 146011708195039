import { LazyLoadImage } from 'react-lazy-load-image-component'
import styled, { css } from 'styled-components'

import type { ImageWithFallbackProps } from './types'

import { Text } from 'components/typography/Text'
import { borderRadius, color, fontWeight } from 'theme/utils'

export const StyledLazyLoadedImage = styled(LazyLoadImage)<{
  $fit?: ImageWithFallbackProps['fit']
}>`
  width: 100%;
  height: 100%;
  opacity: 0;
  opacity: var(--image-opacity);
  transition: opacity 200ms ease;
  ${({ $fit }) =>
    !!$fit &&
    css`
      object-fit: ${$fit};
      display: flex;
      width: 100%;
    `}
`

export const PlaceHolder = styled(Text)`
  transition: opacity 200ms ease;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;

  opacity: var(--image-placeholder-opacity);
  border-radius: var(
    --image-with-fallback-border-radius,
    ${borderRadius('small')}
  );

  font-weight: ${fontWeight('bold')};
`

export const ImageWrapper = styled.div<{
  $background?: ImageWithFallbackProps['background'] | 'error'
  $loaded?: boolean
}>`
  --image-opacity: 0;
  --image-placeholder-opacity: 1;

  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${color.onSurface('onSurfaceHigh')};
  // Hero Image uses this to remove the radius
  border-radius: var(
    --image-with-fallback-border-radius,
    ${borderRadius('small')}
  );

  background: ${({ $background }) =>
    $background ? color.all($background) : 'transparent'};
  overflow: hidden;
  transition: background-color 200ms ease;

  ${({ $loaded }) => css`
    ${$loaded &&
    css`
      --image-opacity: 1;
    `}
  `}
`
