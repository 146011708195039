import { useMemo } from 'react'

import { DynamicBlock } from './DynamicBlock'
import { StaticBlock } from './StaticBlock'
import { HeroImageClassName } from './types'

import { useAppContext } from 'context/AppContext'
import type { ContentBlockProps } from 'registrations/content-layout/content-stack'
import type { CarouselBlock } from 'schemas/ocelot-content'

/**
 * carousel-block **block** (part of `content-stack`). This should be looked
 * up via {@link CONTENT_BLOCK_REGISTRY}.
 */
export const Block = ({
  block,
  allowedHtmlTags,
}: ContentBlockProps<CarouselBlock>): JSX.Element | null => {
  const { contentBundle } = useAppContext()

  /** Resolved picture entities + captions */
  const pictures = useMemo(
    () =>
      block.pictures.flatMap(({ picture: pictureRef, caption }) => {
        const picture = contentBundle?.get(pictureRef)
        if (!picture) return []

        return {
          picture,
          caption,
        }
      }),
    [block.pictures, contentBundle],
  )

  /** Determine the fit class to use */
  const fit = useMemo(() => {
    const candidates = new Set<string>(Object.values(HeroImageClassName))

    const klass = block.className
      ?.split(' ')
      .find(elem => candidates.has(elem))

    // We know that `klass` is in the set `HeroImageClassName`
    return klass as HeroImageClassName | undefined
  }, [block.className])

  switch (pictures.length) {
    case 0:
      return null

    case 1:
    case 2:
      return (
        <StaticBlock
          picture1={pictures[0]}
          picture2={pictures[1]}
          allowedHtmlTags={allowedHtmlTags}
          fit={fit}
        />
      )

    default:
      return (
        <DynamicBlock
          pictures={pictures}
          allowedHtmlTags={allowedHtmlTags}
          fit={fit}
        />
      )
  }
}
