import styled, { css } from 'styled-components'

import type { CSSClamp } from '../mixins'
import { cssClampMixin, hyphenateMixin } from '../mixins'

import type {
  ThemeFontSize,
  ThemeFontWeight,
  ThemeOnSurfaceColor,
  ThemeAccentColor,
  ThemeBaseColor,
} from 'theme/theme'
import { fontFamily, fontSize, fontWeight, color } from 'theme/utils'

type Props = {
  /**
   * Changes the type of rendered element.
   */
  as: 'p' | 'span'
  /**
   * Visually changes the text size (opposed to using `as` which changes the semantic level)
   */
  $size?: Extract<ThemeFontSize, 'xSmall' | 'small' | 'medium' | 'large'>
  /**
   * The font weight of the text
   */
  $weight?: Extract<ThemeFontWeight, 'regular' | 'bold'>
  /**
   * Changes the color of the title
   */
  $color?: ThemeAccentColor | ThemeOnSurfaceColor | ThemeBaseColor
} & CSSClamp

/**
 * Text component
 *
 * For italics, use the `<i>` or `<em>` elements as appropriate. Why? Because in many cases
 * only a portion of the text needs to be italicized, and it's easier to do that with an element. Also, semantics.
 */
export const Text = styled.p<Props>`
  font-family: ${fontFamily('base')};
  font-size: ${fontSize('base')};
  font-weight: ${fontWeight('semibold')};
  line-height: 1.32;
  color: ${color.onSurface('onSurfaceHigh')};

  ${({ $size = 'large' }) => {
    switch ($size) {
      case 'xSmall':
        return css`
          font-size: ${fontSize('xSmall')};
        `
      case 'small':
        return css`
          font-size: ${fontSize('small')};
        `
      case 'medium':
        return css`
          font-size: ${fontSize('medium')};
        `
      case 'large':
        return css`
          font-size: ${fontSize('base')};
          line-height: 1.26;
        `
    }
  }}

  ${({ $weight = 'regular' }) => {
    switch ($weight) {
      case 'regular':
        return css`
          font-weight: ${fontWeight('regular')};
        `
      case 'bold':
        return css`
          font-weight: ${fontWeight('bold')};
        `
    }
  }}

  // Special case for xSmall when used with regular weight
  ${({ $weight = 'regular', $size }) => css`
    ${$weight === 'regular' &&
    $size === 'xSmall' &&
    css`
      font-weight: ${fontWeight('medium')};
    `}
  `}
  

  ${({ $color = 'onSurfaceHigh' }) => css`
    color: ${color.all($color)};
  `}

  ${cssClampMixin}
  ${hyphenateMixin}
`
