import { useMemo } from 'react'

import type { TFunction, TOptions } from 'i18next'
import { useTranslation } from 'react-i18next'

type $Dictionary<T = any> = {
  [key: string]: T
}

type LocaleTranslationFnArgs =
  | []
  | [options: TOptions]
  | [options: TOptions & $Dictionary & { defaultValue: string }]
  | [defaultValue: string, options?: TOptions & $Dictionary]

type LocaleTranslationFn = (
  key: string,
  ...args: LocaleTranslationFnArgs
) => string

type UseLocaleTranslationReturn = {
  _: LocaleTranslationFn
  t: TFunction
}

/**
 * A utility hook that returns a translation function whose key is prefixed with
 * the `paths` parameter.
 *
 * When an array of `paths` is passed as the parameter, the function will loop
 * over the `paths` until it finds a corresponding translation.
 *
 * @category i18n
 */
export const useLocaleTranslation = (
  paths: string | string[],
): UseLocaleTranslationReturn => {
  const { t } = useTranslation()

  return useMemo(() => {
    const pathsArray = Array.isArray(paths) ? paths : [paths]

    const _: LocaleTranslationFn = (key, ...args) => {
      // Generate an array of all possible keys
      const keys = pathsArray.map(path => [path, key].join('.'))

      return t(keys, ...args)
    }

    return { _, t }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t, ...paths])
}
