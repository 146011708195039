import type { MutableRefObject } from 'react'
import { useEffect, useMemo, useRef } from 'react'

const isHtmlElement = <TElement extends HTMLElement = HTMLElement>(
  input: TElement | MutableRefObject<TElement> | typeof window,
): input is TElement => {
  return typeof (input as TElement).tagName === 'string'
}

const isWindowObject = <TElement extends HTMLElement = HTMLElement>(
  input: TElement | MutableRefObject<TElement> | typeof window,
): input is typeof window => {
  const el = input as typeof window
  return !!el.document && !!el.JSON
}

export interface FullEventMap extends HTMLElementEventMap, WindowEventMap {}

export function useDomEventListener<
  K extends keyof FullEventMap,
  TElement extends HTMLElement = HTMLElement,
>(
  eventName: K,
  handler: (this: TElement, ev: FullEventMap[K]) => void,
  element: TElement | MutableRefObject<TElement> | typeof window = window,
) {
  const htmlElement = useMemo(() => {
    if (isHtmlElement(element) || isWindowObject(element)) {
      return element
    }

    return element.current
  }, [element])

  // Create a ref that stores handler
  const savedHandler = useRef(handler)
  // Update ref.current value if handler changes.
  // This allows our effect below to always get latest handler ...
  // ... without us needing to pass it in effect deps array ...
  // ... and potentially cause effect to re-run every render.
  useEffect(() => {
    savedHandler.current = handler
  }, [handler])

  useEffect(
    () => {
      // Make sure element supports addEventListener
      // On
      const isSupported = htmlElement && htmlElement.addEventListener
      if (!isSupported) return
      // Create event listener that calls handler function stored in ref
      const eventListener: EventListenerOrEventListenerObject = event =>
        (savedHandler.current as EventListener)(event)
      // Add event listener
      htmlElement.addEventListener(eventName, eventListener)
      // Remove event listener on cleanup
      return () => {
        htmlElement.removeEventListener(eventName, eventListener)
      }
    },
    [eventName, htmlElement], // Re-run if eventName or element changes
  )
}
