import styled from 'styled-components'

import { space } from 'theme/utils'

/** CSS for the entire content stack */
export const LayoutWrapper = styled.article`
  display: flex;
  flex-direction: column;

  gap: ${space(10)};

  text-align: center;
`
