import { CorePalette, argbFromHex } from '@material/material-color-utilities'

import {
  darkColors,
  lightColors,

} from './color'
import type {
  OnSurfaceColors,
  SurfaceColors, AccentColors, BaseColors } from './color'
import { pxToRem } from './utils'

import { EileenColorScheme } from 'schemas/eileen-service-stylesheet'

// TODO: This is a temporary type that we'll use until the schema is updated
type ColorScheme = Pick<
  EileenColorScheme.IVariant,
  'mode' | 'interactiveColor' | 'primaryTextColor'
>

const defaultColorVariant = {
  interactiveColor: '#000000',
  primaryTextColor: '#000000',
}

/**
 * Shape of the styled-components theme. These are mostly generated from
 * Eileen stylesheets.
 *
 * You should *always* access the theme from this shape, via `useTheme` or
 * {@link theme}, {@link color}, etc. inside styled components. *Never* use
 * `themeVariant` from the `AppContext` directly, because we may be rendering
 * a different variant to the preferred variant (i.e. we're on light, object
 * only has a dark style).
 * If you must get a theme variant, use `const { mode } = useTheme()` rather
 * than getting it from the `AppContext`
 */
export type ThemeInterface = {
  /** ID of the style (from the Gazelle bundle) */
  id: string
  /** Light or dark, use this instead of `AppContext.themeVariant` */
  mode: EileenColorScheme.Mode
  /** Path for a logo, matching `mode` */
  logoPath: string
  /**
   * MD3 Color System colors.
   * For more information about the colour system, refer to https://m3.material.io/styles/color/system/how-the-system-works'
   * */
  color: BaseColors & AccentColors & SurfaceColors & OnSurfaceColors

  /** Font properties */
  font: {
    /** Font weights */
    weight: {
      regular: number
      medium: number
      semibold: number
      bold: number
    }

    /** Font sizes */
    size: {
      xSmall: string
      small: string
      medium: string
      base: string
      large: string
      xLarge: string
      x2Large: string
      x3Large: string
      x4Large: string
    }

    /** Font families */
    family: {
      base: string
    }
  }

  /** Breakpoints */
  breakpoints: {
    small: string
    medium: string
    large: string
    xLarge: string
    verticalSmall: string
    verticalXSmall: string
  }

  /** Breakpoints */
  heightBreakpoints: {
    xSmall: string
    small: string
  }

  /** Border Radius */
  radius: {
    small: string
    medium: string
    large: string
    full: string
  }
}

export const generateTheme = (
  id: string,
  variant: ColorScheme,
): ThemeInterface => {
  const { interactiveColor, primaryTextColor, mode } = variant

  const isDark = mode === EileenColorScheme.Mode.DARK

  const interactivePalette = CorePalette.of(argbFromHex(interactiveColor))
  const textColorPalette = CorePalette.of(argbFromHex(primaryTextColor))

  const logoPath = isDark ? '/logos/logo-dark.png' : '/logos/logo.png'

  const color = isDark
    ? darkColors(
        primaryTextColor,
        interactiveColor,
        textColorPalette,
        interactivePalette,
      )
    : lightColors(
        primaryTextColor,
        interactiveColor,
        textColorPalette,
        interactivePalette,
      )

  return {
    id,
    mode,
    logoPath,
    color: { ...color, inherit: 'inherit', transparent: 'transparent' },

    font: {
      weight: {
        regular: 400,
        medium: 500,
        semibold: 600,
        bold: 700,
      },
      size: {
        x4Large: pxToRem(36),
        x3Large: pxToRem(28),
        x2Large: pxToRem(24),
        xLarge: pxToRem(22),
        large: pxToRem(20),
        base: pxToRem(17),
        medium: pxToRem(14),
        small: pxToRem(12),
        xSmall: pxToRem(11),
      },
      family: {
        base: 'Figtree, DM Sans, sans-serif',
      },
    },
    breakpoints: {
      small: pxToRem(320),
      medium: pxToRem(768),
      large: pxToRem(1240),
      xLarge: pxToRem(1440),
      verticalXSmall: pxToRem(568),
      verticalSmall: pxToRem(660),
    },
    heightBreakpoints: {
      xSmall: pxToRem(568),
      small: pxToRem(660),
    },
    radius: {
      small: pxToRem(4),
      medium: pxToRem(8),
      large: pxToRem(16),
      // This follows the design system
      full: pxToRem(999),
    },
  }
}

export const defaultTheme = generateTheme('default', {
  ...defaultColorVariant,
  mode: EileenColorScheme.Mode.LIGHT,
})

export const defaultDarkTheme = generateTheme('default', {
  ...defaultColorVariant,
  mode: EileenColorScheme.Mode.DARK,
})

/** A valid color key in the theme */
export type ThemeColor = keyof ThemeInterface['color']
/** A valid base color key in the theme */
export type ThemeBaseColor = keyof BaseColors
/** A valid accent color key in the theme */
export type ThemeAccentColor = keyof AccentColors
/** A valid onSurface color key in the theme */
export type ThemeOnSurfaceColor = keyof OnSurfaceColors
/** A valid surface color key in the theme */
export type ThemeSurfaceColor = keyof SurfaceColors
/** A valid font family in the theme */
export type ThemeFontFamily = keyof ThemeInterface['font']['family']
/** A valid font weight in the theme */
export type ThemeFontWeight = keyof ThemeInterface['font']['weight']
/** A valid font size in the theme */
export type ThemeFontSize = keyof ThemeInterface['font']['size']
/** A valid breakpoint in the theme */
export type ThemeBreakpoints = keyof ThemeInterface['breakpoints']
export type ThemeHeightBreakpoints = keyof ThemeInterface['heightBreakpoints']
/** A valid radius in the theme */
export type ThemeRadius = keyof ThemeInterface['radius']

/** All valid keys in the theme */
export type ThemeInterfaceKeys =
  | `color.${ThemeColor}`
  | `font.weight.${ThemeFontWeight}`
  | `font.family.${ThemeFontFamily}`
  | `font.size.${ThemeFontSize}`
  | `breakpoints.${ThemeBreakpoints}`
  | `radius.${ThemeRadius}`

declare module 'styled-components' {
  export interface DefaultTheme extends ThemeInterface {} // extends the global DefaultTheme with our ThemeType.
}
