// GENERATED BY process-svg DO NOT EDIT
import { GeneratedIcon } from './Icon'
import type { GeneratedIconProps } from './Icon/types'
// @ts-ignore
import Svg from './svg/ic-sync-problem-outline.svg?react'

export const IconSyncProblemOutline = (props: GeneratedIconProps): JSX.Element => (
  <GeneratedIcon {...props}>
    <Svg preserveAspectRatio="xMidYMid meet" />
  </GeneratedIcon>
)

IconSyncProblemOutline.displayName = 'IconSyncProblemOutline'
