import styled from 'styled-components'

/**
 * A container to prevent the button from filling the entire width of the
 * view.
 */
export const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 auto;
`
