import styled, { css } from 'styled-components'

import { VISUALIZER_TYPES } from './types'

import { space } from 'theme/utils'

export const VisualizerContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform-origin: 0 0;
  z-index: 0;
  overflow: hidden;
  pointer-events: none;
  filter: blur(14px);
`

export const visualizerStyles = {
  [VISUALIZER_TYPES.BAR]: css`
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  `,
  [VISUALIZER_TYPES.RADIAL]: css`
    top: 50%;
    left: 50%;
    // offset y axis to align center with player UI
    transform: translate(-50%, calc(-50% - ${space(28)}));
  `,
}

export const VisualizerCanvas = styled.canvas<{ type: VISUALIZER_TYPES }>`
  position: relative;
  right: 0;
  bottom: 0;
  ${({ type }) => visualizerStyles[type]}
`
