import type { FunctionComponent } from 'react'

import { Helmet, HelmetProvider } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import { Outlet } from 'react-router-dom'

import { AppContextProvider } from './context/AppContext'
import { gaInit, gaSendPageview } from './helpers/analytics'
import { useEffectOnce } from './hooks/useEffectOnce'
import { Layout } from './layouts/Layout'
import { sentryInit } from './utils/sentry'

import { RefProvider } from 'context/RefContext'
import { getIsIOS } from 'helpers/ua'

sentryInit()

gaInit()

const isIOS = getIsIOS()

const App: FunctionComponent = () => {
  const {
    i18n: { language },
  } = useTranslation()

  useEffectOnce(() => {
    // Disable pinch zoom on iOS.
    // iOS Pinch and zoom on sites causes site reloads and crashes on most sites.
    // https://bugs.webkit.org/show_bug.cgi?id=172206
    // We only disable on iOS as disabling on android will cause issues with the
    // browsers drag to refresh feature.
    if (isIOS) {
      document.addEventListener(
        'touchmove',
        (event) => {
          // @ts-ignore - iOS event property only
          if (event.scale !== 1) {
            event.preventDefault()
          }
        },
        { passive: false },
      )
    }

    gaSendPageview(location.pathname)
  })

  return (
    <AppContextProvider>
      <HelmetProvider context={{}}>
        <Helmet
          // Set lang to the current UI language
          // N.B. content fields will be set to the appropriate content
          // language as required
          htmlAttributes={{ lang: language }}
        />
        <RefProvider>
          <Layout>
            <Outlet />
          </Layout>
        </RefProvider>
      </HelmetProvider>
    </AppContextProvider>
  )
}

export default App
