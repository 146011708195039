import get from 'lodash/get'
import memoize from 'lodash/memoize'

import { BASE_FONT_SIZE } from './constants'
import type {
  ThemeSurfaceColor,
  ThemeBreakpoints,
  ThemeFontFamily,
  ThemeFontSize,
  ThemeFontWeight,
  ThemeInterface,
  ThemeInterfaceKeys,
  ThemeRadius,
  ThemeOnSurfaceColor,
  ThemeHeightBreakpoints,
  ThemeColor,
  ThemeAccentColor,
} from './theme'

import { EileenColorScheme } from 'schemas/eileen-service-stylesheet'

const PLANES = {
  base: 0,
  floating: 1,
  under: -1,
  overlay: 100,
  dialog: 200,
  debug: 1000,
}

export type Plane = keyof typeof PLANES

export const SPACING_MULTIPLIER = 4

// This is a hard list of spacings that we want to use in our app.
// We don't want to use any other spacings, so we're going to enforce
// this list by creating a type that can only be one of these values.
type SPACINGS = [
  0, // 0px
  0.25, // 1px
  0.5, // 2px
  1, // 4px
  1.5, // 6px
  2, // 8px
  2.5, // 10px
  3, // 12px
  3.5, // 14px
  4, // 16px
  5, // 20px
  6, // 24px
  7, // 28px
  8, // 32px
  9, // 36px
  10, // 40px
  11, // 44px
  12, // 48px
  14, // 56px
  16, // 64px
  18, // 72px
  20, // 80px
  24, // 96px
  28, // 112px
  32, // 128px
]

export type Spacing = SPACINGS[number]

/**
 *
 * So that we can still know what's going on when looking at figma / designs in px values,
 * run this function on any px value to convert it to rem.
 * @param px
 * @returns a rem converted string for consumption
 */
export const pxToRem = (px: number): string => `${px / BASE_FONT_SIZE}rem`

/**
 * This is the signature for functions like padding and margin
 * with multiple overloads that works like padding/margin and
 * makes it clear what each argument is.
 */
interface MultiSpaceFunction {
  /**
   * @param space - The space for all sides
   */
  (space: Spacing): string
  /**
   * @param topAndBottom - The space for top and bottom
   * @param leftAndRight - The space for left and right
   */
  (topAndBottom: Spacing, leftAndRight: Spacing): string
  /**
   * @param top - The space for top
   * @param leftAndRight - The space for left and right
   * @param bottom - The space for bottom
   */
  (top: Spacing, leftAndRight: Spacing, bottom: Spacing): string
  /**
   * @param top - The space for top
   * @param right - The space for right
   * @param bottom - The space for bottom
   * @param left - The space for left
   */
  (top: Spacing, right: Spacing, bottom: Spacing, left: Spacing): string
}

/**
 * This is the multi-overload definition for the space function.
 */
interface SpaceFunction {
  /**
   * This returns the rem suffix by default.
   * @param input - The space that will be multiplied by 4 and converted to rem
   */
  (input: Spacing): string
  /**
   * @param input - The space that will be multiplied by 4 and converted to rem
   * @param suffix - Whether or not to add the rem suffix
   */
  (input: Spacing, suffix: boolean): string
}

/**
 * Space utility that works with a multiple of 4 and returns a rem value.
 *
 * @see {@link padding} for padding
 */
export const space: SpaceFunction = memoize(
  (input: Spacing, suffix: boolean = true) => {
    const result = input * SPACING_MULTIPLIER
    return suffix ? pxToRem(result) : `${result}`
  },
  (input: Spacing, suffix?: boolean) => `${input}${suffix ?? false}`,
)

/**
 * Utility function for padding.
 * It's memoized as there's not point in re-calculating the same values over and over.
 *
 * @see {@link space} for setting `gap`
 */
export const padding: MultiSpaceFunction = memoize<MultiSpaceFunction>(
  (...inputs: Spacing[]): string =>
    inputs.map(input => space(input, true)).join(' '),
  // This is necessary so all of the arguments are considered
  (...args: Spacing[]) => args.join(' '),
)

/** Alias for padding for semantically using it on margin */
export const margin = padding

type Props = { theme: ThemeInterface }

/** Look up a color from the theme provider */
const allColor =
  <T extends ThemeColor = ThemeColor>(themeColor: T) =>
  // Returns a props lookup function
  ({ theme: _theme }: Props): string =>
    _theme.color[themeColor]

/** Look up an accent color from the theme provider */
const accentColor =
  <T extends ThemeAccentColor = ThemeAccentColor>(themeColor: T) =>
  // Returns a props lookup function
  ({ theme: _theme }: Props): string =>
    _theme.color[themeColor]

/** Look up a surface color from the theme provider */
const surfaceColor =
  <T extends ThemeSurfaceColor = ThemeSurfaceColor>(themeColor: T) =>
  // Returns a props lookup function
  ({ theme: _theme }: Props): string =>
    _theme.color[themeColor]

/** Look up an on surface color from the theme provider */
const onSurfaceColor =
  <T extends ThemeOnSurfaceColor = ThemeOnSurfaceColor>(themeColor: T) =>
  // Returns a props lookup function
  ({ theme: _theme }: Props): string =>
    _theme.color[themeColor]

/**
 * Utility function for theme colors.
 *
 * The properties have been namespaced to match the design system's MD3 colour system.
 * For more information about the colour system, refer to https://m3.material.io/styles/color/system/how-the-system-works'
 */
export const color = {
  all: allColor,
  accent: accentColor,
  surface: surfaceColor,
  onSurface: onSurfaceColor,
}

/** Look up a font family from the theme provider */
export const fontFamily =
  (themeFont: ThemeFontFamily) =>
  // Returns a props lookup function
  ({ theme: _theme }: Props): string =>
    _theme.font.family[themeFont]

/** Look up a font weight from the theme provider */
export const fontWeight =
  (themeFontWeight: ThemeFontWeight) =>
  // Returns a props lookup function
  ({ theme: _theme }: Props): number =>
    _theme.font.weight[themeFontWeight]

/** Look up a font size from the theme provider */
export const fontSize =
  (themeFontSize: ThemeFontSize) =>
  // Returns a props lookup function
  ({ theme: _theme }: Props): string =>
    _theme.font.size[themeFontSize]

export const breakpoint =
  (size: ThemeBreakpoints) =>
  // Returns a props lookup function
  ({ theme: _theme }: Props): string =>
    `@media (min-width: ${_theme.breakpoints[size]})`

export const breakpointHeight =
  (size: ThemeHeightBreakpoints) =>
  // Returns a props lookup function
  ({ theme: _theme }: Props): string =>
    `@media (min-height: ${_theme.heightBreakpoints[size]})`

type ThemeRadiusWithNone = ThemeRadius | 'none'

/**
 * Signature for the Radius function with multiple overloads
 * allowing setup of radius for all corners, or individual corners
 */
interface RadiusFunction {
  /**
   * @param input - The radius for all corners
   */
  (input: ThemeRadius): (props: Props) => string
  /**
   * @param input - The radius for top and bottom, left and right
   */
  (input: `${ThemeRadiusWithNone} ${ThemeRadiusWithNone}`): (
    props: Props,
  ) => string
  /**
   * @param input - The radius for top, left and right, bottom
   */
  (
    input: `${ThemeRadiusWithNone} ${ThemeRadiusWithNone} ${ThemeRadiusWithNone}`,
  ): (props: Props) => string
  /**
   * @param input - The radius for top, right, bottom, left
   */
  (
    input: `${ThemeRadiusWithNone} ${ThemeRadiusWithNone} ${ThemeRadiusWithNone} ${ThemeRadiusWithNone}`,
  ): (props: Props) => string
}

/** Look up a radius from the theme provider */
export const borderRadius: RadiusFunction =
  input =>
  // Returns a props lookup function
  ({ theme: _theme }: Props): string => {
    const sizes = input.split(' ') as ThemeRadiusWithNone[]
    return sizes
      .map(size => {
        if (size === 'none') return '0'
        return _theme.radius[size]
      })
      .join(' ')
  }

/**
 * Utility function for looking up theme values.
 */
export const theme = (keys: ThemeInterfaceKeys) => (props: any) =>
  get(props, `theme.${keys}`)

/**
 * Named planes for the z-index,
 * you can put multiple planes and they will be summed, like:
 * plane('modal', 'aboveFollowingContent')
 */
export const plane = memoize(
  (...planes: Plane[]): number =>
    planes.reduce((acc, p) => acc + PLANES[p], 0),
  (...planes) => planes.join(''),
)

/**
 * Returns the preferred system color match
 */
export const getSystemColorSchemeMatch = (): MediaQueryList =>
  window.matchMedia('(prefers-color-scheme: dark)')

/**
 * Returns the preferred system color scheme
 */
export const getSystemColorScheme = (): EileenColorScheme.Mode =>
  getSystemColorSchemeMatch().matches
    ? EileenColorScheme.Mode.DARK
    : EileenColorScheme.Mode.LIGHT
