import styled from 'styled-components'

import { IconButton } from 'components/Button'
import { shrinkAndFade, scaleAndFadeCycle, spin } from 'styles/animations'
import {
  space,
  fontSize,
  fontWeight,
  color,
  borderRadius,
  breakpointHeight,
} from 'theme/utils'

export const Pulse = styled.span`
  position: absolute;
  width: 100%;
  height: 100%;
  inset: 0;
  margin: 0;
  opacity: 0;
  --border-width: 12px;

  transition: opacity 500ms 1000ms ease-in;
  filter: blur(4px);

  &.show {
    opacity: 1;
    &:before,
    &:after {
      content: '';
      display: block;
      animation: ${shrinkAndFade} 2.5s infinite ease-out;
      animation-fill-mode: both;
      border-color: ${color.surface('inverseTransparentSurface')};
      border-style: solid;
      border-width: var(--border-width);
      border-radius: 50%;
      width: ${space(24)};
      height: ${space(24)};
      position: absolute;
      inset: 0;
      margin: auto;
    }

    &:after {
      border-color: ${color.surface('inverseTransparentSurface')};
      animation-delay: 900ms;
    }
  }
`

export const TextCycle = styled.span`
  position: relative;
  display: flex;
  flex-direction: column;
  align-content: center;
  width: 100%;
  opacity: 0;
  transform: translateY(${space(2)}) scale(0.8);
  transition: opacity 300ms 100ms ease-in, transform 300ms 100ms ease-in;

  &.show {
    opacity: 1;
    transform: translateY(0) scale(1);
    transition: opacity 300ms 400ms ease-in, transform 300ms 400ms ease-in;
  }

  > *:first-child,
  > *:last-child {
    position: absolute;
    inset: 0;
    opacity: 0;
    animation: ${scaleAndFadeCycle} 8s infinite linear;
  }
  > *:first-child {
    animation-delay: 0;
  }
  > *:last-child {
    animation-delay: 4s;
  }
`

export const Labels = styled.div`
  position: relative;

  width: 100%;

  display: none;

  ${breakpointHeight('xSmall')} {
    display: block;
    padding-top: ${space(9)};
  }

  ${breakpointHeight('small')} {
    padding-top: ${space(18)};
  }
`

export const Label = styled.span`
  position: absolute;
  text-transform: uppercase;
  font-weight: ${fontWeight('bold')};
  font-size: ${fontSize('medium')};
  letter-spacing: 0.1rem;
  text-align: center;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;

  opacity: 0;
  transform: translateY(${space(2)}) scale(0.8);
  transition: opacity 300ms 100ms ease-in, transform 300ms 100ms ease-in;

  &.show {
    opacity: 1;
    transform: translateY(0) scale(1);
    transition: opacity 300ms 400ms ease-in, transform 300ms 400ms ease-in;
  }
`

export const LoadingIndicatorContainer = styled.div`
  position: relative;
  margin-top: ${space(1)};

  > .icon {
    color: ${color.onSurface('onSurfaceHigh')};
    position: absolute;
    inset: 0;
    margin: auto;
    opacity: 0;
    transform: scale(0.8);
    transition: opacity 300ms ease-in, transform 300ms ease-in;

    &.show {
      opacity: 1;
      transform: scale(1);
    }
  }
`

export const PlayButton = styled(IconButton)`
  opacity: 0;
  transform: scale(0.8);
  transition: opacity 300ms ease-in, transform 300ms ease-in;
  position: absolute;
  inset: 0;
  margin: auto;

  &.show {
    opacity: 1;
    transform: scale(1);
    transition: opacity 300ms 200ms ease-in, transform 300ms 200ms ease-in;
  }

  .icon {
    position: absolute;
    opacity: 0;
    transform: scale(0.8);
    transition: opacity 300ms ease-in, transform 300ms ease-in;

    &.show {
      opacity: 1;
      transform: scale(1);
      transition: opacity 300ms 200ms ease-in, transform 300ms 200ms ease-in;
    }
  }
`

export const LoadingIndicator = styled.div`
  width: ${space(32)};
  height: ${space(32)};
  position: relative;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  margin-bottom: 0;
  pointer-events: none;
  z-index: 2;
  opacity: 0;
  transform: scale(0.8);
  transition: opacity 300ms ease-in, transform 300ms ease-in;

  &.show {
    opacity: 1;
    transform: scale(1);
  }

  &:before {
    content: '';
    display: block;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    width: ${space(18)};
    height: ${space(18)};
    border-radius: ${borderRadius('full')};
    border: ${space(1)} solid transparent;
    border-left-color: ${color.onSurface('onSurfaceHigh')};
    border-right-color: ${color.onSurface('onSurfaceHigh')};
    align-self: center;
    animation: ${spin} 2s infinite linear;
  }
`

export const Container = styled.div`
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
