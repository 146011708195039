import styled, { css, keyframes } from 'styled-components'

import { fullHeight } from 'theme/global'
import { color, pxToRem, space } from 'theme/utils'

const BackgroundLoop = keyframes`
  0% {
    background-position: -150% 0, -150% 0;
  }
  66% {
    background-position: 250% 0, -150% 0;
  }
  100% {
    background-position: 250% 0, 250% 0;
  }

`

export const IndeterminateIndicator = styled.div`
  --indicator-foreground: no-repeat
    linear-gradient(${color.onSurface('onSurfaceHigh')} 0 0);
  --indicator-background: no-repeat
    linear-gradient(${color.onSurface('onSurfaceHigh')} 0 0);

  height: ${pxToRem(4)};
  width: 100%;

  background: var(--indicator-foreground), var(--indicator-foreground),
    ${color.accent('outlineVariant')};
  background-size: 60% 100%;
  animation: ${BackgroundLoop} 3s infinite;
`

export const Overlay = styled.div<{ $variant?: 'solid' | 'opaque' }>`
  position: fixed;
  inset: 0;
  // TODO: Replace using plane util
  z-index: 1000;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${space(8)};

  ${fullHeight}

  ${({ $variant = 'solid' }) => {
    switch ($variant) {
      case 'solid':
        return css`
          background-color: ${color.surface('surface0')};
        `
      case 'opaque':
        return css`
          background-color: ${color.surface('fixedTransparentSurface')};
        `
    }
  }}

  ${IndeterminateIndicator} {
    width: ${pxToRem(106)};
  }
`
