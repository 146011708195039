import type { Picture } from 'schemas/vixen-assets'

export enum HeroImageClassName {
  FILL = 'hero-image-fill',
  FIT = 'hero-image-fit',
}

export type PictureProps = {
  /** Picture entity */
  picture: Picture
  /** HTML caption */
  caption?: Record<string, string>
}
