import React from 'react'

import { createRoot } from 'react-dom/client'
import { RouterProvider } from 'react-router-dom'

import { router } from './router'
import './locales/i18n'
import registerServiceWorker from './service-worker-registration'
import Settings from './settings'
import { setupSettings } from './utils/settings'
import './registrations'

// Initialise settings from settings.ts
setupSettings(Settings)

// Register service worker
if (import.meta.env.PROD) {
  registerServiceWorker()
}

createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
)
