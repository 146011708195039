import type { PropsWithChildren } from 'react'

import { ModalContent as ModalContentStyle, ModalViewport } from './styles'

/**
 * Displays the contents of the modal with the correct placement.
 */
export function ModalContent({ children }: PropsWithChildren): JSX.Element {
  return (
    <ModalViewport>
      <ModalContentStyle>{children}</ModalContentStyle>
    </ModalViewport>
  )
}
