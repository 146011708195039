import { PlaceHolder } from './styles'
import type { ImageFallback } from './types'

import { IconExclamationTriangleOutline, IconSpinner } from 'components/icons'

type Props = {
  /**
   * The definition for the fallback
   * Can be a string for a text fallback or an object
   * with an Icon and size for a visual fallback
   */
  fallback?: ImageFallback
  /**
   * Indicates if the image failed to load so
   * the correct fallback is rendered
   */
  imageLoadError?: boolean
}

/**
 * Renders the fallback text/icons for the {@link ImageWithFallback} component
 */
export function Fallback({
  fallback,
  imageLoadError,
}: Props): JSX.Element | null {
  if (typeof fallback === 'string') {
    return <PlaceHolder>{fallback.substring(0, 3)}</PlaceHolder>
  }

  const { Icon: FallbackIcon = IconSpinner, size = 5, color } = fallback ?? {}

  // because we've narrowed the type, we now know the format of fallback.
  const Icon = imageLoadError ? IconExclamationTriangleOutline : FallbackIcon

  return (
    <PlaceHolder>
      <Icon size={size} color={imageLoadError ? 'onError' : color} />
    </PlaceHolder>
  )
}
