/**
 * @see https://www.typescriptlang.org/docs/handbook/advanced-types.html#exhaustiveness-checking
 * @category Utils
 */
export function assertNever(x: never): never {
  throw new Error(`Unexpected object: ${JSON.stringify(x)}`)
}

/**
 * Ensures that the input is not null or undefined while maintaining the type safety.
 */
export const notNil = <T>(input: T | null | undefined): input is T => !!input
