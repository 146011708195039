import type {
  FunctionComponent,
  MutableRefObject,
  PropsWithChildren } from 'react'
import {
  createContext,
  useContext,
  useEffect,
} from 'react'

import { GA_EVENTS } from '../../constants'

import { gaSendCustomEvent } from 'helpers/analytics'
import { useAudioPlayer } from 'hooks/useAudioPlayer'
import { useReferredState } from 'hooks/useReferredState'
import { PlayerState } from 'types/player'

export type SyncPlayerContextData = {
  audioRef: MutableRefObject<HTMLAudioElement>
  audioCtx: MutableRefObject<AudioContext>
  bufferSrc: AudioBufferSourceNode | undefined
  state: PlayerState
  play(): boolean
  stop(): void
  toggleMuted(): void
  muted: boolean
  allBuffersLoaded: boolean
}

export const SyncPlayerContext = createContext<
  SyncPlayerContextData | undefined
>(undefined)

export function useSyncPlayerContext() {
  const data = useContext(SyncPlayerContext)
  if (!data) {
    throw new Error(
      'The component needs to be wrapped with SyncPlayerContextProvider',
    )
  }
  return data
}

export const SyncPlayerContextProvider: FunctionComponent<
  PropsWithChildren
> = ({ children }) => {
  const {
    audioRef,
    audioCtx,
    state,
    play,
    bufferSrc,
    toggleMuted,
    muted,
    allBuffersLoaded,
  } = useAudioPlayer()

  const [stopAndClose, setStopAndClose] = useReferredState<boolean>(false)

  const sendDuration = () => {
    if (!stopAndClose.current) {
      // console.log(`Duration ${audioCtx.current.currentTime}`)
      gaSendCustomEvent(GA_EVENTS.DURATION, {
        value: audioCtx.current.currentTime,
      })
    }
  }

  useEffect(() => {
    if (state === PlayerState.PLAYING) {
      window.addEventListener('beforeunload', sendDuration)
    }
    return () => {
      window.removeEventListener('beforeunload', sendDuration)
    }
  }, [state])

  useEffect(() => {
    if (stopAndClose.current) {
      // console.log(`Duration from stop ${audioCtx.current.currentTime}`)
      gaSendCustomEvent(GA_EVENTS.DURATION, {
        value: audioCtx.current.currentTime,
      })
      // Reload user to home page
      window.location.reload()
    }
  }, [stopAndClose.current])

  const stop = () => {
    gaSendCustomEvent(GA_EVENTS.STOP)
    setStopAndClose(true)
  }

  return (
    <SyncPlayerContext.Provider
      value={{
        play,
        stop,
        audioRef,
        state,
        audioCtx,
        bufferSrc,
        toggleMuted,
        muted,
        allBuffersLoaded,
      }}
    >
      {children}
    </SyncPlayerContext.Provider>
  )
}
