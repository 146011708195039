import {
  createContext,
  useContext,
  useRef,

} from 'react'
import type { PropsWithChildren, MutableRefObject } from 'react'

type RefKey = string
type RefValue = MutableRefObject<HTMLElement | null>
type RefContextType = {
  setRef: (key: RefKey, ref: RefValue) => void
  getRef: (key: RefKey) => RefValue | undefined
  removeRef: (key: RefKey) => void
}

const RefContext = createContext<RefContextType | undefined>(undefined)

/**
 * A convenience hook to use the ref context
 */
export const useRefContext = (): RefContextType => {
  const context = useContext(RefContext)
  if (!context) {
    throw new Error('useRefContext must be used within a RefProvider')
  }
  return context
}

/**
 * The ref provider is intended to be used to store and retrieve refs
 * to assist with accessing deeply nested components.
 */
export const RefProvider = ({ children }: PropsWithChildren): JSX.Element => {
  const refs = useRef<Record<RefKey, RefValue>>({})

  const setRef: RefContextType['setRef'] = (key, ref) => {
    refs.current[key] = ref
  }

  const getRef: RefContextType['getRef'] = key => {
    return refs.current[key]
  }

  const removeRef: RefContextType['removeRef'] = key => {
    delete refs.current[key]
  }

  return (
    <RefContext.Provider value={{ setRef, getRef, removeRef }}>
      {children}
    </RefContext.Provider>
  )
}
