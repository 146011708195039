import { useMemo } from 'react'

import { useTranslation } from 'react-i18next'

import type { GazelleRef } from '@apsys/gazelle'

import { useAppContext } from 'context/AppContext'
import type { Picture, RasterImageAsset } from 'schemas/vixen-assets'
import { retrievePictureAssets } from 'utils/assets'
import { useSettings } from 'utils/settings'

/**
 * Take a picture and turn it into image assets.
 */
export function usePicture(
  pictureRef: GazelleRef<Picture> | undefined,
): RasterImageAsset[] {
  const { contentBundle } = useAppContext()
  const { settings } = useSettings()
  const {
    i18n: { language },
  } = useTranslation()

  return useMemo(() => {
    if (!contentBundle || !pictureRef) return []

    return (
      retrievePictureAssets({
        contentBundle,
        ref: pictureRef,
        settings,
        language,
      }) ?? []
    )
  }, [contentBundle, pictureRef])
}
