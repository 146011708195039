import styled, { css } from 'styled-components'

import type { GeneratedIconProps } from './types'

import { color, space } from 'theme/utils'
import type { MakeStyledTransientProps } from 'types/utils'

type Props = MakeStyledTransientProps<
  Pick<GeneratedIconProps, 'size' | 'color'>
>

export const StyledIcon = styled.span<Props>`
  align-items: center;
  display: flex;
  flex-shrink: 0;
  justify-content: center;

  ${({ $size = 6 }) => css`
    &,
    svg {
      fill: currentColor;
      fill-rule: evenodd;
      height: ${space($size)};
      width: ${space($size)};
    }
  `};

  ${({ $color = 'inherit' }) =>
    $color &&
    css`
      * {
        fill: ${color.all($color)};
      }
    `};
`
