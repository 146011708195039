import type { HTMLAttributeAnchorTarget, PropsWithChildren } from 'react'
import { useMemo } from 'react'

import type { LinkProps } from 'react-router-dom'
import { Link } from 'react-router-dom'

import { BUTTON_ICON_SIZE } from './constants'
import { BaseButton } from './styles'
import type { BaseButtonProps } from './types'

import { IconSpinner } from 'components/icons'
import type { IconType } from 'components/icons'

type Props = PropsWithChildren<
  BaseButtonProps & {
    forwardedAs?: 'button' | 'span' | 'div'
    /**
     * The internal URL to link to. If this is set, the button will be rendered as a `Link`
     */
    to?: LinkProps['to']
    /**
     * The target for the link
     */
    target?: HTMLAttributeAnchorTarget
    /**
     * The state to be passed to the destination.
     */
    state?: LinkProps['state']
    /**
     * The position of the icon
     */
    iconPosition?: 'left' | 'right'
    /**
     * The icon to display in the button
     */
    Icon?: IconType
  }
>

/**
 * Our standard button component
 */
export const Button = ({
  forwardedAs = 'button',
  to,
  target,
  state,
  size = 'large',
  shape,
  variant,
  appearance,
  disabled,
  isLoading,
  Icon: _icon,
  iconPosition = 'left',
  children,
  ...attrs
}: Props): JSX.Element => {
  const Icon = isLoading ? IconSpinner : _icon

  const layout = useMemo(() => {
    switch (iconPosition) {
      case 'left':
        return (
          <>
            {Icon && <Icon size={BUTTON_ICON_SIZE[size]} />}
            {children}
          </>
        )
      case 'right':
        return (
          <>
            {children}
            {Icon && <Icon size={BUTTON_ICON_SIZE[size]} />}
          </>
        )
    }
  }, [size, iconPosition, Icon, children])

  return (
    <BaseButton
      as={to ? Link : forwardedAs}
      to={to}
      target={target}
      state={state}
      $size={size}
      $shape={shape}
      $variant={variant}
      $appearance={appearance}
      disabled={disabled || isLoading}
      {...attrs}
    >
      {layout}
    </BaseButton>
  )
}
