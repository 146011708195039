import { useMemo } from 'react'

import memoize from 'lodash/memoize'
import uniq from 'lodash/uniq'

import type { WithRef } from '@apsys/gazelle'
import { GazelleRef } from '@apsys/gazelle'

import { useAppContext } from 'context/AppContext'
import { EileenColorScheme } from 'schemas/eileen-service-stylesheet'
import type {
  ThemeInterface } from 'theme/theme'
import {
  defaultDarkTheme,
  defaultTheme,
  generateTheme,
} from 'theme/theme'

/**
 * Access the dynamic stylesheet from the stylesheet bundle.
 * Requires the stylesheet bundle to be loaded and parsed.
 */
export function useStylesheet() {
  const { stylesheetBundle, themeVariant } = useAppContext()

  // Functions that depend on the bundle only
  const bundleOnlyFuncs = useMemo(() => {
    /** Variants listed in this stylesheet */
    const variantsSupported = uniq(
      stylesheetBundle
        ?.getAll(EileenColorScheme)
        .flatMap(elem => elem.variants.map(variant => variant.mode)) ?? [],
    )

    /** Lookup a variant by id and return a theme structure for it */
    const getThemeForId = memoize(
      (id: string, variant: EileenColorScheme.Mode): ThemeInterface | null => {
        const ref = new GazelleRef<EileenColorScheme>({
          typename: EileenColorScheme.typename,
          id,
        })

        const colorScheme = stylesheetBundle?.get(ref)

        const schemaVariant =
          // Try the requested variant
          colorScheme?.variants.find(({ mode }) => mode === variant) ??
          // Try the first variant
          colorScheme?.variants[0]

        if (!schemaVariant) return null

        return generateTheme(id, schemaVariant)
      },
      // Memoizing function
      (id, variant) => id + variant,
    )

    return {
      variantsSupported,
      getThemeForId,
    }
  }, [stylesheetBundle])

  // Functions that depend on the variant
  return useMemo(() => {
    const { getThemeForId } = bundleOnlyFuncs

    // All of the ids of used as classes in the stylesheet
    const colorSchemeClassNames = new Set(
      stylesheetBundle
        ?.getAll(EileenColorScheme.typename)
        .map(elem => elem.ref.id) ?? [],
    )

    /**
     * Take a list of entities and use it to retrieve the first one of the defined
     * class names for the first entity that has it.
     */
    const getClassNameForEntity = memoize(
      <T extends string>(
        classNames: Set<T>,
        ...entities: Array<(WithRef & { className?: string }) | null>
      ): T | undefined => {
        for (const entity of entities) {
          for (const className of entity?.className?.split(/\s+/) ?? []) {
            if (classNames.has(className as T)) {
              return className as T
            }
          }
        }
      },
      (classNames, ...entities) =>
        Array.from(classNames.values()).join() +
        entities.map(entity => entity?.ref.id).join(),
    )

    /**
     * Take a list of entities and use it to calculate a theme.
     *
     * Tries the entities from first supplied, to last supplied. Trying the
     * parts of their className in order.
     */
    const getThemeForEntity = memoize(
      (
        ...entities: Array<(WithRef & { className?: string }) | null>
      ): ThemeInterface => {
        const className = getClassNameForEntity(
          colorSchemeClassNames,
          ...entities,
        )
        const theme = className && getThemeForId(className, themeVariant)

        if (theme) return theme

        return themeVariant === EileenColorScheme.Mode.DARK
          ? defaultDarkTheme
          : defaultTheme
      },
      // Memoize operator
      (...entities) => entities.map(entity => entity?.ref.id).join(),
    )

    return {
      ...bundleOnlyFuncs,
      getClassNameForEntity,
      getThemeForEntity,
    }
  }, [bundleOnlyFuncs, themeVariant])
}
