import {
  useState,
  forwardRef,

} from 'react'
import type { PropsWithChildren, RefObject } from 'react'

import { useMotionValueEvent, useScroll, useTransform } from 'framer-motion'

import { BaseLayoutNav, GradientOverlay } from './styles'

// When the user scrolls past this value, the navigation is considered stuck.
const SCROLL_TRIGGER = 100

type Props = PropsWithChildren<{
  /**
   * The variant of the navigation bar.
   *
   * Using `gradient` will apply a gradient overlay to the navigation bar.
   * Using `ghost` will make the navigation bar transparent.
   */
  variant?: 'gradient' | 'ghost'
  /**
   * A callback when the navigation bar is stuck.
   */
  onStuck?: () => void
  /**
   * A callback when the navigation bar is unstuck.
   */
  onUnstuck?: () => void
  /**
   * Render prop for the navigation bar, will be called with the current `isStuck` state.
   */
  render?: (isStuck: boolean) => JSX.Element
  /**
   * The scrollable container to track the scroll position of.
   *
   * @default window
   */
  scrollContainer?: RefObject<HTMLElement>

  className?: string
}>

/**
 * Use LayoutNav to render a fixed or sticky navigation bar at the top of the page.
 */
export const LayoutNav = forwardRef<HTMLDivElement, Props>(
  (
    {
      variant = 'gradient',
      onStuck,
      onUnstuck,
      render,
      scrollContainer,
      className,
      children,
    },
    ref,
  ): JSX.Element => {
    const [isStuck, setIsStuck] = useState(false)
    const { scrollY } = useScroll({
      container: scrollContainer,
      layoutEffect: false,
    })

    const opacity = useTransform(scrollY, [0, SCROLL_TRIGGER], [0, 1])

    useMotionValueEvent(scrollY, 'change', latest => {
      if (latest > SCROLL_TRIGGER) {
        setIsStuck(true)
        onStuck?.()
      } else {
        setIsStuck(false)
        onUnstuck?.()
      }
    })

    return (
      <BaseLayoutNav ref={ref} className={className}>
        {variant === 'gradient' && (
          <GradientOverlay key="gradient" style={{ opacity }} />
        )}
        {render ? render(isStuck) : children}
      </BaseLayoutNav>
    )
  },
)
