/* eslint-disable */
import * as z from 'zod'

import {
  Struct,
  Entity,
  Meta,
  recase,
  GazelleRef,
  GazelleRefSchema,
} from '@apsys/gazelle'
import { BundleLoader, GazelleEntityFactory, ValueOf } from '@apsys/gazelle'
import { ReffedEntity as ReffableEntity, WithRef } from '@apsys/gazelle'

import * as VixenCore from './vixen-core'
import * as VixenRules from './vixen-rules'
import * as VixenAssets from './vixen-assets'
import * as VixenSpatial from './vixen-spatial'
import * as VixenBeacons from './vixen-beacons'
import * as OcelotContent from './ocelot-content'
import * as OcelotCatalog from './ocelot-catalog'
import * as OcelotHibiki from './ocelot-hibiki'
import * as EileenImmersive from './eileen-immersive'
import * as EileenSpatial from './eileen-spatial'
import * as EileenConfig from './eileen-config'
import * as LivesyncCore from './livesync-core'
import * as LivesyncHibiki from './livesync-hibiki'

export const meta: Meta = {
  name: 'Eileen VX Schema',
  moduleName: 'eileen-schema',
  version: '1.6.0',
  authors: ['Art Processors <developers@artprocessors.net>'],
}

// === enums ===
// === interfaces ===
// === structs ===
// === entities ===
export type INTERFACES = VixenCore.INTERFACES &
  VixenRules.INTERFACES &
  VixenAssets.INTERFACES &
  VixenSpatial.INTERFACES &
  VixenBeacons.INTERFACES &
  OcelotContent.INTERFACES &
  OcelotCatalog.INTERFACES &
  OcelotHibiki.INTERFACES &
  EileenImmersive.INTERFACES &
  EileenSpatial.INTERFACES &
  EileenConfig.INTERFACES &
  LivesyncCore.INTERFACES &
  LivesyncHibiki.INTERFACES & {}

export const ENTITIES = {
  ...VixenCore.ENTITIES,
  ...VixenRules.ENTITIES,
  ...VixenAssets.ENTITIES,
  ...VixenSpatial.ENTITIES,
  ...VixenBeacons.ENTITIES,
  ...OcelotContent.ENTITIES,
  ...OcelotCatalog.ENTITIES,
  ...OcelotHibiki.ENTITIES,
  ...EileenImmersive.ENTITIES,
  ...EileenSpatial.ENTITIES,
  ...EileenConfig.ENTITIES,
  ...LivesyncCore.ENTITIES,
  ...LivesyncHibiki.ENTITIES,
}
export type ENTITIES = {
  [K in keyof typeof ENTITIES]: InstanceType<(typeof ENTITIES)[K]>
}

export class Loader extends BundleLoader {
  static readonly ENTITIES = ENTITIES
  static readonly schemaVersion = '1.6.0'

  get<K extends keyof ENTITIES>(ref: {
    typename: K
    id: string
  }): ENTITIES[K] | undefined
  get<T extends ValueOf<ENTITIES> | ValueOf<INTERFACES> | Entity<any>>(
    ref: GazelleRef<T>,
  ): (T extends Entity<any> ? T : ReffableEntity<T> & T) | undefined
  get(ref: any) {
    return super.get(ref)
  }

  getAll<K extends keyof ENTITIES>(typename: K): Array<ENTITIES[K]>
  getAll<T extends ValueOf<ENTITIES>>(klass: GazelleEntityFactory<T>): Array<T>
  getAll(klass: any) {
    return super.getAll(klass)
  }

  getSingleton<K extends keyof ENTITIES>(typename: K): ENTITIES[K] | undefined
  getSingleton<T extends ValueOf<ENTITIES>>(
    klass: GazelleEntityFactory<T>,
  ): T | undefined
  getSingleton(klass: any) {
    return super.getSingleton(klass)
  }
}
