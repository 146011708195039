import type { PropsWithChildren } from 'react'

import { ErrorBoundary } from 'react-error-boundary'

import { useAppContext } from './context/AppContext'

import { LoadingOverlay } from 'components/Loading'
import { CatchAllFullpage } from 'views/errors/CatchAll'

export const Loader = ({ children }: PropsWithChildren): JSX.Element => {
  const { loadFailure, contentLoaded } = useAppContext()

  if (!contentLoaded && !loadFailure) return <LoadingOverlay />
  if (loadFailure) return <CatchAllFullpage />

  return (
    <ErrorBoundary FallbackComponent={CatchAllFullpage}>
      {children}
    </ErrorBoundary>
  )
}
