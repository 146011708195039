import { motion } from 'framer-motion'
import styled from 'styled-components'

import { BaseLayoutNav } from 'components/LayoutNav/styles'
import { appBreakpointsMixin } from 'layouts/styles'
import { fullMaxHeight, fullMinHeight } from 'theme/global'
import { color, plane, space } from 'theme/utils'

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: ${plane('overlay')};
  background: transparent;

  will-change: filter;

  ${fullMinHeight};
  ${fullMaxHeight};
`

export const ModalFrame = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  box-sizing: border-box;
  z-index: ${plane('dialog')};

  background: ${color.surface('surface0')};

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;
  grid-template-areas: 'nav' 'viewport' 'footer';

  height: 100%;

  pointer-events: auto;

  overflow-x: hidden;
  overflow-y: auto;

  ${fullMinHeight};
  ${fullMaxHeight};

  ${BaseLayoutNav} {
    grid-area: nav;

    ${appBreakpointsMixin};
  }
`

export const ModalViewport = styled.div`
  grid-area: viewport;

  flex: 1;
  width: 100%;
  height: 100%;
  padding: ${space(10)} ${space(4)};

  ${appBreakpointsMixin};
`

export const ModalContent = styled.div`
  // The use of grid + height: 100% is to ensure that the container takes up the full height of the viewport
  // but still respects the global app bar (unlike using height: 100dvh for example).
  display: grid;
  // Ensures that the container takes up the space it needs to fill the viewport, but no more.
  // Without minmax, nested content may blow out the width of the container.
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: minmax(0, 1fr);

  flex: 1;
  width: 100%;
  height: 100%;
`

/**
 * A container for the footer of the modal.
 *
 * It will always be stuck to the bottom.
 */
export const ModalFooter = styled.div`
  grid-area: footer;

  position: sticky;
  bottom: 0;
  z-index: ${plane('floating')};
  width: 100%;
`
