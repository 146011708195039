import type { FunctionComponent } from 'react'
import { useEffect, useState } from 'react'

import { Outlet, useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import { Information } from 'components/Information'
import { useSyncPlayerContext } from 'components/SyncPlayer/SyncPlayerContext'
import { Transcript } from 'components/Transcript'
import { AudioInfo } from 'debug-tools'
import { debugAllowed } from 'helpers/debug'
import { getIsAndroid } from 'helpers/ua'
import { useEsConfig } from 'hooks/useEsConfig'
import { AppScreen } from 'layouts/styles'
import { PlayerState } from 'types/player'

const isAndroid = getIsAndroid()

const AudioElement = styled.audio`
  visibility: hidden;
  z-index: -1;
  position: relative;
  height: 0;
  width: 0;
`

const Modals = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  left: 0;
  right: 0;
`

export const PlayerView: FunctionComponent = () => {
  const esConfig = useEsConfig()

  const { audioRef, state } = useSyncPlayerContext()

  const [previousStatus, setPreviousStatus] = useState<
    PlayerState | undefined
  >()
  // This is necessary so the request to start playback isn't repeated
  // const [playbackRequested, setPlaybackRequested] = useState(false)
  const navigate = useNavigate()

  const debug = debugAllowed()

  useEffect(() => {}, [])

  useEffect(() => {
    if (state === previousStatus) {
      return
    }

    if (state === PlayerState.ERROR) {
      navigate('/player/error')
    }

    setPreviousStatus(state)
  }, [state, previousStatus])

  return (
    <>
      {!isAndroid && <AudioElement ref={audioRef} />}
      <AppScreen>
        <Outlet />
        <Modals>
          <Information content={esConfig} />
          <Transcript content={esConfig} />
        </Modals>
      </AppScreen>

      {debug && <AudioInfo />}
    </>
  )
}
