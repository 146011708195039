import * as z from 'zod'

/** This key is intentionally ommitted by sending null */
const ZOmitKey = z.literal(null).default(null)

/**
 * Event spec: `Context` validator
 *
 * @see https://artprocessors.atlassian.net/wiki/spaces/DAI/pages/1998356528/Pladia+Visitor+Apps+-+Digital+Event+Spec+v0.1#Context
 */
const ZContext = z
  .object({
    /** Client used to describe the deployment */
    client: z.string().min(1),

    /** Project used to describe the deployment */
    project: z.string().min(1),

    /** The environment the deployment is in (dev, staging, prod) */
    env: z.string().min(1),

    /** The product name */
    product: z.literal('eventsync'),

    /** The customer short name */
    customer: z.string().min(1),

    /** If the app is in debug mode */
    debug: z.boolean(),

    /** The version of event the spec that we're using  */
    spec_version: z.literal('0.1'),

    /** The app details */
    app: z
      .object({
        /** The type of app based on enum */
        type: z.literal('pwa'),
        /** The public application version, so the version minus the tag */
        version: z.string().min(1),
        /** The application version plus the tag, same as in settings */
        build: z.string().min(1),
        /** The public name of the application */
        name: z.string().min(1),
        /** The role of the application based on enum such as byod or in-house */
        role: z.literal('byod'),
      })
      .strict(),
    device: z
      .object({
        /** The device ID, must be a UUID */
        id: z.string().uuid(),
        /** The version of the os of the device (12, 13, etc) */
        os_version: z.string().optional(),
        /** The name of the os of the device */
        os: z.string().min(1),
        /** The timezone of the device */
        time_zone: z.string().min(1),
        /** The locale of the device */
        locale: z.string().min(1),
      })
      .strict(),

    // These keys are all being dropped
    page: ZOmitKey,
    locale: ZOmitKey,
    timezone: ZOmitKey,
    os: ZOmitKey,
  })
  .strict()

/**
 * Common properties to all events, used to drop keys we don't want to send.
 */
export const ZCommonProperties = z
  .object({
    tab_url: ZOmitKey,
    initial_referrer: ZOmitKey,
    initial_referring_domain: ZOmitKey,
    referring_domain: ZOmitKey,
    path: ZOmitKey,
    search: ZOmitKey,
    name: ZOmitKey,
  })
  .strict()

/** Event spec: `Context` type */
export type Context = z.input<typeof ZContext>

/** Schema for the apiOptions field */
export const ZApiOptions = ZContext.extend({
  /** The anonymous ID of the user. Set to device ID generally. Should be a UUID */
  anonymousId: z.string().uuid(),
}).strict()

/**
 * Event Spec: `EntityDetails` validator
 * @see https://artprocessors.atlassian.net/wiki/spaces/DAI/pages/1998356528/Pladia+Visitor+Apps+-+Digital+Event+Spec+v0.1#EntityDetails
 */
export const ZEntityDetails = z
  .object({
    /** The entity ID */
    id: z.string().min(1),
    /** The entity type */
    typename: z.string().min(1),
    /** The entity name */
    name: z.string().optional(),
  })
  .strict()

/** Evenet spec: `EntityDetails` type */
export type EntityDetails = z.input<typeof ZEntityDetails>

/**
 * Event spec: `BundleInfo` validator
 * @see https://artprocessors.atlassian.net/wiki/spaces/DAI/pages/1998356528/Pladia+Visitor+Apps+-+Digital+Event+Spec+v0.1#BundleInfo
 */
export const ZBundleInfo = z
  .object({
    /** The bundle ID */
    id: z.string().min(1),
    /** The bundle date */
    date: z.string().datetime(),
  })
  .strict()

/** Event spec: `BundleInfo` type */
export type BundleInfo = z.input<typeof ZBundleInfo>

/**
 * Event spec: `FullLocation` validator
 *
 * For the moment this is unused, but it's here for completeness.
 */
export const ZFullLocation = z
  .object({
    venue: ZEntityDetails.optional(),
    building: ZEntityDetails.optional(),
    level: ZEntityDetails.optional(),
    space: ZEntityDetails.optional(),
  })
  .strict()

/** Event spec: `FullLocation` type */
export type FullLocation = z.input<typeof ZFullLocation>
