import { useMemo, useState } from 'react'

import type { PictureProps } from './types'

export enum Direction {
  LEFT = -1,
  NONE = 0,
  RIGHT = 1,
}

type RType = {
  /** The current carousel index (unwrapped, indexed from 0) */
  index: number
  /** The current page (wrapped, indexed from 1) */
  page: number
  /** The animation direction */
  direction: Direction

  /** Scroll to prev image */
  prev: () => void
  /** Scroll to next image */
  next: () => void

  /** Picture animating off screen left */
  farLeft: PictureProps
  /** Picture on the left of the screen */
  left: PictureProps
  /** Picture in the middle of the screen */
  center: PictureProps
  /** Picture on the right of the screen */
  right: PictureProps
  /** PIcture animating off screen right */
  farRight: PictureProps
}

/** % is remainder rather than mod */
function mod(n: number, m: number): number {
  return ((n % m) + m) % m
}

/**
 * A hook to hold the state of the carousel.
 */
export function useCarousel(pictures: PictureProps[]): RType {
  const [[index, direction], setIndex] = useState<[number, Direction]>([
    0,
    Direction.NONE,
  ])

  const callbacks = useMemo(
    () => ({
      prev: () => setIndex(([value]) => [value - 1, Direction.LEFT]),
      next: () => setIndex(([value]) => [value + 1, Direction.RIGHT]),
    }),
    [],
  )

  return useMemo(() => {
    const page = mod(index, pictures.length) + 1

    const farLeft = pictures[mod(index - 2, pictures.length)]
    const left = pictures[mod(index - 1, pictures.length)]
    const center = pictures[mod(index, pictures.length)]
    const right = pictures[mod(index + 1, pictures.length)]
    const farRight = pictures[mod(index + 2, pictures.length)]

    return {
      ...callbacks,
      index,
      page,
      direction,
      farLeft,
      left,
      center,
      right,
      farRight,
    }
  }, [pictures, index, callbacks, direction])
}
