import { motion } from 'framer-motion'
import styled, { css } from 'styled-components'

import { HeroImageClassName } from './types'

import { ImageWithFallback } from 'components/ImageWithFallback'
import { Text } from 'components/typography'
import {
  borderRadius,
  breakpoint,
  color,
  padding,
  plane,
  pxToRem,
  space,
} from 'theme/utils'
import { assertNever } from 'utils/guards'

/**
 * Wrapper for {@link StaticBlock}.
 *
 * This is the grid for n images.
 *
 * Items should be inserted into the grid as image, caption, image, caption.
 * Captions can have the CSS property `--image-width` to make the caption
 * take up the same space as the image (within the constraints).
 */
export const ImageGrid = styled.div`
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-rows: auto auto;
  grid-auto-flow: column;
  align-items: center;

  grid-column-gap: ${space(4)};
  grid-row-gap: ${space(2)};

  justify-items: center;

  > ${Text} {
    min-width: min(${pxToRem(275)}, 100%);
    width: min(var(--image-width), 100%);
    max-width: min(${pxToRem(565)}, 100%);
  }
`

/** Wraps the image layout */
export const ImageWithFallbackWrapper = styled(ImageWithFallback)<{
  /** Cover/contain */
  $fit?: HeroImageClassName
}>`
  height: auto; // Important for Safari

  > img {
    width: auto;
    height: auto;
    max-width: min(${pxToRem(448)}, 100%);
    // If we're in a carousel block, use the max-height of the container,
    // which is --image-width, otherwise use a clamped width.
    max-height: var(--image-width, ${pxToRem(448)});

    ${breakpoint('medium')} {
      max-width: min(${pxToRem(597)}, 100%);
    }

    ${breakpoint('large')} {
      max-width: min(${pxToRem(750)}, 100%);
    }

    border-radius: ${borderRadius('small')};
    align-self: stretch;

    // Fit rule
    ${({ $fit = HeroImageClassName.FIT }) => {
      switch ($fit) {
        case HeroImageClassName.FIT:
          return css`
            object-fit: contain;
          `

        case HeroImageClassName.FILL:
          return css`
            object-fit: cover;
            aspect-ratio: 1;
          `

        default:
          assertNever($fit)
      }
    }}
  }
`

/**
 * Wrapper for {@link DynamicBlock}.
 *
 * Image tray + caption
 */
export const CarouselWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${space(2)};
  align-items: center;

  --image-width: ${pxToRem(326)};

  ${breakpoint('medium')} {
    --image-width: ${pxToRem(448)};
  }

  // Used for animation
  --image-width-plus-gutter: calc(var(--image-width) + ${space(2)});
`

export const CarouselPill = styled.div`
  border-radius: ${borderRadius('large')};
  padding: ${padding(1, 2)};
  background-color: ${color.surface('surface1')};
  color: ${color.onSurface('onSurfaceHigh')};
  text-align: center;
`

/** The image tray + buttons */
export const CarouselTop = styled.div`
  align-self: stretch;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  position: relative;

  // Position the buttons over the grid
  > button {
    position: absolute;
    z-index: ${plane('floating')};

    &.left {
      left: 0;
    }

    &.right {
      right: 0;
    }
  }
`

/** The image tray */
export const CarouselTopInner = styled(motion.div)`
  display: flex;
  flex-direction: row;
  gap: ${space(2)};
  justify-content: center;
  align-items: center;
`

/** A single moving image inside {@link CarouselTopInner} */
export const MotionImage = styled(motion.div)`
  // Images are always square in carousel mode
  > ${ImageWithFallbackWrapper} {
    width: var(--image-width);
    height: var(--image-width);
    aspect-ratio: 1;
  }
`

/** The bottom of the carousel, contains the caption */
export const CarouselBottom = styled.div`
  align-self: center;

  // Set up a grid cell that all the children render into
  display: grid;
  grid-template-columns: auto;
  grid-auto-rows: auto;
  grid-template-areas: 'text';

  > * {
    grid-area: text;
  }

  > ${Text} {
    width: var(--image-width);

    &.hidden {
      visibility: hidden;
    }
  }
`

export const CarouselBottomInner = styled.div`
  display: flex;
  flex-direction: row;

  gap: ${space(2)};
  justify-content: center;
  align-items: flex-start;

  > * {
    width: var(--image-width);
    text-align: center;
  }
`
