import type { ConfigOptions } from 'utils/settings'

export default {
  // Used for the logo click in the header on the detail/player view.
  homepage: 'https://www.artprocessors.net/',

  // Default language if we can't detect their language via react-i18next
  defaultLanguage: 'en',
  // Languages that have UI label translations (stored in /locales) and content
  // and are displayed in the language selector.
  //
  // If only one language is specified, the language selector is not displayed.
  // Case matters in these language codes (zh-Hans, en-AU, etc.)
  languages: ['en'],
} as ConfigOptions
