import { RudderAnalytics } from '@rudderstack/analytics-js'

import getEnv from 'utils/getEnv'

const {
  VITE_RUDDERSTACK_EVENTSYNC_WRITE_KEY: RUDDERSTACK_WRITE_KEY,
  VITE_RUDDERSTACK_EVENTSYNC_DATA_PLANE_URL: RUDDERSTACK_DATA_PLANE_URL,
} = getEnv()

let rudderstackInstance: RudderAnalytics | undefined

/**
 * Standardised Rudderstack integration.
 *
 * The write key and data plane URL will all be set from environment.
 * The following keys should be defined:
 *
 * - `VITE_RUDDERSTACK_EVENTSYNC_WRITE_KEY`
 * - `VITE_RUDDERSTACK_EVENTSYNC_DATA_PLANE_URL`
 *
 * For more information, see the [Rudderstack documentation](https://docs.rudderstack.com/).
 *
 * @category Rudderstack
 */
export const getRudderstackInstance = (): RudderAnalytics => {
  if (rudderstackInstance) return rudderstackInstance

  rudderstackInstance = new RudderAnalytics()

  if (!RUDDERSTACK_WRITE_KEY) {
    console.warn('Rudderstack write key not provided')
    return rudderstackInstance
  }

  if (!RUDDERSTACK_DATA_PLANE_URL) {
    console.warn('Rudderstack data plane URL not provided')
    return rudderstackInstance
  }

  rudderstackInstance.load(RUDDERSTACK_WRITE_KEY, RUDDERSTACK_DATA_PLANE_URL, {
    queueOptions: {
      // See https://www.rudderstack.com/docs/sources/event-streams/sdks/rudderstack-javascript-sdk/load-js-sdk/#queueopts
      maxAttempts: 15,
      maxItems: 200,
      minRetryDelay: 5000, // ms
    },
  })

  console.info('// Rudderstack: initialised')

  return rudderstackInstance
}
