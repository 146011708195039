import { useCallback, useMemo } from 'react'

import { useHtmlBlock } from './useHtmlBlock'
import { usePicture } from './usePicture'

import { useAppContext } from 'context/AppContext'
import type { EsConfig } from 'schemas/eileen-sync-config'
import type { RasterImageAsset } from 'schemas/vixen-assets'
import { getEsConfig } from 'utils/bundle'
import { useSettings } from 'utils/settings'

/**
 * Get the current `es-config`
 *
 * This is the sticky venue (from the `ES_CONFIG` localStorage key) or
 * otherwise this is the first `es-config` in the bundle.
 *
 * The core logic of this function is supplied by {@link getEsConfig}.
 */
export function useEsConfig(): EsConfig | null {
  const { contentBundle } = useAppContext()

  return useMemo(() => {
    if (!contentBundle) return null

    return getEsConfig(contentBundle)
  }, [contentBundle])
}

export type ESConfigProperties = {
  logo: RasterImageAsset[]
  sponsors: RasterImageAsset[]
  background: RasterImageAsset[]
  name: string | undefined
  description: string | undefined
  socketEndpoint: string | undefined
  analyticsId: string | undefined
  analyticsEnabled: boolean | undefined
  getInteractiveID: () => string
  connectionType?: string | undefined
}

export function useConfigProperties(): ESConfigProperties {
  const esConfig = useEsConfig()
  const { settings } = useSettings()

  const interactiveID = esConfig?.interactiveId
  const logo = usePicture(esConfig?.logo)
  const sponsors = usePicture(esConfig?.sponsors)
  const background = usePicture(esConfig?.background)
  const name = esConfig?.name[settings.defaultLanguage]
  const description = useHtmlBlock(esConfig?.description)
  const socketEndpoint = esConfig?.socketEndpoint
  const analyticsId = esConfig?.analyticsId
  const analyticsEnabled = esConfig?.analyticsEnabled
  const connectionType = esConfig?.connectionType

  const configValues = useMemo(() => {
    return {
      logo,
      background,
      name,
      description,
      socketEndpoint,
      analyticsId,
      analyticsEnabled,
      sponsors,
      connectionType,
    }
  }, [esConfig])

  //  returns an interactive ID. Falls back to:
  //  - Query parameters first as is standard
  //  - The id from EsConfig
  const getInteractiveID = useCallback((): string => {
    const urlParams = new URLSearchParams(location.search)
    const urlInteractiveId = urlParams.get('interactive_id') || ''
    if (urlParams.has('interactive_id') && urlInteractiveId)
      return urlInteractiveId
    return interactiveID || ''
  }, [interactiveID, esConfig, settings])

  return {
    getInteractiveID,
    ...configValues,
  }
}
