import { useCallback, useMemo, useState } from 'react'

export type ModalProps = {
  show: boolean
  analyticsEvent?: string
  onClose?: () => void
  onClick?: () => void
  toggleShowing: () => void
  name: string
  ariaExpanded: boolean
}

export function useModalState(
  modalName: string,
  analyticsEvent?: string,
): ModalProps {
  const [show, setShow] = useState(false)

  const openModal = useCallback(() => {
    setShow(true)
  }, [])

  const closeModal = useCallback(() => {
    setShow(false)
  }, [])

  const toggleShowing = useCallback(() => {
    setShow(!show)
  }, [show])

  return useMemo(
    () => ({
      name: modalName,
      show,
      ariaExpanded: show,
      analyticsEvent,
      onClick: openModal,
      onClose: closeModal,
      toggleShowing,
    }),
    [show, openModal, closeModal, modalName, analyticsEvent],
  )
}
