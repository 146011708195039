import * as Sentry from '@sentry/browser'

import getEnv from './getEnv'

/**
 * Standardised Sentry browser integration.
 *
 * This should be called before the root React application is mounted.
 *
 * The DSN, release and environment will all be set from environment.
 * The following keys should be defined:
 *
 * - `REACT_APP_SENTRY_DSN`
 * - `REACT_APP_SENTRY_RELEASE`
 * - `REACT_APP_SENTRY_ENVIRONMENT`
 *
 * @category Sentry
 */
export const sentryInit = (
  options?: Omit<Sentry.BrowserOptions, 'dsn' | 'release'>,
) => {
  const env = getEnv()
  const dsn = env.VITE_SENTRY_DSN
  const release = env.VITE_SENTRY_RELEASE
  const environment = env.VITE_SENTRY_ENVIRONMENT

  console.info('// Sentry: SENTRY_DSN', dsn)
  console.info('// Sentry: SENTRY_RELEASE', release)
  console.info('// Sentry: SENTRY_ENVIRONMENT', environment)

  if (dsn) {
    if (!release) {
      console.warn(
        'Sentry initialisation was not provided a release identifier.',
      )
    }

    Sentry.init({
      dsn,
      release: release || undefined,
      environment,
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.captureConsoleIntegration({ levels: ['error'] }),
      ],
      ignoreErrors: [
        // Random plugins/extensions
        'top.GLOBALS',
        // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
        // reduce this. (thanks @acdha)
        // See http://stackoverflow.com/questions/4113268
        'bmi_SafeAddOnload',
        'EBCallBackMessageReceived',
        // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
        'conduitPage',
      ],
      denyUrls: [
        // Chrome extensions
        /extensions\//i,
        /^chrome:\/\//i,
        // Other plugins
        /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
        /webappstoolbarba\.texthelp\.com\//i,
        /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
      ],

      tracesSampleRate: 0.5,
      ...options,
    })
    console.info('// Sentry: initialize sent')
  } else {
    // notify through the console if initialisation was skipped, rather than being silent.
    console.warn('Sentry initialisation failed. DSN not provided.')
  }
}
