import { createGlobalStyle, css } from 'styled-components'

import { BASE_FONT_SIZE } from './constants'
import { fontFamily, fontSize, fontWeight, color } from './utils'

export const fullHeight = css`
  height: 100%;
  height: 100vh;
  height: 100dvh;
`

export const fullMaxHeight = css`
  max-height: 100%;
  max-height: 100vh;
  max-height: 100dvh;
`

export const fullMinHeight = css`
  min-height: 100%;
  min-height: 100vh;
  min-height: 100dvh;
`

export const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    user-select: none; 
  }

  html, body, #root {
    ${fullMinHeight}
  }

  html {
    font-size: ${BASE_FONT_SIZE}px;
    overflow-x: hidden;
  }

  .overflow--hidden {
    overflow:hidden;
  }

  body { 
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    font-family: ${fontFamily('base')};
    font-weight: ${fontWeight('regular')};
    
    color: ${color.onSurface('onSurfaceHigh')};

    margin: 0;

    background: ${color.surface('surface0')};
  }

  figure, fieldset {
    padding:0;
    margin:0;
  }

  fieldset{
    border: 0;
  }
  
  button {
    cursor: pointer;
    border: 0;
    appearance: none;
    background: none;
    font-family: ${fontFamily('base')};
  } 
  
  li {
    margin: 0 0 1rem 0;
  }

  a {
    color: ${color.onSurface('onSurfaceHigh')};
    text-decoration: none;
  }

  ul, ol {
    margin: 0 0 1rem 0;
    padding: 0 0 0 1rem;
  }

  p, h1, h2, h3, h4, h5, h6 {
    color: ${color.onSurface('onSurfaceHigh')};
    padding: 0;
    margin: 0;
  }

  h1, h2, h3, h4, h5, h6 {
    font-weight: ${fontWeight('semibold')};
    line-height: 1.2;
  } 

  p {
    font-size: ${fontSize('base')};
    font-weight: ${fontWeight('regular')};
    line-height: 1.26;
  }

  strong, b {
    font-weight: ${fontWeight('bold')};
  }

  // Styles the message that occurs when JS is not available
  // FIXME: will this get applied?
  .no-js-message {
    text-align:center;
    padding:20px;
    background: #ffc3c3;
    margin: 0 20px;
    border-radius: 4px;
    border: 1px solid #faa;
    box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.08);
  }

  // Selector to hide something from the screen so it only shows up in the
  // screen reader
  [data-sr-only] {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }
`
