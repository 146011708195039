import { useCallback, useEffect, useRef, useState } from 'react'

import { useTranslation } from 'react-i18next'

import { GA_EVENTS } from '../constants'

import { useAudioBuffer } from './useAudioBuffer'

import { useAppContext } from 'context/AppContext'
import { useSyncServerContext } from 'context/SyncServerContext'
import { gaSendEvent } from 'helpers/analytics'
import { getConfigData } from 'helpers/config'
import {
  checkIsHighDelayAllowancePercent,
  setupIOSMediaSession,
} from 'helpers/player'
import { getIsAndroid } from 'helpers/ua'
import { PlayerState } from 'types/player'
import { useRudderstackAudioAnalytics } from 'utils/rudderstack'

const isAndroid = getIsAndroid()

let audioContext: AudioContext | null = null

function getAudioContext(): AudioContext {
  if (!audioContext) {
    audioContext = new AudioContext()
  }
  return audioContext
}

export function useAudioPlayer() {
  const { t } = useTranslation()

  const { tracks } = useAppContext()
  const {
    clockSynced,
    startTime,
    socket,
    setPlayState,
    track,
    setTrack,
    audioId,
  } = useSyncServerContext()

  const audioCtx = useRef<AudioContext>(getAudioContext())
  const audioRef = useRef<HTMLAudioElement>(new Audio())

  const audioDiff = useRef<number>(0)
  const streamOffset = useRef<number>(0)
  const reloadCount = useRef<number>(0)
  const timer = useRef<ReturnType<typeof setTimeout> | null>(null)
  const interval = useRef<ReturnType<typeof setInterval> | null>(null)

  const [state, setState] = useState<PlayerState>(PlayerState.IDLE)

  const [audioGain, setAudioGain] = useState<number>(0)
  const gainNode = useRef<GainNode>()
  const [audioAllowed, setAudioAllowed] = useState(false)
  const [bufferLoaded, setBufferLoaded] = useState(false)
  const [muted, setMuted] = useState(false)

  const {
    bufferSrc,
    setupBuffer,
    setBuffer,
    stopBuffer,
    replaceBuffer,
    replaceLongAudioBuffer,
    longAudioTime,
    allBuffersLoaded,
  } = useAudioBuffer(audioCtx)

  const audioConfig = getConfigData('audioConfig')

  const loadAudio = async (): Promise<void> => {
    console.info('🚛 🎶  Loading Audio buffer', track)
    // If there's no audio context or track, return.
    // IMPORTANT as sometimes even the empty audio track is passed in but fails
    // to load
    if (!audioCtx.current || !track) return

    try {
      await setupBuffer(track, startTime)
      setState(PlayerState.INITIALIZED)
      setBufferLoaded(true) // Set bufferLoaded to true after setting up the buffer
    } catch (err) {
      console.error(`❌ Error Loading Audio ${err}`)
    }
  }

  const toggleMuted = (): void => {
    setMuted(!muted)
    audioRef.current.muted = !muted

    // Android muting
    if (isAndroid && audioCtx.current && gainNode.current) {
      gainNode.current.gain.setValueAtTime(
        !muted ? -1 : 1,
        audioCtx.current.currentTime,
      )
    }
  }

  const reloadAudioContext = (): void => {
    // Prevent a reload if we have exceeded the allowance or reload in a given time.
    // Having this happen will prompt the user with a sync error.
    // Sync/delay issues are caused by operations coming in on mobile devices such as audio output changes and incoming phone calls.
    // Having this insures we don't get hung in a permanent re-sync.
    if (reloadCount.current >= audioConfig.reloadAllowances.max) {
      setState(PlayerState.ERROR)
      if (timer.current) {
        clearTimeout(timer.current)
        timer.current = null
      }
      return
    }

    if (!track) return

    // TODO: DO WE REALLY WANT TO HIGHLIGHT A DESYNC EVEN WHEN IT IS SO MINOR
    setState(PlayerState.SYNCING)
    console.info('🔃 🎶 Reload', track)

    stopBuffer()

    streamOffset.current = audioCtx.current.currentTime
    setBuffer(track)
    // TODO: DISABLED AS WE DONT NEED TO SET TO INITIALISED AS IT WILL FORCE A RERENDER ON PLAYER VIEW
    // setState(PlayerState.INITIALIZED)

    // Track amount of reloads and clear what has been tracked if no reload have happened.
    reloadCount.current = reloadCount.current + 1
    if (timer.current === null) {
      timer.current = setTimeout(() => {
        reloadCount.current = 0
        timer.current = null
      }, audioConfig.reloadAllowances.timeMs)
    }
  }

  const delayCheck = useCallback(() => {
    if (state !== PlayerState.PLAYING || !audioCtx.current?.currentTime) return

    const bufferDuration = bufferSrc?.buffer?.duration
    if (!bufferDuration || !track) {
      return
    }

    // Reload if delay exceeds allowance
    if (
      checkIsHighDelayAllowancePercent(
        audioCtx.current,
        socket,
        startTime,
        audioDiff,
        streamOffset,
        bufferDuration,
      )
    ) {
      console.info('⚠️ Audio delay allowance to high. Re-loading')
      reloadAudioContext()
    } else {
      if (state !== PlayerState.PLAYING) setState(PlayerState.PLAYING)

      // Long audio check
      if (track.longAudio) {
        const epoch = socket.current.getEpochTime()
        let diff = (epoch - startTime) / 1000.0
        // Prevent negative diff
        diff = diff > 0 ? diff : 0

        // Update/replace the long audio buffer if required.
        const longAudioBufferTrack = replaceLongAudioBuffer(track, diff, state)

        // If audio buffer was replaced with above check we update the stored track.
        if (longAudioBufferTrack && longAudioBufferTrack !== track) {
          setTrack(longAudioBufferTrack)
        }
      }
    }
  }, [track, state])

  const playSoundAndroid = () => {
    bufferSrc?.connect(audioCtx.current.destination)
  }

  const playSoundGeneral = () => {
    const streamNode = audioCtx.current.createMediaStreamDestination()
    bufferSrc?.connect(streamNode)
    audioRef.current.controls = true
    audioRef.current.srcObject = streamNode.stream
    audioRef.current.volume = audioGain
    const playPromise = audioRef.current.play()
    // Catch play promise errors for when the play() request is interrupted by a new load request.
    if (playPromise !== undefined) {
      playPromise.catch(error => {})
    }
  }

  const playSound = () => {
    setAudioAllowed(true)

    if (!bufferLoaded || !track) {
      return
    }

    const epoch = socket.current.getEpochTime()
    let diff = (epoch - startTime) / 1000.0

    // Prevent negative diff
    diff = diff > 0 ? diff : 0

    audioDiff.current = diff

    const diffLong = diff - longAudioTime

    let offset = track.longAudio && longAudioTime > 0 ? diffLong : diff
    offset = offset > 0 ? offset : 0

    console.group('▶️ Play Sound')
    console.info(`⏲ Start Time: ${startTime}`)
    console.info(`⏳ Play back diff: ${diff}`)
    if (track.longAudio)
      console.info(`⏳ Play back diff (Long Audio): ${diffLong}`)
    console.info(` Offset: ${offset}`)
    console.groupEnd()

    bufferSrc?.start(0, offset)
    if (isAndroid) {
      playSoundAndroid()
    } else {
      playSoundGeneral()
    }

    setState(PlayerState.PLAYING)
  }

  useEffect(() => {
    console.info('🎛 Setup Sync Player 🎛')

    if (bufferLoaded) return
    loadAudio().then(() => {
      // Ensure we don't get audio suspension when changing browser tabs or locking mobile device screens.
      audioCtx.current.onstatechange = () => {
        if (!audioCtx.current) return
        if (
          audioCtx.current.state === 'suspended' ||
          audioCtx.current.state !== 'running'
        ) {
          if (audioCtx.current.state !== 'closed') audioCtx.current.resume()
        }
      }
      // Listen for device changes and run a delay check when on is detected.
      if (window.navigator.mediaDevices) {
        window.navigator.mediaDevices.ondevicechange = () => {
          delayCheck()
        }
      }

      // Resume the play at least once to ensure audio is allowed initially
      // Fixes a bug on safari where audio starts in a suspended state and never plays
      audioCtx.current.resume()

      // Preventing headphone hardware be able to pause
      audioRef.current.addEventListener('pause', () => {
        audioRef.current.play()
      })

      setupIOSMediaSession(t)

      gaSendEvent(GA_EVENTS.HEADPHONES)
    })
  }, [tracks, track])

  useEffect(() => {
    // Initial Delay check
    setTimeout(() => {
      delayCheck()
      setAudioGain(1)
    }, 500)

    interval.current = setInterval(
      () => {
        delayCheck()
      },
      track?.longAudio
        ? audioConfig.longAudioDelayCheckFrequencyMs
        : audioConfig.delayCheckFrequencyMs,
    )

    return () => {
      if (interval.current) clearInterval(interval.current)
    }
  }, [startTime, state])

  useEffect(() => {
    setPlayState(state)
    if (state == PlayerState.ERROR) {
      if (interval.current) clearInterval(interval.current)
      if (audioCtx.current) audioCtx.current.close()
      gaSendEvent(GA_EVENTS.SYNC_ISSUE)
    }
  }, [state])

  useEffect(() => {
    if (!bufferLoaded) return

    replaceBuffer(audioId, state)
  }, [audioId, allBuffersLoaded])

  useEffect(() => {
    // Connect gain node to bufferSrc when it is created/changed.
    // Used for Android Muting.
    if (gainNode.current && bufferSrc) bufferSrc.connect(gainNode.current)

    // Start audio if we have clock sync and a new bufferSrc has been set.
    if (clockSynced && audioAllowed && bufferLoaded) {
      playSound()
    }
  }, [bufferSrc, bufferLoaded, audioAllowed])

  useEffect(() => {
    // Reload Audio with new offsets if playing
    if (state === PlayerState.PLAYING) {
      reloadAudioContext()
    }
  }, [startTime])

  const play = useCallback((): boolean => {
    if (!clockSynced || state !== PlayerState.INITIALIZED || !bufferLoaded) {
      return false
    }

    // Set initial offset
    streamOffset.current = audioCtx.current.currentTime

    playSound()
    reloadAudioContext()
    return true
  }, [clockSynced, state])

  useEffect(() => {
    // Set up gain node for Android muting
    if (isAndroid && !gainNode.current && audioCtx.current) {
      gainNode.current = audioCtx.current.createGain()
      gainNode.current.connect(audioCtx.current.destination)
    }
  }, [])

  /**
   * Rudderstack Audio Analytics tracking!
   */
  useRudderstackAudioAnalytics({
    track,
    audioCtx,
    playerState: state,
  })

  return {
    audioRef,
    audioCtx,
    bufferSrc,
    state,
    play,
    toggleMuted,
    muted,
    allBuffersLoaded,
  }
}
