import type {
  FC,
  JSXElementConstructor,
  PropsWithChildren,
  ReactElement } from 'react'
import {
  useMemo,
} from 'react'

import memoize from 'lodash/memoize'
import type {
  Transform } from 'react-html-parser'
import ReactHtmlParser, {
  convertNodeToElement,
  processNodes,
} from 'react-html-parser'

/**
 * A mapping of tags to React components. Use this to replace tags with
 * components out of the typography. For example, see {@link HtmlContent}.
 */
export type ComponentMap = Record<string, FC<PropsWithChildren>>

type Props = {
  /**
   * An optional list of allowed HTML tags. If provided, only these tags will
   * be rendered. All other tags will be converted to a `span`.
   */
  allowedHtmlTags?: readonly string[]

  /**
   * A mapping of tags to React components. Use this to replace tags with
   * components out of the typography. For example, see {@link HtmlContent}.
   */
  components?: ComponentMap
}

/** A function that turns a HTML string into safe React element */
type RType = (
  value: string | undefined,
) => ReactElement<any, string | JSXElementConstructor<any>>[] | undefined

/**
 * A hook to parse HTML content, allowing only a specific set of HTML tags.
 *
 * @returns a function that accepts a string and returns a React element.
 * Strings are memoised, so you don't need to call `useMemo`.
 */
export const useHtmlContent = ({
  allowedHtmlTags,
  components,
}: Props): RType => {
  return useMemo(() => {
    const allowed = new Set(allowedHtmlTags)

    const transform: Transform = (node, index) => {
      // Allow text nodes through
      if (node.type !== 'tag') return undefined

      const isAllowed = allowed.has(node.name)
      const Component = components?.[node.name]

      if (isAllowed && Component) {
        // This tag type is allowed, and is mapped to a new component type
        // return the new component type.
        return (
          <Component key={index}>
            {processNodes(node.children, transform)}
          </Component>
        )
      }

      // Allow all other allowed tags through
      if (isAllowed) {
        return undefined
      }

      // Convert denied node to a span

      node.name = 'span'

      node.attribs = {}

      return convertNodeToElement(node, index, transform)
    }

    return memoize((value: string | undefined) =>
      value
        ? ReactHtmlParser(value, {
            transform,
          })
        : undefined,
    )
  }, [allowedHtmlTags, components])
}
