import * as z from 'zod'

import {
  ZApiOptions,
  ZBundleInfo,
  ZCommonProperties,
  ZEntityDetails,
  ZFullLocation,
} from './commonSchema'

/**
 * The type of `track` event.
 *
 * Properties change based on track event.
 */
export enum TrackEventType {
  MEDIA_PLAYED = 'Media Played',
  ENTITY_ACTION = 'Entity Action',
}

/** The trigger for the `track` */
export enum EntityActionTrigger {
  /** Triggered from user action (directly) */
  USER = 'user',
  /** Triggered from system action (indirectly) */
  SYSTEM = 'system',
}

/** Type of entity action */
export enum EntityActionType {
  OBJECT_SAVED = 'Object Saved',
  EXTERNAL_LINK_SELECTED = 'External Link Selected',
}

/** Commmon Props to all track events */
const ZTrackEventCommon = z
  .object({
    apiOptions: ZApiOptions,
  })
  .strict()

/** Common props to all entity-action track events */
const ZEntityActionCommonProps = z
  .object({
    /** The entity details of the entity that the action is being taken on */
    target: ZEntityDetails,

    /** What triggered the event, system, user, or something else */
    trigger: z.nativeEnum(EntityActionTrigger),

    /** Device location, if known */
    device_location: ZFullLocation.optional(),

    /** See the media played event for more information about these */
    contextual_entities: z.array(ZEntityDetails).default([]),

    /** The bundle details */
    bundle: ZBundleInfo,
  })
  .strict()

/**
 * Track events (everything that isn't a page event)
 *
 * This is a discriminated union of possible track events, which include:
 *
 * - MEDIA_PLAYED
 * - ENTITY_ACTION
 */
export const ZTrackEvent = z.discriminatedUnion('event', [
  /**
   * Media Played
   * @see https://artprocessors.atlassian.net/wiki/spaces/DAI/pages/1998356528/Pladia+Visitor+Apps+-+Digital+Event+Spec+v0.1#Media-Played
   */
  ZTrackEventCommon.extend({
    event: z.literal(TrackEventType.MEDIA_PLAYED),

    properties: ZCommonProperties.extend({
      /** The entity details of the media being played */
      target: ZEntityDetails,

      /** The entity details of the media group if we have one */
      group: ZEntityDetails.optional(),

      /** The language of the media, default to the device language if we only have that */
      language: z.string().min(1),

      /** The duration of the media */
      duration: z.number().min(0),

      /** Track amount played, should never be higher then duration */
      duration_played: z.number().min(0),

      /** Device location, if known */
      device_location: ZFullLocation.optional(),

      /**
       * The contextual entities for this event. For media played events the
       * contextual entity is the entity details of the owner of the player.
       * Owner is defined as the parent entity of the player.
       *
       * For example: When the media is played as part of catalog-object details
       * page: the owner will be a reference to the catalog object.
       *
       * When the media is played as part of a “spaces” detail page: the owner
       * will be a reference to the space feature
       */
      contextual_entities: z.array(ZEntityDetails).default([]),

      /** The bundle details */
      bundle: ZBundleInfo,

      /** Custom object of anything for ED data. Must be sent, even empty */
      custom: z
        .object({
          connection_type: z.string().optional(),
          content_id: z.string().optional(),
          sequence: z.number().optional(),
          long_audio: z.boolean().optional(),
        })
        .default({
          connection_type: '',
          content_id: '',
          sequence: 0,
          long_audio: false,
        }),
    }).strict(),
  }).strict(),

  /**
   * Entity Action
   * @see https://artprocessors.atlassian.net/wiki/spaces/DAI/pages/1998356528/Pladia+Visitor+Apps+-+Digital+Event+Spec+v0.1#Entity-Action
   */
  ZTrackEventCommon.extend({
    event: z.literal(TrackEventType.ENTITY_ACTION),

    properties: z.discriminatedUnion('action', [
      ZEntityActionCommonProps.extend({
        action: z.literal(EntityActionType.OBJECT_SAVED),

        custom: z.object({}),
      }).strict(),

      ZEntityActionCommonProps.extend({
        action: z.literal(EntityActionType.EXTERNAL_LINK_SELECTED),

        custom: z.object({
          external_url: z.string().url(),
        }),
      }).strict(),
    ]),
  }).strict(),
])

/**
 * The track event type, which is a combination of the common data and the track
 * specific data.
 */
export type TrackEvent = z.input<typeof ZTrackEvent>
