export enum SyncObjectPlayState {
  Idle = 'IDLE',
  Playing = 'PLAYING',
}

export enum SystemObjectActiveState {
  Active = 'active',
  Inactive = 'inactive',
}

export type SyncObject = {
  content_id: string
  interactive_id: string
  event_time: number
  seq: number
  type: string
  play_state: SyncObjectPlayState
  elapsed_time: number
}

export type StateObject = {
  state: Array<SyncObject>
  seq: number
  system: SystemObject
  type: 'state'
}

export type ChangeObject = SyncObject & {
  type: 'change'
}

export type SystemObject = {
  type: 'system'
  seq?: number
  state: SystemObjectActiveState
  message: string
}

export function isStateObject(
  input: ChangeObject | StateObject,
): input is StateObject {
  return (input as StateObject).state instanceof Array
}
