import type { CSSProperties } from 'react'
import { useEffect, useMemo, useRef, useState } from 'react'

import intersection from 'lodash/intersection'

import { ImageWithFallbackWrapper } from './styles'
import type { HeroImageClassName, PictureProps } from './types'

import { BASIC_HTML_CONTENT_FORMATTING } from 'components/HtmlContent'
import { Text } from 'components/typography'
import { useAssetsSrcSet } from 'hooks/useAssetsSrcSet'
import { useHtmlContent } from 'hooks/useHtmlContent'
import { usePicture } from 'hooks/usePicture'
import { useSettings } from 'utils/settings'

type Props = {
  /** Picture to render */
  picture: PictureProps
  /** Allowed HTML tags (passed in from {@link ContentLayout}) */
  allowedHtmlTags?: ReadonlyArray<string>
  /** The fit mode passed in from {@link ContentLayout} */
  fit?: HeroImageClassName
  /** Whether to show the caption */
  showCaption?: boolean
  /** Classname for styling */
  className?: string
}

/** Rendering a single picture + caption */
export const SingleImage = ({
  picture: { picture, caption },
  allowedHtmlTags: parentAllowedHtmlTags,
  fit,
  showCaption = true,
  className,
}: Props): JSX.Element => {
  const { getTranslation } = useSettings()

  const allowedHtmlTags = useMemo(
    () => intersection(parentAllowedHtmlTags, BASIC_HTML_CONTENT_FORMATTING),
    [parentAllowedHtmlTags],
  )

  const imageRef = useRef<HTMLDivElement>(null)
  const [imageWidth, setImageWidth] = useState<number | undefined>()

  const getHtmlContent = useHtmlContent({ allowedHtmlTags })
  const assets = usePicture(picture.ref)

  const [asset] = assets

  const srcSet = useAssetsSrcSet(assets)
  const src = asset.url
  const altText = getTranslation(picture.altText)
  const translatedCaption = getTranslation(caption)

  // We can't watch the resize of the img, but we can watch the resize of
  // its container, and assume that it has been resized in turn.
  useEffect(() => {
    const elem = imageRef.current

    if (!showCaption || !elem) return

    const resizeObserver = new ResizeObserver(([entry]) => {
      // Retrieve the image from its container
      const img = entry.target.querySelector('img')

      if (!img) {
        setImageWidth(undefined)
        return
      }

      // We reliably know the height of the container
      // Calculate the true width of the image from the height of the container
      // using similar triangles
      const width =
        (entry.contentRect.height / img.naturalHeight) * img.naturalWidth

      setImageWidth(width)
    })

    resizeObserver.observe(elem)

    return () => {
      resizeObserver.unobserve(elem)
    }
  }, [showCaption])

  // This is needed because the size of the image in the StaticBlock
  // is not known until the image is loaded. Which means that the
  // space between sections in the object view tabs is not recalculated
  // until we scroll to the images, which causes the tabs to scroll to the
  // wrong place when they are selected before those images are loaded.
  const aspectRatio = useMemo(() => {
    if (!asset) return undefined
    return `${asset.width / asset.height}`
  }, [asset])

  return (
    <>
      <ImageWithFallbackWrapper
        /**
         * This shouldn't be an issue here, but the order of the attributes
         * matters for the `srcSet` to work correctly.
         *
         * Go into {@link ImageWithFallback} for more details.
         */
        srcSet={srcSet}
        src={src}
        alt={altText}
        noFallbackIcons
        className={className}
        $fit={fit}
        ref={imageRef}
        style={{ aspectRatio }}
      />

      {showCaption &&
        (translatedCaption ? (
          <Text
            $size="small"
            $weight="regular"
            className={className}
            style={
              {
                '--image-width': imageWidth ? `${imageWidth}px` : '100%',
              } as CSSProperties
            }
          >
            {getHtmlContent(translatedCaption)}
          </Text>
        ) : (
          // Placeholder in the grid
          <div />
        ))}
    </>
  )
}
