// Add data types to window.navigator for use in this file.
/// <reference types="user-agent-data-types" />

export function getIsAndroid() {
  const ua = navigator.userAgent.toLowerCase()
  return ua.indexOf('android') > -1
}

export function getIsIOS() {
  const ua = navigator.userAgent.toLowerCase()
  return /iphone|ipad|ipod/.test(ua)
}

export const getAndroidVersion = async () => {
  if (navigator.userAgentData) {
    try {
      const ua = await navigator.userAgentData.getHighEntropyValues([
        'platform',
        'platformVersion',
      ])
      if (ua?.platform?.toLowerCase() === 'android') {
        return Number(ua.platformVersion?.split('.')[0]) || null
      } else {
        return null
      }
    } catch (error) {
      console.error('Error fetching UA data:', error)
      return null
    }
  } else {
    const ua = navigator.userAgent
    const match = ua.match(/Android\s([0-9.]*)/)
    if (match) {
      return Number(match[1]) || null
    } else {
      return null
    }
  }
}

export const getIOSVersion = () => {
  const ua = navigator.userAgent
  const match = ua.match(/OS (\d+)_?(\d+)?/)
  if (match) {
    return Number(match[1]) || null
  } else {
    return null
  }
}
