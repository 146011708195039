import { useEffect, useState } from 'react'

import styled from 'styled-components'

import { AnimatedChildrenContainer } from 'components/AnimatedChildrenContainer'
import { Button } from 'components/Button'
import { useSyncPlayerContext } from 'components/SyncPlayer/SyncPlayerContext'
import { IconSyncProblemOutline } from 'components/icons'
import { Title, Text } from 'components/typography'
import { gaSendCustomEvent } from 'helpers/analytics'
import { isAnyOfPlayerStates } from 'helpers/player'
import { useLocaleTranslation } from 'hooks/useLocaleTranslation'
import { easeOutQuart } from 'styles/animations'
import { space } from 'theme/utils'
import { PlayerState } from 'types/player'

const SyncErrorView = styled(AnimatedChildrenContainer)`
  align-items: center;
  text-align: center;
  gap: ${space(10)};

  h2 {
    margin-bottom: -${space(6)};
  }
`

export const Container = styled.div`
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &.error {
    & div.logo {
      transition-delay: 0;
      transition: transform 1000ms ${easeOutQuart};
      transform: scale(0.4);
    }
  }
`

export const PlayerError = () => {
  const { _ } = useLocaleTranslation('Player')
  const { state } = useSyncPlayerContext()

  const [previousStatus, setPreviousStatus] = useState<
    PlayerState | undefined
  >()

  const [show, setShow] = useState(false)

  const reloadApp = () => {
    gaSendCustomEvent('reloading')
    window.location.reload()
  }

  useEffect(() => {
    if (state === previousStatus) {
      return
    }

    // Do this to trigger the animation
    if (isAnyOfPlayerStates(state, PlayerState.ERROR)) {
      setShow(true)
    }

    setPreviousStatus(state)
  }, [state, previousStatus])

  return (
    <>
      <Container>
        <SyncErrorView show={show}>
          <IconSyncProblemOutline size={10} />
          <Title>{_('error-title')}</Title>
          <Text>{_('error-message')}</Text>
          <Button onClick={reloadApp}>{_('reload-button')}</Button>
        </SyncErrorView>
      </Container>
    </>
  )
}
