/**
 * This registration function is run in the window's context to register the
 * service worker.
 */

import { Workbox } from 'workbox-window'

import { debugLog } from './utils/debug'

export default function registerServiceWorker(): void {
  if (!('serviceWorker' in navigator)) {
    return
  }

  const wb = new Workbox('/sw.js')

  if (!wb) {
    return
  }

  wb.addEventListener('installed', event => {
    debugLog(
      'Service Worker',
      `installed, reload required = ${event.isUpdate ?? false}`,
    )
    /**
     * We have the condition — event.isUpdate because we don’t want to show
     * this message on the very first service worker installation,
     * only on the updated
     */
    if (event.isUpdate) {
      window.location.reload()
    }
  })

  wb.register()
    .then(() => {
      debugLog('Service Worker', 'registered')
    })
    // Registration failed, unregister all service workers so we don't end up
    // with an old one
    .catch(async exc => {
      console.error(exc)
      debugLog('Service Worker', 'unregistering all service workers')

      for (const sw of await navigator.serviceWorker.getRegistrations()) {
        sw.unregister()
      }
    })
}
