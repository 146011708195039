import { useEffect, useState } from 'react'

import classNames from 'classnames'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'

import { useAppContext } from 'context/AppContext'
import { useConfigProperties } from 'hooks/useEsConfig'
import { color, pxToRem } from 'theme/utils'

// Used for Background Animation on transition from intro to player
export const Backdrop = styled.div<{
  $backgroundImage?: string
}>`
  position: absolute;
  inset: 0;
  overflow: hidden;
  filter: blur(0px);
  margin: 0 auto;
  z-index: -1;
  max-width: ${pxToRem(768)};

  &:before {
    content: '';
    position: absolute;
    transition: filter 600ms ease-in, transform 1600ms 100ms ease-in-out,
      opacity 480ms ease-in;
    transform: scale(1.15);
    opacity: 0;
    transform-origin: 50% 50%;
    inset: 0;
    background-color: ${color.all('primary')};
    background-image: url(${({ $backgroundImage }) => $backgroundImage});
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
  }

  &:after {
    overflow: hidden;
    content: '';
    position: absolute;
    inset: 0;
    background-image: linear-gradient(
      180deg,
      transparent 0%,
      ${color.surface('transparentSurface0')} 75%,
      ${color.surface('surface0')} 100%
    );
  }

  &:not(.animated) {
    transition: none;
    &:before {
      transform: scale(1);
      opacity: 0.6;
      transition: none;
    }
  }

  &.show {
    &:before {
      transform: scale(1);
      opacity: 0.6;
    }
  }

  --blur: 10px;
  &.blur-level-1 {
    --blur: 10px;
  }
  &.blur-level-2 {
    --blur: 20px;
  }
  &.blur-level-3 {
    --blur: 30px;
  }

  &.blur {
    &:before {
      filter: blur(var(--blur));
    }
  }
`
type BackgroundProps = {
  blurLevel?: 1 | 2 | 3
  animated?: boolean
}
export const Background = ({
  blurLevel = 1,
  animated = false,
}: BackgroundProps) => {
  const { contentLoaded } = useAppContext()
  const { background } = useConfigProperties()
  const location = useLocation()

  const [blur, setBlur] = useState<boolean>(false)
  const [show, setShow] = useState<boolean>(false)

  useEffect(() => {
    // Ensure blurring only happens when on the player view.
    // We can not use `PlayerState.INITIALIZE` to do this due to audio preload happening as soon as the user lands on the intro screen.
    // This mean the player can enter an initialized state before the user reaches that player view and would blur the background.
    setBlur(location.pathname.indexOf('/player') > -1)
  }, [location])

  useEffect(() => {
    setShow(contentLoaded)
  }, [contentLoaded])

  return (
    <Backdrop
      className={classNames({
        blur,
        show,
        animated,
        [`blur-level-${blurLevel}`]: true,
      })}
      $backgroundImage={background[0]?.url}
    />
  )
}
