import type { PropsWithChildren } from 'react'
import { useEffect, useRef } from 'react'

import { AnimatePresence } from 'framer-motion'
import { FocusOn } from 'react-focus-on'

import { ModalFrame, ModalOverlay } from './styles'

import { slideInFromBottomAnimation } from 'components/animations/SlideInFromBottom'
import { useRefContext } from 'context/RefContext'

export { ModalFooter, ModalFrame } from './styles'

export { ModalContent } from './ModalContent'

export type ModalProps = PropsWithChildren & {
  /**
   * id is required by Framer Motion to avoid conflicts with other modals.
   */
  id: string
  /**
   * Hides or shows the container
   */
  isShowing?: boolean
  /**
   * Indicates the modal needs to be closed
   */
  onClose?: () => void
}

/**
 * Modal component for the PWA.
 * It animates in when isShowing is true and out when isShowing is false.
 *
 * @category Component
 */
export function Modal({
  id,
  isShowing,
  children,
  onClose,
}: ModalProps): JSX.Element {
  const ref = useRef<HTMLDivElement>(null)
  const { setRef, getRef, removeRef } = useRefContext()

  useEffect(() => {
    if (getRef(id)) return

    setRef(id, ref)

    return () => {
      removeRef(id)
    }
  })

  return (
    <AnimatePresence mode="sync">
      {isShowing ? (
        <FocusOn>
          <ModalOverlay onClick={onClose} />
          <ModalFrame
            id={id}
            key={id}
            ref={ref}
            {...slideInFromBottomAnimation}
          >
            {children}
          </ModalFrame>
        </FocusOn>
      ) : null}
    </AnimatePresence>
  )
}
