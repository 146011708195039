import { config } from '../../settings/config'
import type { AnalyticsConfig, Config } from '../../settings/types'

/**
 * Getter all for config data if you know the path. Mainly used for top level\
 * config that won't change
 * @param configPath
 */
export const getConfigData = <K extends keyof Config>(
  configPath: K,
): Config[K] => config[configPath]

/**
 * Getter for the analytics data
 */
export const getAnalyticsData = (): AnalyticsConfig | undefined => {
  return getConfigData('analytics')
}
