import styled, { css } from 'styled-components'

import type { CSSClamp } from '../mixins'
import { cssClampMixin, hyphenateMixin } from '../mixins'

import type {
  ThemeFontSize,
  ThemeFontWeight,
  ThemeAccentColor,
  ThemeOnSurfaceColor,
  ThemeBaseColor,
} from 'theme/theme'
import { fontFamily, fontSize, fontWeight, color } from 'theme/utils'

type Props = {
  /**
   * Changes the type of rendered element.
   *
   * N.B. We are making this required in order to be explicit about the semantic level of the heading
   * for information architecure purposes.
   */
  as: 'span' | 'h3' | 'h4' | 'h5' | 'h6'
  /**
   * Visually changes the label size (opposed to using `as` which changes the semantic level)
   */
  $size?: Extract<ThemeFontSize, 'medium' | 'large'>
  /**
   * The font weight of the label
   */
  $weight?: Extract<ThemeFontWeight, 'medium' | 'semibold'>
  /**
   * Changes the color of the title
   */
  $color?: ThemeAccentColor | ThemeOnSurfaceColor | ThemeBaseColor
} & CSSClamp

/**
 * Label component
 *
 * Use `as` to change the label level, use `$size` to change the font size.
 *
 * Why not tie `as` with the font size? Because we want to be able to change
 * the font size without changing the label level - e.g. sometimes you may need an h1, but
 * visually it needs to look like an h3.
 */
export const Label = styled.h3<Props>`
  font-family: ${fontFamily('base')};
  font-size: ${fontSize('base')};
  font-weight: ${fontWeight('semibold')};
  line-height: 1.2;
  color: ${color.onSurface('onSurfaceHigh')};

  ${({ $size = 'medium' }) => {
    switch ($size) {
      case 'medium':
        return css`
          font-size: ${fontSize('medium')};
        `
      case 'large':
        return css`
          font-size: ${fontSize('base')};
          line-height: 1.28;
        `
    }
  }}

  ${({ $weight = 'semibold' }) => {
    switch ($weight) {
      case 'medium':
        return css`
          font-weight: ${fontWeight('medium')};
        `
      case 'semibold':
        return css`
          font-weight: ${fontWeight('semibold')};
        `
    }
  }}

  ${({ $color = 'onSurfaceHigh' }) => css`
    color: ${color.all($color)};
  `}

  ${cssClampMixin}
  ${hyphenateMixin}
`
