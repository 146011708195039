import type { AnimationProps } from 'framer-motion'

export const slideInFromBottomAnimation = {
  initial: { y: '100%', opacity: 0 },
  animate: { y: 0, opacity: 1 },
  exit: { y: '100%', opacity: 0 },
  transition: {
    opacity: {
      type: 'tween',
      ease: 'easeOut',
      duration: 0.3,
    },
    y: {
      type: 'spring',
      stiffness: 300,
      damping: 28,
      mass: 1,
      ease: 'easeOut',
      when: 'beforeChildren',
    },
    // We don't want the bounce on exit.
    exit: {
      type: 'tween',
      ease: 'easeOut',
      duration: 0.3,
      when: 'afterChildren',
    },
  },
} as const satisfies AnimationProps

/**
 * For use with objects that are absolutely positioned and centered horizontally
 */
export const horizontallyCenteredSlideInFromBottomAnimation = {
  ...slideInFromBottomAnimation,
  initial: { ...slideInFromBottomAnimation.initial, x: '-50%' },
  animate: { ...slideInFromBottomAnimation.animate, x: '-50%' },
  exit: { ...slideInFromBottomAnimation.exit, x: '-50%' },
} satisfies AnimationProps
