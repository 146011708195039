import { hexFromArgb, Hct } from '@material/material-color-utilities'
import type { TonalPalette } from '@material/material-color-utilities'
import _Color from 'color'

export const parseColor = (
  color: string,
): {
  r: number
  g: number
  b: number
} => {
  const match = color.match(/#((?:[0-9A-F]{1,2}){3})/i)
  if (!match) {
    throw new Error('invalid color format')
  }

  const [r, g, b] = match[1]
    .repeat(2)
    .slice(-6)
    .match(/.{2}/g)!
    .map(hex => parseInt(hex, 16))

  return { r, g, b }
}

export const isGrayscale = (color: string): boolean => {
  const { r, g, b } = parseColor(color)
  return r === g && r === b
}

/**
 * Pure black and white return a blue
 * palette and red respectively.. thx Material 3.
 * so we override the chroma value to force it
 * into grayscale 👻
 */
export const getColor = (
  tone: number,
  palette: TonalPalette,
  grayScalecheck?: boolean,
): string =>
  grayScalecheck
    ? hexFromArgb(
        Hct.from(
          0,
          // set the chroma to 0!
          0,
          palette.getHct(tone).tone,
        ).toInt(),
      )
    : hexFromArgb(palette.tone(tone))

export const getTransparentColor = (
  tone: number,
  palette: TonalPalette,
  grayScalecheck: boolean,
  opacity: number,
): string =>
  _Color(getColor(tone, palette, grayScalecheck))
    .alpha(opacity)
    .hexa()
    .toString()

export const getTextColor = (
  tone: number,
  palette: TonalPalette,
  light = '#ffffff',
  dark = '#000000',
): string => (_Color(hexFromArgb(palette.tone(tone))).isDark() ? light : dark)
