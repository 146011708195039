import type {
  ContentLayoutProps,
  ContentLayoutRegistration,
  HasContentFunc } from 'components/ContentLayout'
import {
  CONTENT_LAYOUT_REGISTRY,
} from 'components/ContentLayout'
import { HtmlContent } from 'components/HtmlContent'
import { HtmlBlock } from 'schemas/ocelot-content'
import { useSettings } from 'utils/settings'

/**
 * html-block **layout**, this should be looked up via
 * {@link CONTENT_LAYOUT_REGISTRY}
 *
 * If you're looking for the content block of the same name, see
 * `registrations/content-block/html-block/`
 */
const Layout = ({
  layout,
  allowedHtmlTags,
}: ContentLayoutProps<HtmlBlock>): JSX.Element => {
  const { getTranslation } = useSettings()

  return (
    <HtmlContent
      value={getTranslation(layout.html)}
      allowedHtmlTags={allowedHtmlTags}
    />
  )
}

const hasContent: HasContentFunc<HtmlBlock> = ({
  layout,
  settings: { defaultLanguage },
}) => {
  // The default language is required, so we only need to check the default
  // language
  return !!layout.html[defaultLanguage]
}

CONTENT_LAYOUT_REGISTRY.register(HtmlBlock.typename, {
  Layout,
  hasContent,
} satisfies ContentLayoutRegistration<HtmlBlock>)
