import { useMemo } from 'react'

import { UAParser } from 'ua-parser-js'

import type { Context } from './commonSchema'

import { useConfigProperties } from 'hooks/useEsConfig'
import { getDeviceID } from 'storage/local-storage'
import { debugLog } from 'utils/debug'
import getEnv from 'utils/getEnv'

const getTimeZone = (): string => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone
}

// Get the platform and platformVersion from the legacy API
// This will be fuzzed in Chrome
const { os } = new UAParser().getResult()
let platform = os.name
let platformVersion = os.version

// If available also try the newer API, which is not fuzzed
if (navigator.userAgentData) {
  navigator.userAgentData
    .getHighEntropyValues(['platform', 'platformVersion'])
    .then(ua => {
      debugLog('Using userAgentData API to get platform', ua)
      if (ua.platform) platform = ua.platform
      if (ua.platformVersion) platformVersion = ua.platformVersion
    })
    .catch(console.error)
}

/**
 * Gather the common data for all events. If this fails,
 * throw an exception as we don't want to continue without
 * this data.
 */
export const useCommonRudderstackData = (): Context => {
  const { name } = useConfigProperties()

  return useMemo(() => {
    const env = getEnv()
    const deviceId = getDeviceID()

    return {
      client: env.VITE_CLIENT ?? '',
      project: env.VITE_PROJECT ?? '',
      env: env.VITE_DEPLOY_ENV ?? '',
      product: 'eventsync',
      customer: env.VITE_PROJECT ?? '',
      debug: env.VITE_DEPLOY_ENV === 'dev' || !import.meta.env.PROD,
      spec_version: '0.1',
      app: {
        type: 'pwa',
        version: env.VITE_APP_VERSION ?? '',
        build: env.VITE_APP_RELEASE_VERSION || 'dev',
        name: name ?? '',
        role: 'byod',
      },
      device: {
        id: deviceId,
        os_version: platformVersion,
        os: platform?.toLocaleLowerCase() || 'unknown',
        time_zone: getTimeZone(),
        locale: navigator.language,
      },
    } satisfies Context
  }, [name])
}
