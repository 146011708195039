import type { MutableRefObject } from 'react'
import { useRef, useState } from 'react'

export function useReferredState<T>(
  initialValue: T,
): [MutableRefObject<T>, (value: T) => void] {
  const [state, setState] = useState<T>(initialValue)
  const reference = useRef(state)

  const setReferredState = (value: T) => {
    reference.current = value
    setState(value)
  }

  return [reference, setReferredState]
}
