import type { IconType } from '../icons'

import type { ThemeOnSurfaceColor, ThemeSurfaceColor } from 'theme/theme'
import type { Spacing } from 'theme/utils'

export enum ImageLoadState {
  LOADING = 'loading',
  LOADED = 'loaded',
  ERROR = 'error',
}

export type ImageFit = 'contain' | 'cover' | 'fill'

export type ImageFallback =
  | string
  | {
      background?: ThemeSurfaceColor
      color?: ThemeOnSurfaceColor
      Icon?: IconType
      size?: Spacing
    }

export type ImageWithFallbackProps = {
  /**
   * As HTML spec
   */
  src?: string
  /**
   * As HTML spec
   */
  srcSet?: string
  /**
   * As HTML spec.
   *
   * While not required, it's encourage to use this prop when an image doesn't fill the entire viewport (usually width). Otherwise, 100vw is used as the default.
   * If the image remains a constant size, simply use the width of the image as it is rendered in the DOM.
   */
  sizes?: string
  /**
   * This custom property leverages CSS to make the image fill or be boxed in by its parent component.
   */
  fit?: ImageFit
  /**
   * Default background for the image container
   */
  background?: ThemeSurfaceColor
  /**
   * As HTML spec
   */
  crossOrigin?: 'anonymous' | 'use-credentials'
  /**
   * As HTML spec
   */
  className?: string
  /**
   * As HTML spec
   */
  alt?: string

  style?: React.CSSProperties

  width?: string | number

  height?: string | number
  /**
   * Fallback to either a string value or an icon represented by an IconType and an optional size,
   * which defaults to 'medium' in the component.
   *
   * If no fallback is provided, the component will use the default fallback.
   *
   * If a fallback is provided without an icon, the icon won't be rendered.
   *
   * If a background is provided, the background will be applied for the fallback in all states.
   */
  fallback?: ImageFallback
  /**
   * If true, the component will not show fallback icons.
   */
  noFallbackIcons?: boolean
  /**
   * Sets how the background should behave when the background prop is set.
   * - 'all' will show the background in all states.
   * - 'fallback-only' will show the background only when the image is in a fallback state.
   * - 'loaded-only' will show the background only when the image is in a loaded state.
   *
   * @default 'all'
   */
  backgroundMode?: 'all' | 'fallback-only' | 'loaded-only'
}
