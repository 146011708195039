import { Overlay, IndeterminateIndicator } from './styles'

import { Title } from 'components/typography'

type Props = {
  /**
   * The headline to display in the overlay. If `null`, no headline will be displayed.
   */
  headline?: string
  /**
   * The variant of the overlay to display.
   *
   * Opaque will display a semi-transparent background, solid will display a solid background.
   */
  variant?: 'solid' | 'opaque'
}

/**
 * A fullscreen loading overlay that covers the entire viewport
 */
export const LoadingOverlay = ({ headline, variant }: Props): JSX.Element => (
  <Overlay $variant={variant}>
    <IndeterminateIndicator />
    {headline && <Title $size="small">{headline}</Title>}
  </Overlay>
)
