import { v4 as uuidv4 } from 'uuid'
import { z } from 'zod'

import type { GazelleRef } from '@apsys/gazelle'
import { GazelleRefSchema } from '@apsys/gazelle'

import { LOCAL_STORAGE } from '../enums'
import { EileenColorScheme } from '../schemas/eileen-service-stylesheet'
import type { Manifest } from '../schemas/vixen-core-manifest'
import { debugError } from '../utils/debug'

enum Storage {
  language = 'LANGUAGE',
  experience = 'EXPERIENCE',
  version = 'BUNDLE_VERSION',
  themeVariant = 'THEME_VARIANT',
  deviceID = 'DEVICE_ID',
  eventManifest = 'EVENT_MANIFEST',
  esConfig = 'ES_CONFIG',
}

export const storageDefaults = {}

export const setBundleVersion = (version: string): void =>
  localStorage.setItem(Storage.version, version)

export const getBundleVersion = (): string | null =>
  localStorage.getItem(Storage.version)

/** Validate the deviceId value */
const DEVICE_ID_SCHEMA = z.string().uuid()

/**
 * Sets the deviceID to a new random UUID and returns it.
 *
 * @internal -- only called by startup or by the device getter
 */
export const setDeviceID = (): void => {
  const deviceId = localStorage.getItem(LOCAL_STORAGE.DEVICE_ID)
  if (!DEVICE_ID_SCHEMA.safeParse(deviceId).success) {
    localStorage.setItem(LOCAL_STORAGE.DEVICE_ID, uuidv4())
  }
}

/**
 * Get the current device ID. Used also for Rudderstack. If we don't have one,
 * we should set one.
 *
 * @internal use {@link getEventMetadata}
 */
export const getDeviceID = (): string => {
  return DEVICE_ID_SCHEMA.parse(localStorage.getItem(LOCAL_STORAGE.DEVICE_ID))
}

export const setEventManifest = (
  manifest: GazelleRef<Manifest> | undefined,
): void => {
  if (manifest) {
    localStorage.setItem(Storage.eventManifest, JSON.stringify(manifest))
  }
}

/**
 * Returns a GazelleRef to the current manifest from localstorage.
 */
export const getEventManifest = (): GazelleRef<Manifest> | null => {
  const manifest = localStorage.getItem(Storage.eventManifest)

  if (!manifest) return null

  try {
    const manifestObject = JSON.parse(manifest)

    return GazelleRefSchema.parse(manifestObject)
  } catch (e) {
    debugError('Error parsing manifest', e)
  }

  return null
}

/**
 * Set the theme variant in local storage. Do this via {@link AppContext}.
 */
export const setThemeVariant = (
  theme: EileenColorScheme.Mode | null,
): void => {
  if (theme !== null) {
    localStorage.setItem(Storage.themeVariant, theme)
  } else {
    localStorage.removeItem(Storage.themeVariant)
  }
}

const THEME_VARIANT_SCHEMA = z.nativeEnum(EileenColorScheme.Mode).nullable()

/**
 * Retrieve the theme variant from localstorage. However get this value from
 * {@link AppContext}.
 */
export const getThemeVariant = (): EileenColorScheme.Mode | null => {
  const value = THEME_VARIANT_SCHEMA.safeParse(
    localStorage.getItem(Storage.themeVariant),
  )
  if (value.success) return value.data
  return null
}

/**
 * Removes experience
 */
export const resetExperience = (): void => {
  localStorage.removeItem(Storage.experience)
}

/**
 * Clears all localstorage.
 */
export const clearStorage = (): void => {
  localStorage.clear()
}
