/* eslint-disable */
import * as z from 'zod'

import {
  Struct,
  Entity,
  Meta,
  recase,
  GazelleRef,
  GazelleRefSchema,
} from '@apsys/gazelle'
import { BundleLoader, GazelleEntityFactory, ValueOf } from '@apsys/gazelle'
import { ReffedEntity as ReffableEntity, WithRef } from '@apsys/gazelle'

import * as VixenAssets from './vixen-assets'
import * as VixenCore from './vixen-core'
import * as VixenSpatial from './vixen-spatial'

export const meta: Meta = {
  name: 'LiveSync Core',
  moduleName: 'livesync-core',
  version: '0.2.0',
  authors: ['Art Processors <developers@artprocessors.net>'],
}

// === enums ===
// === interfaces ===
// --- livesync-presentation ---
export interface LivesyncPresentationSchema {}

export interface ILivesyncPresentation {}

// --- livesync-interactive ---
export interface LivesyncInteractiveSchema
  extends VixenCore.NamedSchema,
    VixenSpatial.PositionableSchema {
  presentation: GazelleRefSchema
}

export interface ILivesyncInteractive
  extends VixenCore.INamed,
    VixenSpatial.IPositionable {
  presentation: GazelleRef<ILivesyncPresentation>
}

// === structs ===
// === entities ===
export interface LivesyncPassivePresentationSchema
  extends LivesyncPresentationSchema {
  media: Array<LivesyncPassivePresentation.MediaSchema>
}

export interface ILivesyncPassivePresentation extends ILivesyncPresentation {
  media: Array<LivesyncPassivePresentation.Media>
}

type LivesyncPassivePresentationSchemaType = z.Schema<
  LivesyncPassivePresentation,
  z.ZodTypeDef,
  LivesyncPassivePresentationSchema
>

export const LivesyncPassivePresentationSchema = z
  .lazy(() =>
    z.object({
      ref: GazelleRefSchema,
      media: z.array(LivesyncPassivePresentation.MediaSchema),
    }),
  )
  .transform(
    value => new LivesyncPassivePresentation(recase(value)),
  ) satisfies LivesyncPassivePresentationSchemaType

export class LivesyncPassivePresentation
  extends Entity<LivesyncPassivePresentationSchema>
  implements ILivesyncPassivePresentation
{
  static readonly typename = 'livesync-passive-presentation'
  static readonly schema: LivesyncPassivePresentationSchemaType =
    LivesyncPassivePresentationSchema
  static readonly parse = LivesyncPassivePresentationSchema.parse
  static readonly fields = ['ref', 'media']

  readonly typename = 'livesync-passive-presentation'

  ref!: GazelleRef<LivesyncPassivePresentation>
  media!: Array<LivesyncPassivePresentation.Media>

  constructor(
    attrs: ILivesyncPassivePresentation & WithRef<LivesyncPassivePresentation>,
  ) {
    super(attrs)
  }
}

export namespace LivesyncPassivePresentation {
  export interface MediaSchema {
    'media-id': string
    foreground: GazelleRefSchema
    background?: GazelleRefSchema | null
    'sync-delay': number
  }

  export interface IMedia {
    mediaId: string
    foreground: GazelleRef<VixenAssets.Audio>
    background?: GazelleRef<VixenAssets.Audio>
    syncDelay: number
  }

  type MediaSchemaType = z.Schema<Media, z.ZodTypeDef, MediaSchema>

  export const MediaSchema = z
    .lazy(() =>
      z.object({
        'media-id': z.string(),
        foreground: GazelleRefSchema,
        background: GazelleRefSchema.nullish(),
        'sync-delay': z.number(),
      }),
    )
    .transform(value => new Media(recase(value))) satisfies MediaSchemaType

  export class Media extends Struct<MediaSchema> implements IMedia {
    static readonly typename = 'media'
    static readonly schema: MediaSchemaType = MediaSchema
    static readonly parse = MediaSchema.parse
    static readonly fields = [
      'media-id',
      'foreground',
      'background',
      'sync-delay',
    ]

    readonly typename = 'media'

    mediaId!: string
    foreground!: GazelleRef<VixenAssets.Audio>
    background?: GazelleRef<VixenAssets.Audio>
    syncDelay!: number

    constructor(attrs: IMedia) {
      super(attrs)
    }
  }
}

export interface LivesyncActivePresentationSchema
  extends LivesyncPresentationSchema {
  media: Array<LivesyncActivePresentation.MediaSchema>
}

export interface ILivesyncActivePresentation extends ILivesyncPresentation {
  media: Array<LivesyncActivePresentation.Media>
}

type LivesyncActivePresentationSchemaType = z.Schema<
  LivesyncActivePresentation,
  z.ZodTypeDef,
  LivesyncActivePresentationSchema
>

export const LivesyncActivePresentationSchema = z
  .lazy(() =>
    z.object({
      ref: GazelleRefSchema,
      media: z.array(LivesyncActivePresentation.MediaSchema),
    }),
  )
  .transform(
    value => new LivesyncActivePresentation(recase(value)),
  ) satisfies LivesyncActivePresentationSchemaType

export class LivesyncActivePresentation
  extends Entity<LivesyncActivePresentationSchema>
  implements ILivesyncActivePresentation
{
  static readonly typename = 'livesync-active-presentation'
  static readonly schema: LivesyncActivePresentationSchemaType =
    LivesyncActivePresentationSchema
  static readonly parse = LivesyncActivePresentationSchema.parse
  static readonly fields = ['ref', 'media']

  readonly typename = 'livesync-active-presentation'

  ref!: GazelleRef<LivesyncActivePresentation>
  media!: Array<LivesyncActivePresentation.Media>

  constructor(
    attrs: ILivesyncActivePresentation & WithRef<LivesyncActivePresentation>,
  ) {
    super(attrs)
  }
}

export namespace LivesyncActivePresentation {
  export interface MediaSchema {
    video: GazelleRefSchema
    foreground: GazelleRefSchema
    background?: GazelleRefSchema | null
    'sync-delay': number
  }

  export interface IMedia {
    video: GazelleRef<VixenAssets.Video>
    foreground: GazelleRef<VixenAssets.Audio>
    background?: GazelleRef<VixenAssets.Audio>
    syncDelay: number
  }

  type MediaSchemaType = z.Schema<Media, z.ZodTypeDef, MediaSchema>

  export const MediaSchema = z
    .lazy(() =>
      z.object({
        video: GazelleRefSchema,
        foreground: GazelleRefSchema,
        background: GazelleRefSchema.nullish(),
        'sync-delay': z.number(),
      }),
    )
    .transform(value => new Media(recase(value))) satisfies MediaSchemaType

  export class Media extends Struct<MediaSchema> implements IMedia {
    static readonly typename = 'media'
    static readonly schema: MediaSchemaType = MediaSchema
    static readonly parse = MediaSchema.parse
    static readonly fields = [
      'video',
      'foreground',
      'background',
      'sync-delay',
    ]

    readonly typename = 'media'

    video!: GazelleRef<VixenAssets.Video>
    foreground!: GazelleRef<VixenAssets.Audio>
    background?: GazelleRef<VixenAssets.Audio>
    syncDelay!: number

    constructor(attrs: IMedia) {
      super(attrs)
    }
  }
}

export interface LivesyncPassiveInteractiveSchema
  extends LivesyncInteractiveSchema {
  'interactive-id': string
}

export interface ILivesyncPassiveInteractive extends ILivesyncInteractive {
  interactiveId: string
}

type LivesyncPassiveInteractiveSchemaType = z.Schema<
  LivesyncPassiveInteractive,
  z.ZodTypeDef,
  LivesyncPassiveInteractiveSchema
>

export const LivesyncPassiveInteractiveSchema = z
  .lazy(() =>
    z.object({
      ref: GazelleRefSchema,
      position: VixenSpatial.PositionSchema.nullish(),
      name: z.record(z.string()),
      presentation: GazelleRefSchema,
      'interactive-id': z.string(),
    }),
  )
  .transform(
    value => new LivesyncPassiveInteractive(recase(value)),
  ) satisfies LivesyncPassiveInteractiveSchemaType

export class LivesyncPassiveInteractive
  extends Entity<LivesyncPassiveInteractiveSchema>
  implements ILivesyncPassiveInteractive
{
  static readonly typename = 'livesync-passive-interactive'
  static readonly schema: LivesyncPassiveInteractiveSchemaType =
    LivesyncPassiveInteractiveSchema
  static readonly parse = LivesyncPassiveInteractiveSchema.parse
  static readonly fields = [
    'ref',
    'position',
    'name',
    'presentation',
    'interactive-id',
  ]

  readonly typename = 'livesync-passive-interactive'

  ref!: GazelleRef<LivesyncPassiveInteractive>
  position?: VixenSpatial.Position
  name!: Record<string, string>
  presentation!: GazelleRef<ILivesyncPresentation>
  interactiveId!: string

  constructor(
    attrs: ILivesyncPassiveInteractive & WithRef<LivesyncPassiveInteractive>,
  ) {
    super(attrs)
  }
}

export type INTERFACES = VixenAssets.INTERFACES &
  VixenCore.INTERFACES &
  VixenSpatial.INTERFACES & {
    'livesync-presentation': ILivesyncPresentation
    'livesync-interactive': ILivesyncInteractive
  }

export const ENTITIES = {
  ...VixenAssets.ENTITIES,
  ...VixenCore.ENTITIES,
  ...VixenSpatial.ENTITIES,
  'livesync-passive-presentation': LivesyncPassivePresentation,
  'livesync-active-presentation': LivesyncActivePresentation,
  'livesync-passive-interactive': LivesyncPassiveInteractive,
}
export type ENTITIES = {
  [K in keyof typeof ENTITIES]: InstanceType<(typeof ENTITIES)[K]>
}

export class Loader extends BundleLoader {
  static readonly ENTITIES = ENTITIES
  static readonly schemaVersion = '0.2.0'

  get<K extends keyof ENTITIES>(ref: {
    typename: K
    id: string
  }): ENTITIES[K] | undefined
  get<T extends ValueOf<ENTITIES> | ValueOf<INTERFACES> | Entity<any>>(
    ref: GazelleRef<T>,
  ): (T extends Entity<any> ? T : ReffableEntity<T> & T) | undefined
  get(ref: any) {
    return super.get(ref)
  }

  getAll<K extends keyof ENTITIES>(typename: K): Array<ENTITIES[K]>
  getAll<T extends ValueOf<ENTITIES>>(klass: GazelleEntityFactory<T>): Array<T>
  getAll(klass: any) {
    return super.getAll(klass)
  }

  getSingleton<K extends keyof ENTITIES>(typename: K): ENTITIES[K] | undefined
  getSingleton<T extends ValueOf<ENTITIES>>(
    klass: GazelleEntityFactory<T>,
  ): T | undefined
  getSingleton(klass: any) {
    return super.getSingleton(klass)
  }
}
