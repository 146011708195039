import { Block } from './Block'

import type {
  ContentBlockRegistration,
  HasContentFunc } from 'registrations/content-layout/content-stack'
import {
  CONTENT_BLOCK_REGISTRY,
} from 'registrations/content-layout/content-stack'
import { CarouselBlock } from 'schemas/ocelot-content'

const hasContent: HasContentFunc<CarouselBlock> = ({
  block,
  contentBundle,
}) => {
  // Check we have some published pictures
  return block.pictures.some(
    ({ picture: pictureRef }) => !!contentBundle.get(pictureRef),
  )
}

CONTENT_BLOCK_REGISTRY.register(CarouselBlock.typename, {
  Block,
  hasContent,
} satisfies ContentBlockRegistration<CarouselBlock>)
