import { useEffect, useMemo, useState } from 'react'

import classnames from 'classnames'

import { GA_EVENTS } from '../../constants'

import {
  Container,
  Label,
  Labels,
  LoadingIndicator,
  LoadingIndicatorContainer,
  PlayButton,
  Pulse,
  TextCycle,
} from './styles'

import { useSyncPlayerContext } from 'components/SyncPlayer/SyncPlayerContext'
import {
  IconHeadphonesSolid,
  IconVolume2Outline,
  IconVolumeXOutline,
  IconPlaySolid,
} from 'components/icons'
import { Visualizer } from 'components/visualiser'
import { gaSendEvent } from 'helpers/analytics'
import { getConfigData } from 'helpers/config'
import { disableVisualizer } from 'helpers/debug'
import { isAnyOfPlayerStates, isNoneOfPlayerStates } from 'helpers/player'
import { useLocaleTranslation } from 'hooks/useLocaleTranslation'
import { PlayerState } from 'types/player'
import { PageEventType, usePageEventTracking } from 'utils/rudderstack'

export const PlayerUI = () => {
  const { _ } = useLocaleTranslation('Player')
  const { state, play, toggleMuted, muted, allBuffersLoaded } =
    useSyncPlayerContext()
  const audioConfig = getConfigData('audioConfig')

  const [previousStatus, setPreviousStatus] = useState<
    PlayerState | undefined
  >()
  const isVisualizer = isNoneOfPlayerStates(
    state,
    PlayerState.IDLE,
    PlayerState.INITIALIZED,
  )

  const loading = isAnyOfPlayerStates(state, PlayerState.IDLE)
  const processing = isAnyOfPlayerStates(
    state,
    PlayerState.IDLE,
    PlayerState.SYNCING,
  )
  const syncing = isAnyOfPlayerStates(state, PlayerState.SYNCING)
  const loaded =
    isAnyOfPlayerStates(state, PlayerState.INITIALIZED) && allBuffersLoaded
  console.log('BUFFERS LOADED', allBuffersLoaded)
  const playing = useMemo(() => {
    return isAnyOfPlayerStates(state, PlayerState.PLAYING)
  }, [state])

  /**
   * Send playing event only when the player is playing and not muted
   */
  const sendPlayingEvent = useMemo(() => {
    return playing && !muted
  }, [playing, muted])

  usePageEventTracking({
    pageName: PageEventType.LISTENING,
    isShowing: sendPlayingEvent,
  })

  usePageEventTracking({
    pageName: PageEventType.LISTENING_SOUND_OFF,
    isShowing: muted,
  })

  const playHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    if (playing) {
      toggleMuted()
    } else {
      const currentlyPlaying = play()
      if (currentlyPlaying) {
        gaSendEvent(GA_EVENTS.PLAYING)
      }
    }
  }

  useEffect(() => {
    if (state === previousStatus) {
      return
    }

    setPreviousStatus(state)
  }, [state, previousStatus])

  const renderVisualizer = () => {
    const showVisualizer =
      audioConfig.showVisualizer && !disableVisualizer() && isVisualizer

    return <>{showVisualizer && <Visualizer />}</>
  }

  return (
    <>
      <Container>
        {renderVisualizer()}

        <LoadingIndicatorContainer>
          <LoadingIndicator className={classnames({ show: processing })} />
          <IconHeadphonesSolid
            size={6}
            className={classnames({ show: loading })}
          />
          <Pulse
            className={classnames({
              show: loaded,
            })}
          />
          <PlayButton
            className={classnames({ show: loaded || playing })}
            onClick={playHandler}
          >
            <IconPlaySolid size={6} className={classnames({ show: loaded })} />
            <IconVolumeXOutline
              size={6}
              className={classnames({ show: playing && muted })}
            />
            <IconVolume2Outline
              size={6}
              className={classnames({ show: playing && !muted })}
            />
          </PlayButton>
        </LoadingIndicatorContainer>

        <Labels>
          <TextCycle className={classnames({ show: loading })}>
            <Label>{_('loading')}</Label>
            <Label>{_('headphones')}</Label>
          </TextCycle>

          <Label className={classnames({ show: loaded })}>
            {_('headphones')}
          </Label>
          <Label className={classnames({ show: playing && muted })}>
            {_('sound-off')}
          </Label>
          <Label className={classnames({ show: playing && !muted })}>
            {_('sound-on')}
          </Label>
          <Label className={classnames({ show: syncing })}>
            {_('resync')}
          </Label>
        </Labels>
      </Container>
    </>
  )
}
