import memoize from 'lodash/memoize'

import type { Meta } from '@apsys/gazelle'
import { GazelleRef, checkVersion } from '@apsys/gazelle'

import getEnv from './getEnv'

import { SEARCH_PARAMS } from 'enums'
import {
  Loader as ContentLoader,
  meta as ContentMeta,
} from 'schemas/eileen-service'
import { Loader as ManifestLoader } from 'schemas/eileen-service-manifest'
import {
  Loader as StylesheetLoader,
  meta as StylesheetMeta,
} from 'schemas/eileen-service-stylesheet'
import { EsConfig } from 'schemas/eileen-sync-config'
import type {
  Manifest } from 'schemas/vixen-core-manifest'
import {
  Audience,
  GazelleContentBundle,
} from 'schemas/vixen-core-manifest'
import settings from 'settings'
import {
  setBundleVersion,
  setDeviceID,
  setEventManifest,
} from 'storage/local-storage'
import { debugError, debugPerformance, memoizedDebugError } from 'utils/debug'

const { VITE_APP_CONTENT_DOMAIN } = getEnv()

const DOMAIN = VITE_APP_CONTENT_DOMAIN // .replace(/\//g, '\\/')

/** Don't repeat the same log message over and over */
const logOnce = memoize(value => console.log(value))

/**
 * A wrapper around `fetch` that re-tries if it fails
 *
 *
 * @param url URL to fetch
 * @param retries number of retries to refresh the token
 * @category HTTP
 */
async function repeatableFetch(
  url: string,
  retries: number = 1,
): ReturnType<typeof fetch> {
  const response = await fetch(url, {
    method: 'GET',
  })

  if (response.status === 401 && retries > 0) {
    // Try again
    return repeatableFetch(url, retries - 1)
  }

  return response
}

/**
 * Wrapper around fetch() that does the following things:
 *
 * - checks the response
 * - parses the JSON
 *
 * @param url URL to fetch
 */
async function fetchAndParse(
  url: string,
  fix: boolean = true,
): Promise<unknown> {
  const response = await repeatableFetch(url)

  if (!response.ok) {
    const json = await response.json()
    throw json || { code: response.status, detail: response.statusText }
  }

  const text = await response.text()

  const urlFixer = (key: string, value: any) => {
    if (key === 'url') {
      return new URL(value, DOMAIN).toString()
    }
    return value as unknown
  }
  const json = fix ? JSON.parse(text, urlFixer) : JSON.parse(text)
  return json
}

/** Load the manifest loader (bundle) from the manifest file */
async function loadManifest(): Promise<ManifestLoader> {
  const searchParams = new URLSearchParams(window.location.search)

  // Override manifest audience type if url param `BUNDLE_AUDIENCE` is provided and is an `Audience` type.
  const urlAudienceParam = searchParams.get(SEARCH_PARAMS.BUNDLE_AUDIENCE)
  const urlAudience =
    urlAudienceParam &&
    Object.values(Audience).includes(urlAudienceParam as Audience)
      ? urlAudienceParam
      : 'public'

  const customer = window.DYNAMIC_ENV.CUSTOMER ?? 'testing'
  const event = window.DYNAMIC_ENV.EVENT ?? 'manual'
  const audience = urlAudience

  // Request manifest, that contains the reference to all other data files
  const response = await fetchAndParse(
    // `/data/${urlAudience}/latest/manifest.json`, // Used to access local testing bundle data
    new URL(
      `/customers/${customer}/${event}/${audience}/manifest.json`,
      DOMAIN,
    ).toString(),
  )
  const manifestLoader = new ManifestLoader(response)

  return manifestLoader
}

/** Load the content bundle */
async function loadContent(
  entry: GazelleContentBundle,
): Promise<ContentLoader> {
  const { url, schemaVersion } = entry

  const contentData = await fetchAndParse(url)
  const t1 = performance.now()
  // Pass the bundle through the Loader (expensive operation)
  const content = new ContentLoader(contentData)
  const t2 = performance.now()
  debugPerformance('Establish Loader for content', t2, t1)

  setBundleVersion(schemaVersion)

  return content
}

/** Load the stylesheet bundle */
async function loadStylesheet(
  entry: GazelleContentBundle | undefined,
): Promise<StylesheetLoader | null> {
  if (!entry) return null

  const { url } = entry

  const stylesheetData = await fetchAndParse(url)
  const t1 = performance.now()
  const stylesheet = new StylesheetLoader(stylesheetData)
  const t2 = performance.now()
  debugPerformance('Establish Loader for stylesheet', t2, t1)

  return stylesheet
}

/**
 * Get the esConfig from the content bundle
 *
 * This is the first `es-config` in the bundle.
 *
 * It can also be the `es-config` from the `es-config` URL search parameter.
 * This is used for testing.
 *
 * Used by {@link useEsConfig}.
 */
export function getEsConfig(contentBundle: ContentLoader): EsConfig | null {
  const searchParams = new URLSearchParams(window.location.search)

  for (const [esConfigId, fatal] of [
    // Check to see if there's a es-config passed in the URL
    [searchParams.get(SEARCH_PARAMS.ES_CONFIG), true],
    // Check local storage
    [localStorage.getItem('ES_CONFIG'), false],
    // Otherwise see if there's a value in the settings
    [settings.esConfig, true],
  ] satisfies Array<[string | null | undefined, boolean]>) {
    if (!esConfigId) continue

    // Look up the specific es-config, even if it's not really for us
    const ref = new GazelleRef<EsConfig>({
      typename: EsConfig.typename,
      id: esConfigId,
    })

    const esConfig = contentBundle.get(ref)

    if (!esConfig) {
      if (fatal) {
        memoizedDebugError('No es-config matching id')

        return null
      } else {
        memoizedDebugError('es-config matching id no longer available')
        continue
      }
    }

    logOnce(`Loading es-config ${esConfig.ref.id}: ${esConfig.name.en}`)
    localStorage.setItem('ES_CONFIG', esConfig.ref.id)

    return esConfig
  }

  // Get a list of all configs
  const esConfigs = contentBundle.getAll(EsConfig)

  if (esConfigs.length > 1) {
    memoizedDebugError(
      'Too many matching es-configs (set one in settings.ts):',
      esConfigs,
    )
  }

  const [esConfig] = esConfigs

  if (!esConfig) {
    debugError('No matching es-config found')
  }

  logOnce(`Loading es-config ${esConfig.ref.id}: ${esConfig.name.en}`)
  localStorage.setItem('ES_CONFIG', esConfig.ref.id)

  return esConfig
}

/**
 * Hook to fetch and load the data bundle.
 *
 * This hook only needs to be called at the top level. To access data
 * use one of the bundle access functions, i.e.
 *
 * - {@link useEsConfig}
 *
 */
export async function loadAllBundles(): Promise<{
  contentBundle: ContentLoader
  stylesheetBundle: StylesheetLoader | null
  manifestBundle: ManifestLoader | null
}> {
  // A few notes on terminology:
  // - a loader (or bundle) is a parsed structure for accessing the contents
  //   of a Gazelle file or IMDF archive.
  // - An "entry" is a record in the manifest to another file that needs to be
  //   loaded.
  //
  // This function does the following:
  // - Downloads the latest manifest file from a known URL
  // - Parses it into a Gazelle loader
  // - Retrieves the single manifest entity ("the manifest")
  // - Looks up the "entry" for the content bundle from the manifest
  // - Downloads and parses the content bundle into a Gazelle loader
  // - Retrieves the vx-config for the venue ID
  // - Looks up the spatial and stylesheet entries from the manifest
  // - Retrieves, downloads and parses the spatial and stylesheet entries
  // - Returns the content, spatial and stylesheet loaders
  const manifestLoader = await loadManifest()
  const manifest = manifestLoader.getSingleton('manifest')

  if (!manifest) {
    throw new Error('Could not load manifest')
  }

  // Append a generated UUID into localstorage so we can refer to it for events.
  setDeviceID()
  setEventManifest(manifest.ref)

  const bundles = manifest.contents.map(ref => manifestLoader.get(ref))

  /**
   * Find a bundle matching `meta`, return the `manifest-entry` for loading.
   */
  const findGazelleEntry = (meta: Meta): GazelleContentBundle | undefined => {
    // Try a bundle for the right module + right version
    return (
      bundles.find(
        (entry): entry is GazelleContentBundle =>
          entry instanceof GazelleContentBundle &&
          entry.schemaModule === meta.moduleName &&
          checkVersion(meta.version, entry.schemaVersion),
      ) ??
      // Oh no! Try a module for any version (will almost certainly fail loading)
      bundles.find(
        (entry): entry is GazelleContentBundle =>
          entry instanceof GazelleContentBundle &&
          entry.schemaModule === meta.moduleName,
      )
    )
  }

  // Look up the content bundle from the manifest
  const contentEntry = findGazelleEntry(ContentMeta)

  if (!contentEntry) {
    throw new Error('Failed to load content bundle (no entry matches)')
  }

  // Load the content bundle
  const contentBundle = await loadContent(contentEntry)

  // Look up the remaining bundles from the manifest (these are all optional)
  const stylesheetEntry = findGazelleEntry(StylesheetMeta)

  // Load the remaining (optional) bundles
  const [stylesheetBundle] = await Promise.all([
    loadStylesheet(stylesheetEntry)
      // Absorb errors loading stylesheet, we can carry on without it
      .catch(exc => {
        console.warn('Failed to load stylesheet', exc)
        return null
      }),
  ])

  return {
    contentBundle,
    stylesheetBundle,
    manifestBundle: manifestLoader,
  }
}

export const getManifestRef = (
  manifestLoader: ManifestLoader | null,
): GazelleRef<Manifest> | null => {
  if (!manifestLoader) {
    return null
  }

  const manifest = manifestLoader.getSingleton('manifest')
  return manifest?.ref ?? null
}
