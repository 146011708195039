import type { PropsWithChildren } from 'react'

import classNames from 'classnames'

import { StyledIcon } from './styles'
import type { GeneratedIconProps } from './types'

export const GeneratedIcon = ({
  size,
  color,
  className,
  children,
  ...rest
}: PropsWithChildren<GeneratedIconProps>) => {
  return (
    <StyledIcon
      className={classNames('icon', className)}
      $color={color}
      $size={size}
      {...rest}
    >
      {children}
    </StyledIcon>
  )
}
