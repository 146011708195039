import { useMemo } from 'react'

import type { RasterImageAsset } from 'schemas/vixen-assets'
import { useSettings } from 'utils/settings'

export type ImageAssets = Array<
  | RasterImageAsset
  | {
      url: RasterImageAsset['url']
      width: RasterImageAsset['width'] | 'auto'
      height: RasterImageAsset['height'] | 'auto'
      language?: RasterImageAsset['language']
    }
>

/**
 * Turns assets into a srcset string.
 */
export function useAssetsSrcSet(assets: ImageAssets): string {
  const { settings } = useSettings()

  // Memoize the assets so they can't change before we unmount, otherwise
  // we get a crash in the lazy loader
  return useMemo(() => {
    if (assets.length === 0) return ''

    // filter sources by required language.
    // and write out the entry for the srcset.
    // size is handled by the srcset
    let sources = assets
      .flatMap(image => {
        // Only accept assets in our language, or no language
        if (image.language !== settings.defaultLanguage) return []
        // Return the source string for this asset
        if (image.width && typeof image.width === 'number') {
          return [`${image.url} ${image?.width}w`]
        }
        return [`${image.url}`]
      })
      .join(', ')

    if (sources.length === 0) {
      // Find any language-less assets as a fall-back
      sources += assets
        .flatMap(asset => {
          if (asset.language !== undefined) return []
          if (asset.width && typeof asset.width === 'number') {
            return [`${asset.url} ${asset?.width}w`]
          }
          return [`${asset.url}`]
        })
        .join(', ')
    }

    return sources
  }, [assets])
}
