/**
 * This file was copied from sdk-console 23.8
 */

/**
 * A registry for dependency injection of handlers.
 *
 * Registries are used to allow dependency injection by high-level packages
 * whose implementations need to be called by lower-level packages. The most
 * common example of this is for
 * [Extension Points](https://docs.artpro.net.au/apsys-vixen/main/concepts.html#extension-points)
 * within the Gazelle schemas.
 *
 * vixen-core defines an extension point `content`, which is used by low-level
 * packages such as vixen-assets, but whose implementations are defined in
 * higher level packages such as ocelot-content (or even custom modules).
 *
 * Registry entries are stored as string names, this is typically the
 * `typename` of a Gazelle entity, or similar.
 *
 * @template T the type stored by the registry, typically a function or an object
 * @category Registry
 * @see https://en.wikipedia.org/wiki/Dependency_injection
 */
export class Registry<T> {
  private registry: Record<string, T>

  private defaultValue: T | undefined

  /**
   * Construct a new registry.
   *
   * Registries are typically exported with a well-known name, so that other
   * parts of the code may use them to register additional implementations.
   *
   * @param defaultValue a value to return if a given key is unknown
   * @template T the type stored by the registry
   * @example
   *
   * For example to create a registry of icons for Gazelle types, that return
   * an `IconType` you might do something like this:
   *
   * ```ts
   * export const ICON_REGISTRY = new Registry<IconType>(null)
   * ```
   */
  constructor(defaultValue?: T) {
    this.registry = {}
    this.defaultValue = defaultValue
  }

  /**
   * Registry a new entry in this registry.
   *
   * Should be called from the top-level of a module, from somewhere that is
   * definitely imported, e.g. `routes.ts`.
   *
   * @param key key to retrieve the entry under, typically a Gazelle `typename` or similar.
   * @param value value to register
   * @template T type type stored by the registry
   */
  public register(key: string, value: T): void {
    if (key in this.registry) {
      console.error(`'${key}' already in registry`)
    }

    this.registry[key] = value
  }

  /**
   * Retrieve a registry entry by key.
   *
   * @param key a key to retrieve, typically a Gazelle `typename` or similar.
   * @returns whatever was registered with {@link register} or the default value (if set)
   * @template T the type stored by the registry
   */
  public get(key: string | null | undefined): T | undefined {
    if (!key) return undefined
    return this.registry[key] ?? this.defaultValue
  }

  /**
   * Retrieves a list of entries.
   *
   * @param keys a array of keys to retrieve
   * @returns a array of registry entries. This list may not be the same length
   *   as `keys` as it does not include undefined entries.
   *
   * If you want a direct mapping of registry entries to `keys` use {@link get}
   * with `map`.
   */
  public getlist(keys: string[] = []): T[] {
    return keys.flatMap(key =>
      key in this.registry ? [this.registry[key]] : [],
    )
  }

  /**
   * Returns all registered types. Useful for selecting a type.
   *
   * @returns all registered types
   * @template T type type stored by the registry
   */
  public all(): T[] {
    return Object.values(this.registry)
  }
}
