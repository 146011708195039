import type { RouteObject } from 'react-router-dom'
import { createMemoryRouter } from 'react-router-dom'

import App from './App'
import { IntroView } from './views/IntroView'
import { PlayerView } from './views/PlayerView'
import { NotFoundFullpage } from './views/errors/NotFound'

import { PlayerError } from 'components/PlayerError'
import { PlayerUI } from 'components/PlayerUI'

const routes: RouteObject[] = [
  {
    path: '/',
    element: <App />,
    children: [
      {
        path: '/',
        element: <IntroView />,
      },

      {
        path: '/player',
        element: <PlayerView />,
        children: [
          {
            path: 'active',
            index: true,
            element: <PlayerUI />,
          },
          {
            path: 'error',
            element: <PlayerError />,
          },
        ],
      },
      {
        path: '*',
        element: <NotFoundFullpage />,
      },
    ],
  },
]

export const router = createMemoryRouter(routes)
