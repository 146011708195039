/* eslint-disable */
import * as z from 'zod'

import {
  Struct,
  Entity,
  Meta,
  recase,
  GazelleRef,
  GazelleRefSchema,
} from '@apsys/gazelle'
import { BundleLoader, GazelleEntityFactory, ValueOf } from '@apsys/gazelle'
import { ReffedEntity as ReffableEntity, WithRef } from '@apsys/gazelle'

import * as VixenCore from './vixen-core'
import * as VixenRules from './vixen-rules'
import * as VixenAssets from './vixen-assets'
import * as VixenSpatial from './vixen-spatial'
import * as OcelotCatalog from './ocelot-catalog'
import * as OcelotContent from './ocelot-content'

export const meta: Meta = {
  name: 'Eileen Sync Config',
  moduleName: 'eileen-sync-config',
  version: '1.0.0',
  authors: ['Art Processors <developers@artprocessors.net>'],
}

// === enums ===
// === interfaces ===
// === structs ===
// === entities ===
export interface EsConfigSchema
  extends VixenCore.NamedSchema,
    OcelotContent.SlottedContentSchema {
  description: GazelleRefSchema
  'preview-image'?: GazelleRefSchema | null
  background: GazelleRefSchema
  logo: GazelleRefSchema
  'class-name'?: string | null
  'interactive-id': string
  'control-id': string
  'socket-endpoint': string
  'analytics-id': string
  'analytics-enabled': boolean
  'event-sync-audios': Array<EventSyncAudioSchema>
  'connection-type': string
}

export interface IEsConfig
  extends VixenCore.INamed,
    OcelotContent.ISlottedContent {
  description: GazelleRef<OcelotContent.HtmlBlock>
  previewImage?: GazelleRef<VixenAssets.Picture>
  background: GazelleRef<VixenAssets.Picture>
  logo: GazelleRef<VixenAssets.Picture>
  sponsors?: GazelleRef<VixenAssets.Picture>
  className?: string
  interactiveId: string
  controlId: string
  socketEndpoint: string
  analyticsId: string
  analyticsEnabled: boolean
  eventSyncAudios: Array<EventSyncAudio>
  connectionType: string
}

type EsConfigSchemaType = z.Schema<EsConfig, z.ZodTypeDef, EsConfigSchema>

export const EsConfigSchema = z
  .lazy(() =>
    z.object({
      ref: GazelleRefSchema,
      name: z.record(z.string()),
      description: GazelleRefSchema,
      'preview-image': GazelleRefSchema.nullish(),
      background: GazelleRefSchema,
      logo: GazelleRefSchema,
      sponsors: GazelleRefSchema.nullish(),
      content: z.array(OcelotContent.SlottedContent.ContentSlotSchema),
      'class-name': z.string().nullish(),
      'interactive-id': z.string(),
      'control-id': z.string(),
      'socket-endpoint': z.string(),
      'analytics-id': z.string(),
      'analytics-enabled': z.boolean(),
      'event-sync-audios': z.array(EventSyncAudioSchema),
      'connection-type': z.string(),
    }),
  )
  .transform(value => new EsConfig(recase(value))) satisfies EsConfigSchemaType

export class EsConfig extends Entity<EsConfigSchema> implements IEsConfig {
  static readonly typename = 'es-config'
  static readonly schema: EsConfigSchemaType = EsConfigSchema
  static readonly parse = EsConfigSchema.parse
  static readonly fields = [
    'ref',
    'name',
    'description',
    'preview-image',
    'background',
    'logo',
    'sponsors',
    'content',
    'class-name',
    'interactive-id',
    'control-id',
    'socket-endpoint',
    'analytics-id',
    'analytics-enabled',
    'event-sync-audios',
    'connection-type',
  ]

  readonly typename = 'es-config'

  ref!: GazelleRef<EsConfig>
  name!: Record<string, string>
  description!: GazelleRef<OcelotContent.HtmlBlock>
  previewImage?: GazelleRef<VixenAssets.Picture>
  background!: GazelleRef<VixenAssets.Picture>
  logo!: GazelleRef<VixenAssets.Picture>
  sponsors?: GazelleRef<VixenAssets.Picture>
  content!: Array<OcelotContent.SlottedContent.ContentSlot>
  className?: string
  interactiveId!: string
  controlId!: string
  socketEndpoint!: string
  analyticsId!: string
  analyticsEnabled!: boolean
  eventSyncAudios!: Array<EventSyncAudio>
  connectionType!: string

  constructor(attrs: IEsConfig & WithRef<EsConfig>) {
    super(attrs)
  }
}

export interface EventSyncAudioSchema {
  audio: GazelleRefSchema
  sequence: number
  'long-audio': boolean
  'event-id': string
}

export interface IEventSyncAudio {
  audio: GazelleRef<VixenAssets.Audio>
  sequence: number
  longAudio: boolean
  eventId: string
}

type EventSyncAudioSchemaType = z.Schema<
  EventSyncAudio,
  z.ZodTypeDef,
  EventSyncAudioSchema
>

export const EventSyncAudioSchema = z
  .lazy(() =>
    z.object({
      ref: GazelleRefSchema,
      'event-id': z.string(),
      audio: GazelleRefSchema,
      'long-audio': z.boolean(),
      sequence: z.number(),
    }),
  )
  .transform(
    value => new EventSyncAudio(recase(value)),
  ) satisfies EventSyncAudioSchemaType

export class EventSyncAudio
  extends Entity<EventSyncAudioSchema>
  implements IEventSyncAudio
{
  static readonly typename = 'event-sync-audio'
  static readonly schema: EventSyncAudioSchemaType = EventSyncAudioSchema
  static readonly parse = EventSyncAudioSchema.parse
  static readonly fields = [
    'ref',
    'sequence',
    'audio',
    'event-id',
    'long-audio',
  ]

  readonly typename = 'event-sync-audio'

  ref!: GazelleRef<EventSyncAudio>
  sequence!: number
  longAudio!: boolean
  eventId!: string
  audio!: GazelleRef<VixenAssets.Audio>

  constructor(attrs: IEventSyncAudio & WithRef<EventSyncAudio>) {
    super(attrs)
  }
}

export namespace EsConfig {}

export type INTERFACES = VixenCore.INTERFACES &
  VixenRules.INTERFACES &
  VixenAssets.INTERFACES &
  VixenSpatial.INTERFACES &
  OcelotCatalog.INTERFACES & {}

export const ENTITIES = {
  'es-config': EsConfig,
}
export type ENTITIES = {
  [K in keyof typeof ENTITIES]: InstanceType<(typeof ENTITIES)[K]>
}

export class Loader extends BundleLoader {
  static readonly ENTITIES = ENTITIES
  static readonly schemaVersion = '1.2.0'

  get<K extends keyof ENTITIES>(ref: {
    typename: K
    id: string
  }): ENTITIES[K] | undefined
  get<T extends ValueOf<ENTITIES> | ValueOf<INTERFACES> | Entity<any>>(
    ref: GazelleRef<T>,
  ): (T extends Entity<any> ? T : ReffableEntity<T> & T) | undefined
  get(ref: any) {
    return super.get(ref)
  }

  getAll<K extends keyof ENTITIES>(typename: K): Array<ENTITIES[K]>
  getAll<T extends ValueOf<ENTITIES>>(klass: GazelleEntityFactory<T>): Array<T>
  getAll(klass: any) {
    return super.getAll(klass)
  }

  getSingleton<K extends keyof ENTITIES>(typename: K): ENTITIES[K] | undefined
  getSingleton<T extends ValueOf<ENTITIES>>(
    klass: GazelleEntityFactory<T>,
  ): T | undefined
  getSingleton(klass: any) {
    return super.getSingleton(klass)
  }
}
