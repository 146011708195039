import type { Config } from './types'

export const config: Config = {
  audioConfig: {
    delayAllowancePercent: 0.25,
    delayCheckFrequencyMs: 5000,
    longAudioDelayCheckFrequencyMs: 250, // The lower the time the better the stitch between long audio files. Higher can cause a gap and/or a stutter or audio drag.
    fftSize: 2048,
    reloadAllowances: {
      timeMs: 5000,
      max: 5,
    },
    showVisualizer: true,
  },
  analytics: {
    gaId: '',
    enabled: false,
  },
}
