import * as z from 'zod'

import {
  ZApiOptions,
  ZBundleInfo,
  ZCommonProperties,
  ZEntityDetails,
  ZFullLocation,
} from './commonSchema'

/**
 * Named pages that are used in the event spec.
 * Defined here: https://artprocessors.atlassian.net/wiki/spaces/DAI/pages/2098823270/Empower+-+Event+Specifications+-+Visitor+Analytics
 */
export enum PageEventType {
  /** The first landing page */
  LANDING = 'Landing',
  /** The start listening button has been clicked and the user is listening */
  LISTENING = 'Listening',
  /** The sound has been turned off on the listening page */
  LISTENING_SOUND_OFF = 'Listening - Sound Off',
  /** Info page, by clicking the info button */
  INFO = 'Info',
  /** The transcript page */
  TRANSCRIPT = 'Transcript',
}

/**
 * page Events
 *
 * @see https://artprocessors.atlassian.net/wiki/spaces/DAI/pages/1998356528/Pladia+Visitor+Apps+-+Digital+Event+Spec+v0.1#Page-Views
 */
export const ZPageEvent = z.object({
  /** The name of the page. See named pages in the event spec details */
  name: z.string().min(1),

  /** The category of the page. See named pages in the event spec details */
  category: z.string().min(1),

  properties: ZCommonProperties.extend({
    /** Maps to the name set above */
    category: z.string().optional(),

    /** The url and domain of the previous page. Autoset if unset */
    referrer: z.string().url().optional(),

    /** The title of the page. Autoset if unset */
    title: z.string().optional(),

    /** The full url and domain. Autoset if unset */
    url: z.string().url().optional(),

    /** The target entity details */
    target: ZEntityDetails.optional(),

    /** The bundle details */
    bundle: ZBundleInfo,

    /** Custom object of anything for ED data. Must be sent, even empty */
    custom: z
      .object({
        connection_type: z.string().optional(),
      })
      .default({}),

    /** The selected locale of the app */
    locale: z.string().min(1),

    /** The location of the target, if known */
    target_location: ZFullLocation.optional(),
    /** The location of the device, if known */
    device_location: ZFullLocation.optional(),
    /** See the media played event for more information about these */
    contextual_entities: z.array(ZEntityDetails).default([]),
  }).strict(),

  /** API options, but anything extra in here ends up in the `context` object in
   * the Rudderstack event, so we append the full common data here, as well
   * as the anonymous ID, which should be the device ID
   */
  apiOptions: ZApiOptions,
})

/**
 * The page event type, which is a combination of the common data and the page
 * specific data.
 */
export type PageEvent = z.input<typeof ZPageEvent>
