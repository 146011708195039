import { css } from 'styled-components'

export type CSSClamp = {
  /**
   * Clamp the headline to a certain number of lines
   */
  $clamp?: 1 | 2 | 3 | 4
}

/**
 * Use this mixin on text elements that you want to clamp to a certain number of lines.
 */
export const cssClampMixin = ({ $clamp }: CSSClamp) => css`
  ${$clamp &&
  css`
    display: -webkit-box;
    -webkit-line-clamp: ${$clamp};
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  `}
`

/**
 * Use this to forcibly break and (maybe) hyphenate long words
 */
export const hyphenateMixin = () => css`
  /* FIXME: turn on hyphenation in languages where it's appropriate */
  /* hyphens: auto; */
  word-wrap: break-word;
  white-space: normal;
`
