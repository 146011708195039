import type { PropsWithChildren, ReactNode } from 'react'

import type { LinkProps } from 'react-router-dom'
import { Link } from 'react-router-dom'

import { BUTTON_ICON_SIZE } from './constants'
import { BaseIconButton } from './styles'
import type { BaseIconButtonProps } from './types'

import { IconSpinner } from 'components/icons'
import type { IconType } from 'components/icons'

type IconOrChildren =
  | { Icon: IconType; children?: never }
  | { Icon?: never; children: ReactNode }

export type IconButtonProps = PropsWithChildren<
  BaseIconButtonProps &
    IconOrChildren & {
      /**
       * The internal URL to link to. If this is set, the button will be rendered as a `Link`
       */
      to?: LinkProps['to']
      // /**
      //  * The icon to display in the button
      //  */
      // Icon: IconType
    }
>

/**
 * Icon button component
 */
export const IconButton = ({
  to,
  size = 'large',
  variant,
  appearance,
  disabled,
  isLoading,
  Icon: _icon,
  children,
  ...attrs
}: IconButtonProps): JSX.Element => {
  const Icon = isLoading ? IconSpinner : _icon

  return (
    <BaseIconButton
      as={to ? Link : 'button'}
      to={to}
      $size={size}
      $variant={variant}
      $appearance={appearance}
      disabled={disabled || isLoading}
      $shape="pill"
      {...attrs}
    >
      {children && children}
      {Icon && <Icon size={BUTTON_ICON_SIZE[size]} aria-hidden />}
    </BaseIconButton>
  )
}
