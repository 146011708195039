import { CONTENT_BLOCK_REGISTRY } from './registry'
import { LayoutWrapper } from './styles'

import type {
  ContentLayoutProps,
  ContentLayoutRegistration,
  HasContentFunc } from 'components/ContentLayout'
import {
  CONTENT_LAYOUT_REGISTRY,
} from 'components/ContentLayout'
import { useAppContext } from 'context/AppContext'
import { ContentStack } from 'schemas/ocelot-content'

export * from './registry'

const Layout = ({
  layout,
  ...props
}: ContentLayoutProps<ContentStack>): JSX.Element => {
  const { contentBundle } = useAppContext()

  return (
    <LayoutWrapper>
      {layout.blocks.map(blockRef => {
        const Block = CONTENT_BLOCK_REGISTRY.get(blockRef.typename)?.Block
        if (!Block) return null

        const block = contentBundle?.get(blockRef)

        if (!block) return null

        return <Block key={blockRef.id} block={block} {...props} />
      })}
    </LayoutWrapper>
  )
}

const hasContent: HasContentFunc<ContentStack> = ({
  contentBundle,
  layout,
  ...props
}) => {
  return layout.blocks.some(blockRef => {
    const hasBlockContent = CONTENT_BLOCK_REGISTRY.get(
      blockRef.typename,
    )?.hasContent
    if (!hasBlockContent) return false

    const block = contentBundle?.get(blockRef)
    if (!block) return false

    return hasBlockContent({ block, contentBundle, ...props })
  })
}

CONTENT_LAYOUT_REGISTRY.register(ContentStack.typename, {
  Layout,
  hasContent,
} satisfies ContentLayoutRegistration<ContentStack>)
