/* eslint-disable */
import * as z from 'zod'

import {
  Struct,
  Entity,
  Meta,
  recase,
  GazelleRef,
  GazelleRefSchema,
} from '@apsys/gazelle'
import { BundleLoader, GazelleEntityFactory, ValueOf } from '@apsys/gazelle'
import { ReffedEntity as ReffableEntity, WithRef } from '@apsys/gazelle'

import * as VixenCore from './vixen-core'
import * as VixenAssets from './vixen-assets'
import * as VixenSpatial from './vixen-spatial'
import * as OcelotContent from './ocelot-content'
import * as OcelotHibiki from './ocelot-hibiki'

export const meta: Meta = {
  name: 'Eileen Spatial',
  moduleName: 'eileen-spatial',
  version: '0.1.0',
  authors: ['Art Processors <developers@artprocessors.net>'],
}

// === enums ===
// === interfaces ===
// === structs ===
// === entities ===
export interface FeatureContentSchema
  extends VixenCore.StyledNamedSchema,
    OcelotContent.SlottedContentSchema {
  feature: VixenSpatial.FeatureRefSchema
  'preview-image'?: GazelleRefSchema | null
  'wall-id'?: string | null
  player?: GazelleRefSchema | null
  'class-name'?: string | null
}

export interface IFeatureContent
  extends VixenCore.IStyledNamed,
    OcelotContent.ISlottedContent {
  feature: VixenSpatial.FeatureRef
  previewImage?: GazelleRef<VixenAssets.Picture>
  wallId?: string
  player?: GazelleRef<OcelotHibiki.IHibikiPlayer>
  className?: string
}

type FeatureContentSchemaType = z.Schema<
  FeatureContent,
  z.ZodTypeDef,
  FeatureContentSchema
>

export const FeatureContentSchema = z
  .lazy(() =>
    z.object({
      ref: GazelleRefSchema,
      content: z.array(OcelotContent.SlottedContent.ContentSlotSchema),
      name: z.record(z.string()),
      'styled-name': z.record(z.string()),
      feature: VixenSpatial.FeatureRefSchema,
      'preview-image': GazelleRefSchema.nullish(),
      'wall-id': z.string().nullish(),
      player: GazelleRefSchema.nullish(),
      'class-name': z.string().nullish(),
    }),
  )
  .transform(
    value => new FeatureContent(recase(value)),
  ) satisfies FeatureContentSchemaType

export class FeatureContent
  extends Entity<FeatureContentSchema>
  implements IFeatureContent
{
  static readonly typename = 'feature-content'
  static readonly schema: FeatureContentSchemaType = FeatureContentSchema
  static readonly parse = FeatureContentSchema.parse
  static readonly fields = [
    'ref',
    'content',
    'name',
    'styled-name',
    'feature',
    'preview-image',
    'wall-id',
    'player',
    'class-name',
  ]

  readonly typename = 'feature-content'

  ref!: GazelleRef<FeatureContent>
  content!: Array<OcelotContent.SlottedContent.ContentSlot>
  name!: Record<string, string>
  styledName!: Record<string, string>
  feature!: VixenSpatial.FeatureRef
  previewImage?: GazelleRef<VixenAssets.Picture>
  wallId?: string
  player?: GazelleRef<OcelotHibiki.IHibikiPlayer>
  className?: string

  constructor(attrs: IFeatureContent & WithRef<FeatureContent>) {
    super(attrs)
  }
}

export type INTERFACES = VixenCore.INTERFACES &
  VixenAssets.INTERFACES &
  VixenSpatial.INTERFACES &
  OcelotContent.INTERFACES &
  OcelotHibiki.INTERFACES & {}

export const ENTITIES = {
  ...VixenCore.ENTITIES,
  ...VixenAssets.ENTITIES,
  ...VixenSpatial.ENTITIES,
  ...OcelotContent.ENTITIES,
  ...OcelotHibiki.ENTITIES,
  'feature-content': FeatureContent,
}
export type ENTITIES = {
  [K in keyof typeof ENTITIES]: InstanceType<(typeof ENTITIES)[K]>
}

export class Loader extends BundleLoader {
  static readonly ENTITIES = ENTITIES
  static readonly schemaVersion = '0.1.0'

  get<K extends keyof ENTITIES>(ref: {
    typename: K
    id: string
  }): ENTITIES[K] | undefined
  get<T extends ValueOf<ENTITIES> | ValueOf<INTERFACES> | Entity<any>>(
    ref: GazelleRef<T>,
  ): (T extends Entity<any> ? T : ReffableEntity<T> & T) | undefined
  get(ref: any) {
    return super.get(ref)
  }

  getAll<K extends keyof ENTITIES>(typename: K): Array<ENTITIES[K]>
  getAll<T extends ValueOf<ENTITIES>>(klass: GazelleEntityFactory<T>): Array<T>
  getAll(klass: any) {
    return super.getAll(klass)
  }

  getSingleton<K extends keyof ENTITIES>(typename: K): ENTITIES[K] | undefined
  getSingleton<T extends ValueOf<ENTITIES>>(
    klass: GazelleEntityFactory<T>,
  ): T | undefined
  getSingleton(klass: any) {
    return super.getSingleton(klass)
  }
}
