import type { HTMLProps } from 'react'
import { useCallback, useEffect, useRef } from 'react'

import { useTheme } from 'styled-components'

import { VisualizerContainer, VisualizerCanvas } from './styles'
import type { SpectrumLineChart, SpectrumRadial } from './types'
import { VISUALIZER_TYPES } from './types'

import { useSyncPlayerContext } from 'components/SyncPlayer/SyncPlayerContext'
import { useAnalyzer } from 'hooks/useAnalyzer'
import { useDomEventListener } from 'hooks/useDomEventListener'

const MAX_BAR_WIDTH = 20 // You can adjust the bar width here
const MAX_BAR_HEIGHT = 850
const MAX_NUM_BARS = 100

// You can adjust the min and max radius here
const MIN_RANGE = 10
const MAX_RANGE = 170

export const VISUALIZER_TYPE: VISUALIZER_TYPES = VISUALIZER_TYPES.RADIAL

export type VisualizerProps = HTMLProps<HTMLCanvasElement>

/**
 * A visualizer component that renders a canvas element and a reactive audio
 * spectrum
 */
export function Visualizer(props: VisualizerProps): JSX.Element | null {
  const canvasContextRef = useRef<CanvasRenderingContext2D | null>(null)
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const { muted } = useSyncPlayerContext()
  const analyzer = useAnalyzer()
  const barWidth = useRef(MAX_BAR_WIDTH)
  const mutedRef = useRef(muted)
  const { color } = useTheme()

  useEffect(() => {
    mutedRef.current = muted
  }, [muted])

  const onWindowResize = useCallback(() => {
    if (!canvasRef.current) {
      return
    }
    canvasRef.current.width = window.innerWidth
    canvasRef.current.height = window.innerHeight

    if (VISUALIZER_TYPE === VISUALIZER_TYPES.BAR) {
      const availableWidth = window.innerWidth

      // Calculate the number of bars and bar width
      let currentBars = Math.max(
        MAX_NUM_BARS,
        Math.floor(availableWidth / (MAX_BAR_WIDTH + 1)),
      )
      let currentBarWidth = (availableWidth - currentBars) / currentBars

      // Check if the bar width is greater than the maximum allowed width
      while (currentBarWidth > MAX_BAR_WIDTH) {
        currentBars += 1
        currentBarWidth = (availableWidth - currentBars) / currentBars
      }

      barWidth.current = currentBarWidth
    }
  }, [canvasRef])

  useDomEventListener('resize', onWindowResize, window)
  useDomEventListener('orientationchange', onWindowResize, window)

  useEffect(() => {
    if (!canvasRef.current) {
      return
    }

    canvasContextRef.current = canvasRef.current.getContext('2d')

    onWindowResize()
  }, [canvasRef])

  /**
   * Factory function that returns a function that renders bars for the
   * audio spectrum visualization
   */
  const spectrumBarFactory = (
    ctx: CanvasRenderingContext2D,
    canvas: HTMLCanvasElement,
    muted: boolean,
  ) => {
    return ({ numBars, barWidth, dataArray }: SpectrumLineChart) => {
      const barSpacing = 1

      ctx.fillStyle = color.onSurfaceHigh
      ctx.globalAlpha = 0.3
      ctx.clearRect(0, 0, canvas.width, canvas.height)

      for (let i = 0; i < numBars; i++) {
        const value = dataArray[i]
        const height = (value / MAX_BAR_HEIGHT) * canvas.height

        const x = i * (barWidth + barSpacing)
        const y = canvas.height - height * (muted ? 0.3 : 1)

        ctx.fillRect(x, y, barWidth, height * (muted ? 0.3 : 1))
      }
    }
  }

  // Utility function that is used to draw a ring on the canvas
  const ring =
    (
      canvas: HTMLCanvasElement,
      ctx: CanvasRenderingContext2D,
      muted: boolean,
    ) =>
    (radius: number, alpha: number, lineWidth: number) => {
      ctx.globalAlpha = alpha
      ctx.lineWidth = lineWidth

      ctx.beginPath()
      ctx.arc(
        canvas.width / 2,
        canvas.height / 2,
        radius * (muted ? 0.5 : 1),
        0,
        2 * Math.PI,
        false,
      )

      ctx.stroke()
      ctx.closePath()
    }

  // Utility that generates ring radius data from the audio wave  data
  const generateAudioWaveRingData = (data: Uint8Array) => {
    let maxRadius = 0
    let minRadius = MAX_RANGE
    for (let i = 0; i <= 360; i++) {
      const frequency = data[i]
      const radius =
        (frequency * (MAX_RANGE - MIN_RANGE)) / (255 - 0) + MIN_RANGE
      if (radius > maxRadius) {
        maxRadius = radius
      }
      if (radius < minRadius) {
        minRadius = radius
      }
    }

    return {
      maxRadius,
      minRadius,
      midRadius: maxRadius - (maxRadius - minRadius) / 2,
      fillRadius: minRadius - (minRadius - MIN_RANGE) / 2,
    }
  }

  /**
   * Factory function that returns a function that renders rings for the
   * audio spectrum visualization
   */
  const spectrumRadialFactory = (
    ctx: CanvasRenderingContext2D,
    canvas: HTMLCanvasElement,
    muted: boolean,
  ) => {
    return ({ dataArray }: SpectrumRadial) => {
      const { maxRadius, minRadius, midRadius, fillRadius } =
        generateAudioWaveRingData(dataArray)

      const drawRing = ring(canvas, ctx, muted)

      ctx.strokeStyle = color.onSurfaceHigh
      ctx.clearRect(0, 0, canvas.width, canvas.height)

      drawRing(maxRadius, 0.05, 20)
      drawRing(minRadius, 0.1, 30)
      drawRing(midRadius, 0.15, 40)
      drawRing(fillRadius, 0.2, 60)
    }
  }

  /**
   * Visualize the sound on the canvas
   */
  const visualizeSound = () => {
    const { current: canvasCtx } = canvasContextRef
    if (!canvasRef.current || !canvasCtx || !analyzer) {
      return
    }

    const bufferLength = analyzer.frequencyBinCount
    const dataArray = new Uint8Array(bufferLength)

    const animate = () => {
      if (!canvasRef.current || !canvasCtx) return

      analyzer.getByteFrequencyData(dataArray)

      if (VISUALIZER_TYPE == VISUALIZER_TYPES.BAR) {
        const draw = spectrumBarFactory(
          canvasCtx,
          canvasRef.current,
          mutedRef.current,
        )

        const barDataArray = dataArray.slice(0, MAX_NUM_BARS)

        draw({
          numBars: MAX_NUM_BARS,
          barWidth: barWidth.current,
          dataArray: barDataArray,
        })
      }
      if (VISUALIZER_TYPE == VISUALIZER_TYPES.RADIAL) {
        const draw = spectrumRadialFactory(
          canvasCtx,
          canvasRef.current,
          mutedRef.current,
        )

        draw({
          dataArray,
        })
      }

      requestAnimationFrame(animate)
    }

    animate()
  }

  useEffect(() => {
    if (!analyzer) {
      return
    }
    visualizeSound()
  }, [analyzer])

  return (
    <VisualizerContainer>
      <VisualizerCanvas ref={canvasRef} type={VISUALIZER_TYPE} />
    </VisualizerContainer>
  )
}
