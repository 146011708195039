import styled, { css } from 'styled-components'

import type {
  BaseActionButtonProps,
  BaseButtonProps,
  BaseIconButtonProps,
} from './types'

import {
  borderRadius,
  color,
  fontSize,
  fontWeight,
  padding,
  pxToRem,
  space,
} from 'theme/utils'
import type { MakeStyledTransientProps } from 'types/utils'

type StyledButtonProps = MakeStyledTransientProps<
  Pick<BaseButtonProps, 'size' | 'variant' | 'appearance' | 'shape'>
>

export const BaseButton = styled.button<StyledButtonProps>`
  --button-on-surface-color: ${color.onSurface('inverseOnSurface')};
  --button-surface-color: ${color.surface('inverseSurface')};
  --button-hover-on-surface-color: ${color.surface('surface0')};

  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: ${space(2)};
  padding: ${padding(4)};

  font-weight: ${fontWeight('bold')};
  line-height: 1;
  letter-spacing: 0.12rem;

  // Prevents a tap highlight on mobile
  -webkit-tap-highlight-color: transparent;

  transition: background 0.2s, color 0.2s, border-color 0.2s;

  // Do not apply hover effect on mobile
  @media (hover: hover) and (pointer: fine) {
    &:hover:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: inherit;
      opacity: 0.12;
      background-color: var(--button-hover-on-surface-color);
      pointer-events: none;
    }
  }

  ${({ $size = 'large' }) => {
    switch ($size) {
      case 'small':
        return css`
          height: ${pxToRem(32)};
          padding: ${padding(2)};
          font-size: ${fontSize('small')};
        `
      case 'medium':
        return css`
          height: ${pxToRem(44)};
          padding: ${padding(3, 4)};
          font-size: ${fontSize('medium')};
        `
      case 'large':
        return css`
          height: ${pxToRem(56)};
          padding: ${padding(4)};
          font-size: ${fontSize('base')};
        `
    }
  }}

  ${({ $shape = 'rounded' }) => {
    switch ($shape) {
      case 'rounded':
        return css`
          border-radius: ${borderRadius('medium')};
        `
      case 'pill':
        return css`
          border-radius: ${borderRadius('full')};
        `
    }
  }}

  ${({ $variant = 'neutral' }) => {
    switch ($variant) {
      case 'neutral':
        return css`
          --button-on-surface-color: ${color.onSurface('inverseOnSurface')};
          --button-surface-color: ${color.surface('inverseSurface')};
          --button-hover-on-surface-color: ${color.surface('surface0')};
        `
      case 'primary':
        return css`
          --button-on-surface-color: ${color.onSurface('onPrimary')};
          --button-surface-color: ${color.accent('primary')};
          --button-hover-on-surface-color: ${color.surface('surface0')};
        `
      case 'inversed':
        return css`
          --button-on-surface-color: ${color.onSurface('onSurfaceHigh')};
          --button-surface-color: ${color.surface(
            'inverseTransparentSurface',
          )};
          --button-hover-on-surface-color: ${color.onSurface('onSurfaceHigh')};
        `
      case 'error':
        return css`
          --button-on-surface-color: ${color.onSurface('onError')};
          --button-surface-color: ${color.accent('error')};
          --button-hover-on-surface-color: ${color.surface('surface0')};
        `
    }
  }}

  ${({ $appearance = 'solid' }) => {
    switch ($appearance) {
      case 'solid':
        return css`
          background-color: var(--button-surface-color);
          color: var(--button-on-surface-color);
          border: none;
        `
      case 'outline':
        return css`
          background-color: transparent;
          color: var(--button-surface-color);
          border: 2px solid var(--button-surface-color);
        `
      case 'ghost':
        return css`
          color: var(--button-surface-color);
          background-color: transparent;
        `
      case 'subtle':
        return css`
          color: ${color.onSurface('onSurfaceHigh')};
          background-color: ${color.surface('surface1')};
        `
      case 'overlay':
        return css`
          color: ${color.onSurface('onSurfaceHigh')};
          background-color: ${color.surface('transparentSurface0')};
        `
    }
  }}

  // Special Hover States
  // These variant & appearance combinations require specific treatments
  ${({ $variant, $appearance }) => {
    switch ($appearance) {
      case 'outline':
      case 'ghost':
        switch ($variant) {
          case 'neutral':
            return css`
              --button-hover-on-surface-color: ${color.onSurface(
                'onSurfaceHigh',
              )};
            `
          case 'primary':
            return css`
              --button-hover-on-surface-color: ${color.accent('primary')};
            `
          case 'error':
            return css`
              --button-hover-on-surface-color: ${color.accent('error')};
            `
          default:
            return css``
        }
      case 'subtle':
        return css`
          --button-hover-on-surface-color: ${color.onSurface('onSurfaceHigh')};
        `
      case 'overlay':
        return css`
          --button-hover-on-surface-color: ${color.onSurface('onSurfaceHigh')};
        `
    }
  }}

  ${({ disabled }) => css`
    ${disabled &&
    css`
      opacity: 0.5;
      cursor: default;

      &:hover:before {
        background-color: transparent;
      }
    `}
  `}
`

type StyledIconButtonProps = MakeStyledTransientProps<
  Pick<BaseIconButtonProps, 'size' | 'variant' | 'appearance'>
>

export const BaseIconButton = styled(BaseButton)<StyledIconButtonProps>`
  ${({ $size = 'large' }) => {
    switch ($size) {
      case 'small':
        return css`
          width: ${pxToRem(32)};
        `
      case 'medium':
        return css`
          width: ${pxToRem(44)};
        `
      case 'large':
        return css`
          width: ${pxToRem(56)};
        `
    }
  }}
`

type StyledActionButtonProps = MakeStyledTransientProps<
  Pick<BaseActionButtonProps, 'variant' | 'appearance'>
>

export const BaseActionButton = styled(BaseButton)<StyledActionButtonProps>`
  flex-direction: column;
  height: ${pxToRem(69)};
  width: ${pxToRem(69)};
  padding: ${padding(2, 4)};
`

export const BaseActionButtonLabel = styled.span`
  font-size: ${fontSize('medium')};
`
