export type SpectrumLineChart = {
  numBars: number
  barWidth: number
  dataArray: Uint8Array
}

export type SpectrumRadial = {
  dataArray: Uint8Array
}

export enum VISUALIZER_TYPES {
  RADIAL = 'radial',
  BAR = 'bar',
}
