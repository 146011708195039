// GENERATED BY process-svg DO NOT EDIT
import { GeneratedIcon } from './Icon'
import type { GeneratedIconProps } from './Icon/types'
// @ts-ignore
import Svg from './svg/ic-volume-2-outline.svg?react'

export const IconVolume2Outline = (props: GeneratedIconProps): JSX.Element => (
  <GeneratedIcon {...props}>
    <Svg preserveAspectRatio="xMidYMid meet" />
  </GeneratedIcon>
)

IconVolume2Outline.displayName = 'IconVolume2Outline'
