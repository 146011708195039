import type { FC } from 'react'

import type { Entity } from '@apsys/gazelle'

import type { ContentLayoutEntity } from 'components/ContentLayout'
import type { Loader as ContentLoader } from 'schemas/eileen-service'
import type { IContentBlock } from 'schemas/ocelot-content'
import { Registry } from 'utils/registry'
import type { ConfigOptions } from 'utils/settings'

/**
 * Any content block entitiy (implements `content-block`)
 */
export type ContentBlockEntity = Entity<any> & IContentBlock

export type ContentBlockProps<T extends ContentLayoutEntity> = {
  /** The block being rendered */
  block: T
  /** A list of allowed HTML tags (for html blocks) */
  allowedHtmlTags?: readonly string[]
}

export type HasContentFunc<T extends ContentLayoutEntity> = (props: {
  /** The contentBundle (for resolving refs) */
  contentBundle: ContentLoader
  /** The settings block */
  settings: ConfigOptions
  /** The layout being rendered */
  block: T
}) => boolean

/**
 * A registration into {@link CONTENT_BLOCK_REGISTRY} for a single content
 * block.
 */
export interface ContentBlockRegistration<T extends ContentBlockEntity> {
  Block: FC<ContentBlockProps<T>>

  /** Returns true if the block has content */
  hasContent: HasContentFunc<T>
}

/**
 * A registry of content block types. These are types that can be used for
 * rendering a {@link ContentBlockEntity}, which are used by the
 * `ContentStack` entity.
 *
 * Content blocks registered into this interface should live in
 * `registrations/content-block/`. Known blocks are `html-block`,
 * `carousel-block` and `external-cta-button-block`.
 *
 * Instead of using this registry directly, you probably want
 * {@link ContentLayout}, which will render a named content slot for you.
 */
export const CONTENT_BLOCK_REGISTRY = new Registry<
  ContentBlockRegistration<any>
>()
