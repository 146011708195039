import { useMemo } from 'react'

import type {
  ImageWithFallbackProps } from 'components/ImageWithFallback'
import {
  ImageWithFallback,
} from 'components/ImageWithFallback'
import type { ImageAssets } from 'hooks/useAssetsSrcSet'
import { useAssetsSrcSet } from 'hooks/useAssetsSrcSet'

type Props = Omit<ImageWithFallbackProps, 'src' | 'srcSet'> & {
  assets: ImageAssets
}

/**
 * Same as {@link ImageWithFallback}, but it receives assets instead of src.
 */
export function Image({
  assets,
  className,
  width,
  height,
  ...imageWithFallbackProps
}: Props): JSX.Element | null {
  const sources = useAssetsSrcSet(assets)

  const finalWidth = useMemo(() => {
    if (width !== 'auto') {
      return width
    }

    const [firstAsset] = assets

    if (firstAsset.width !== 'auto') {
      return firstAsset.width
    }

    return undefined
  }, [assets, width])

  const finalHeight = useMemo(() => {
    if (height !== 'auto') {
      return height
    }

    const [firstAsset] = assets

    if (firstAsset.height !== 'auto') {
      return firstAsset.height
    }

    return undefined
  }, [assets, height])

  if (assets.length === 0) return null

  const firstAsset = assets[0]

  return (
    <ImageWithFallback
      {...imageWithFallbackProps}
      className={className}
      style={{
        width: finalWidth ?? '100%',
        height: finalHeight ?? 'auto',
      }}
      /**
       * This shouldn't be an issue here, but the order of the attributes
       * matters for the `srcSet` to work correctly.
       *
       * Go into {@link ImageWithFallback} for more details.
       */
      srcSet={sources}
      src={firstAsset.url}
      width={finalWidth}
      height={finalHeight}
    />
  )
}
