import { useCallback, useState } from 'react'

/**
 * Utility hook to access properties from things that don't generate state
 * updates, like localstorage.
 *
 * @param getter a getter function, will be used once only to initialise our
 * internal state.
 * @param setter a setter function, to update the upstream state when our
 * internal state is updated.
 * @returns a state vector and a setter, like `useState`.
 */
export function useLocalStorageState<T>(
  getter: () => T,
  setter: (value: T) => void,
): [T, (value: T) => void] {
  const [value, setValue] = useState<T>(getter)
  const combinedSetter = useCallback(
    (newValue: T) => {
      setter(newValue)
      setValue(newValue)
    },
    [setter],
  )

  return [value, combinedSetter]
}
