import { useState } from 'react'

import styled from 'styled-components'

import { IconButton } from './components/Button/IconButton'
import { IconXCloseOutline } from './components/icons'
import { useSyncServerContext } from './context/SyncServerContext'
import { debugAllowed } from './helpers/debug'
import { useEffectOnce } from './hooks/useEffectOnce'

import { useConfigProperties } from 'hooks/useEsConfig'
import { plane, space, color, fontSize } from 'theme/utils'

const AudioInfoPanel = styled.div`
  width: 100%;
  background: ${color.accent('primary')};
  color: ${color.surface('inverseSurface')};
  font-size: ${fontSize('base')};
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: left;
  z-index: ${plane('debug')};

  button {
    position: absolute;
    top: 0;
    right: 0;
  }

  strong {
    padding: ${space(1)} ${space(3)};
    display: block;
    span {
      color: white;
      display: block;
    }
  }
  > div {
    border-top: 1px solid ${color.surface('surface0')};
    height: 150px;
    overflow-y: scroll;
    padding: 0;
    span {
      padding: ${space(1)} ${space(3)};
      display: block;
      &:nth-child(even) {
        background: ${color.accent('primary')};
      }
    }
  }
`

export const AudioInfo = () => {
  const [show, setShow] = useState<boolean>(false)
  const [logs, setLogs] = useState<React.ReactNode[]>([])

  const { track } = useSyncServerContext()
  const { getInteractiveID } = useConfigProperties()
  const vxTitle = track?.title || 'No Track'

  const addLog = (...message: any) => {
    // @ts-ignore
    console.olog(...message)
    let _message = message
    if (typeof _message === 'object') {
      _message = JSON.stringify(_message)
    }
    setLogs(prev => [<span key={prev.length + 1}>{_message}</span>, ...prev])
  }

  useEffectOnce(() => {
    if (debugAllowed()) {
      if (typeof console !== 'undefined')
        if (typeof console.log !== 'undefined') {
          // @ts-ignore
          console.olog = console.log
        } else {
          // @ts-ignore
          console.olog = () => {
            /** */
          }
        }

      console.log = addLog
      console.error = console.debug = console.info = console.log
    }

    document.onkeyup = (e: KeyboardEvent) => {
      if (e.ctrlKey && e.key === 'd') {
        setShow(prev => !prev)
      }
    }

    document.ontouchstart = (e: TouchEvent) => {
      if (e.touches.length === 2) {
        setShow(prev => !prev)
      }
    }
  })

  const onClose = () => {
    setShow(false)
  }

  return (
    <>
      {show && (
        <AudioInfoPanel>
          <IconButton onClick={onClose} Icon={IconXCloseOutline} />
          <strong>
            Track Name: <span>{vxTitle}</span>
          </strong>
          <strong>
            Interactive ID: <span>{getInteractiveID()}</span>
          </strong>
          <div>{logs}</div>
        </AudioInfoPanel>
      )}
    </>
  )
}
