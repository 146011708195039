export const debugAllowed = (): boolean => {
  const urlParams = new URLSearchParams(location.search)
  if (urlParams.has('debug-allowed')) {
    const debugAllowed = urlParams.get('debug-allowed')
    return debugAllowed === 'true'
  }
  return false
}

export const disableVisualizer = (): boolean => {
  const urlParams = new URLSearchParams(location.search)
  if (urlParams.has('disable-visualizer')) {
    const visualizer = urlParams.get('disable-visualizer')
    return visualizer === 'true'
  }
  return false
}
