import type {
  ChangeObject,
  StateObject,
  SyncObject } from 'types/sync'
import {
  isStateObject,
} from 'types/sync'

export function getSyncObject(
  interactiveId: string,
  obj: ChangeObject | StateObject,
) {
  const found = isStateObject(obj)
    ? obj.state.find(s => s.interactive_id === interactiveId)
    : obj
  if (!found || found.interactive_id !== interactiveId) {
    return
  }

  return found
}

export function getMessageFromObject(syncObject?: SyncObject): string[] {
  try {
    return JSON.parse(syncObject?.content_id || '')
  } catch {
    return []
  }
}

// export function getContent(obj: SyncObject | ChangeObject): {
//   message: string[]
//   audio_id: string
// } {
//   try {
//     return JSON.parse(obj.content_id)
//   } catch {
//     return {
//       message: [],
//       audio_id: '',
//     }
//   }
// }
