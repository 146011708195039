import { HtmlContent } from 'components/HtmlContent'
import type {
  ContentBlockProps,
  ContentBlockRegistration,
  HasContentFunc } from 'registrations/content-layout/content-stack'
import {
  CONTENT_BLOCK_REGISTRY,
} from 'registrations/content-layout/content-stack'
import { HtmlBlock } from 'schemas/ocelot-content'
import { useSettings } from 'utils/settings'

/**
 * html-block **block** (part of `content-stack`), this should be looked up via
 * {@link CONTENT_BLOCK_REGISTRY}
 *
 * If you're looking for the layout of the same name, see
 * `registrations/content-layout/html-block/`
 */
const Block = ({
  block,
  allowedHtmlTags,
}: ContentBlockProps<HtmlBlock>): JSX.Element => {
  const { getTranslation } = useSettings()

  return (
    <HtmlContent
      value={getTranslation(block.html)}
      allowedHtmlTags={allowedHtmlTags}
    />
  )
}

const hasContent: HasContentFunc<HtmlBlock> = ({
  block,
  settings: { defaultLanguage },
}) => {
  // The default language is required, so we only need to check the default
  // language
  return !!block.html[defaultLanguage]
}

CONTENT_BLOCK_REGISTRY.register(HtmlBlock.typename, {
  Block,
  hasContent,
} satisfies ContentBlockRegistration<HtmlBlock>)
