import { keyframes } from 'styled-components'

export const shrinkAndFade = keyframes`
  0% {
    opacity: 0;
    transform: scale(1);
  }
  90% {
    opacity: 0.9;
    transform: scale(0.5);
  }
  100% {
    opacity: 1;
    transform: scale(0.5);
  }
`

export const scaleAndFadeCycle = keyframes`
  0% {
    transform: scale(0.8);
    opacity: 0;
  }
  
  5% {
    opacity: 1;
    transform: scale(1);
  }
  
  45% {
    opacity: 1;
    transform: scale(1);
  }
  
  50% {
    opacity: 0;
    transform: scale(0.8);
  }
  
  100% {
    opacity: 0;
    transform: scale(0.8);
  }
`

export const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

export const easeOutQuart = 'cubic-bezier(0.25, 1, 0.5, 1)'
