import type { PropsWithChildren } from 'react'
import { Children } from 'react'

import classNames from 'classnames'
import styled, { css } from 'styled-components'

import { easeOutQuart } from 'styles/animations'
import type { MakeStyledTransientProps } from 'types/utils'

type AnimatedChildrenContainerProps = PropsWithChildren<{
  show: boolean
  className?: string
}>

const animateIn = css<MakeStyledTransientProps<{ childrenCount: number }>>`
  > * {
    transform-origin: 50% 50%;
    opacity: 0;
    transition: transform 1000ms ${easeOutQuart},
      opacity 1200ms ${easeOutQuart};
    transform: translateY(30px) scale(0.9);
  }

  ${({ $childrenCount }) =>
    new Array($childrenCount).fill(null).map(
      (_, index) => css`
        > *:nth-child(${index + 1}) {
          transition-delay: ${index * 150 + 'ms'};
        }
      `,
    )}

  &.show > * {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
`

export const Container = styled.section<
  MakeStyledTransientProps<{ childrenCount: number }>
>`
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;

  ${animateIn}
`

export function AnimatedChildrenContainer({
  show,
  children,
  className,
}: AnimatedChildrenContainerProps): JSX.Element {
  const childrenCount = Children.count(children)
  return (
    <Container
      className={classNames(className, { show })}
      $childrenCount={childrenCount}
    >
      {children}
    </Container>
  )
}
