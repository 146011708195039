import styled from 'styled-components'

import { useConfigProperties } from 'hooks/useEsConfig'
import { pxToRem } from 'theme/utils'

export const LogoElement = styled.img`
  width: ${pxToRem(220)};
  height: auto;
  max-height: ${pxToRem(136)}; // golden ratio
  object-fit: contain;
  object-position: top left;
  margin: 0 auto;
`

export const Logo = () => {
  const { logo } = useConfigProperties()
  return <LogoElement role="heading" src={logo[0].url} alt="Title" />
}
