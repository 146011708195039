import { useMemo } from 'react'

import type {
  OneOrMoreSlotNames,
  SlottedContentEntity,
} from './getContentLayout'
import { getContentLayout } from './getContentLayout'
import type { ContentLayoutEntity } from './registry'
import { CONTENT_LAYOUT_REGISTRY } from './registry'

import { useAppContext } from 'context/AppContext'
import type { EsConfig } from 'schemas/eileen-sync-config'
import { useSettings } from 'utils/settings'

export {
  getContentLayout,
  type SlottedContentEntity,
} from './getContentLayout'
export * from './registry'

type Props = (
  | // Either pass pwaObject + slotName
  {
      /** The object to look up a content slot for */
      pwaObject: EsConfig
      /**
       * A slot name in the content model, i.e. `summary` or `subtitle`.
       *
       * If multiple slot names are provided. The first slot with content is
       * rendered.
       */
      slotName: OneOrMoreSlotNames
    }
  | {
      /** The object to look up a content slot for */
      entity: SlottedContentEntity
      /**
       * A slot name in the content model, i.e. `summary` or `subtitle`.
       *
       * If multiple slot names are provided. The first slot with content is
       * rendered.
       */
      slotName: OneOrMoreSlotNames
    }
  // or a content layout you already have
  | {
      /**
       * A content layout to render
       *
       * If you want to look this up by slot name, use the `pwaObject` API
       * instead
       */
      layout: ContentLayoutEntity
    }
) & {
  /**
   * A list of allowed HTML tags (only meaningful for layouts that include
   * HTML)
   */
  allowedHtmlTags?: readonly string[]
}

/**
 * Render a content layout. You can do this either by supplying a content
 * layout OR by slot name.
 *
 * The component uses pluggable renderers to handle the rendering of many
 * different layout types. These are stored in the registry of
 * content layout renderers ({@link CONTENT_LAYOUT_REGISTRY}). Which can
 * be found in `registrations/content-layout/`.
 *
 * If you're looking for the registration of content blocks for the
 * `content-stack` type, these are stored in {@link CONTENT_BLOCK_REGISTRY},
 * found in `registrations/content-block/`.
 *
 * If you just want to render a single Gazelle `html` field (e.g.
 * `styled-name`), use {@link HtmlContent}.
 */
export const ContentLayout = ({
  allowedHtmlTags,
  ...props
}: Props): JSX.Element | null => {
  const { settings } = useSettings()
  const { contentBundle } = useAppContext()

  /** Content entity (looked up by `slotName` from `pwaObject` */
  const layout = useMemo(() => {
    if ('pwaObject' in props) {
      const { pwaObject, slotName } = props
      return getContentLayout({
        entity: pwaObject,
        slotName,
        settings,
        contentBundle,
      })
    }

    if ('entity' in props) {
      const { entity, slotName } = props

      return getContentLayout({ entity, slotName, settings, contentBundle })
    }

    return props.layout
  }, [contentBundle, props, settings])

  if (!layout) return null

  /** The component used to render `layout` */
  const Layout = CONTENT_LAYOUT_REGISTRY.get(layout.typename)?.Layout

  // This shouldn't be possible due to the check in `findSlottedContent`
  if (!Layout) return null

  return <Layout layout={layout} allowedHtmlTags={allowedHtmlTags} />
}
