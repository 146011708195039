import type { PropsWithChildren } from 'react'

import { Helmet } from 'react-helmet-async'
import { ThemeProvider } from 'styled-components'

import { Loader } from 'Loader'
import { Background } from 'components/Background'
import { Logo } from 'components/Logo'
import { SyncPlayerContextProvider } from 'components/SyncPlayer/SyncPlayerContext'
import { SyncServerContextProvider } from 'context/SyncServerContext'
import { useEsConfig } from 'hooks/useEsConfig'
import { useStylesheet } from 'hooks/useStylesheet'
import { AppContainer, AppFrame, AppViewport } from 'layouts/styles'
import { GlobalStyle } from 'theme/global'

/**
 * The root layout for the app. This component should only be used once.
 */
export const Layout = ({ children }: PropsWithChildren): JSX.Element => {
  const esConfig = useEsConfig()
  const { getThemeForEntity } = useStylesheet()
  const theme = getThemeForEntity(esConfig)
  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <meta name="theme-color" content={theme.color.surface2} />
        <meta name="color-scheme" content={theme.mode} />
      </Helmet>

      <GlobalStyle theme={theme} />
      <Loader>
        <AppFrame>
          <Background animated />
          <AppViewport>
            <SyncServerContextProvider>
              <AppContainer>
                <Logo />
                <SyncPlayerContextProvider>
                  {children}
                </SyncPlayerContextProvider>
              </AppContainer>
            </SyncServerContextProvider>
          </AppViewport>
        </AppFrame>
      </Loader>
    </ThemeProvider>
  )
}
