import { useEffect, useRef } from 'react'

// THIS SHOULD NOT BE USED!
// eslint-disable-next-line @typescript-eslint/no-invalid-void-type
export const useEffectOnce = (effect: () => void | (() => void)) => {
  // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
  const destroyFunc = useRef<void | (() => void)>()
  const calledOnce = useRef(false)
  const renderAfterCalled = useRef(false)

  if (calledOnce.current) {
    renderAfterCalled.current = true
  }

  useEffect(() => {
    if (calledOnce.current) {
      return
    }

    calledOnce.current = true
    destroyFunc.current = effect()

    return () => {
      if (!renderAfterCalled.current) {
        return
      }

      if (destroyFunc.current) {
        destroyFunc.current()
      }
    }
  }, [])
}
