import { WEBSOCKET_CONFIG } from './constants'
import type { ChangeObject, StateObject, SystemObject } from './types/sync'

class OffsetSampler {
  #samples: Array<number>

  constructor() {
    this.#samples = []
  }

  getSize = () => {
    return this.#samples.length
  }

  median = (responseOffset: number) => {
    if (this.#samples.length === 0) {
      return responseOffset
    }
    const sorted = this.#samples.slice().sort()
    if (sorted.length % 2 === 0) {
      const left = sorted[sorted.length / 2 - 1]
      const right = sorted[sorted.length / 2]
      return (left + right) / 2
    } else {
      return sorted[Math.floor(sorted.length / 2)]
    }
  }

  add = (sample: number) => {
    this.#samples[this.#samples.length] = sample
  }

  reset = () => {
    this.#samples = []
  }
}

class Message {
  type: 'Unknown' | 'sync'

  constructor() {
    this.type = 'Unknown'
  }
}

class SyncRequestMessage extends Message {
  client_time: number

  constructor() {
    super()
    this.type = 'sync'
    this.client_time = Date.now()
  }
}

class SyncResponseMessage extends Message {
  client_time: number

  server_time: number

  constructor() {
    super()
    this.type = 'sync'
    this.client_time = Date.now()
    this.server_time = Date.now()
  }
}

export default class WebsocketService {
  public static instantiate(
    connectionString: string,
    readyHandler: () => void,
    stateHandler: (data: StateObject) => void,
    changeHandler: (data: ChangeObject) => void,
    systemHandler: (data: SystemObject) => void,
  ) {
    return new WebsocketService(
      connectionString,
      readyHandler,
      stateHandler,
      changeHandler,
      systemHandler,
      new OffsetSampler(),
    )
  }

  private client: WebSocket | null = null

  private connecting = false

  private reconnectTimeout = 5000

  private reconnectTimer: ReturnType<typeof setTimeout> | null = null

  private clockSynced = false

  private clockOffset = 0

  private updatingClock = false

  constructor(
    private connectionString: string,
    private readyHandler: () => void,
    private stateHandler: (data: StateObject) => void,
    private changeHandler: (data: ChangeObject) => void,
    private systemHandler: (data: SystemObject) => void,
    private offsetSampler: OffsetSampler,
  ) {}

  private sendClockPing = () => {
    const request = new SyncRequestMessage()
    this.send(JSON.stringify(request))
  }

  private updateClock = () => {
    this.updatingClock = true
    this.offsetSampler.reset()

    const updateTimer = window.setInterval(() => {
      if (!this.updatingClock) {
        clearInterval(updateTimer)
      } else {
        this.sendClockPing()
      }
    }, WEBSOCKET_CONFIG.DELAY_BETWEEN_CLOCK_REQUESTS_MS)
  }

  private calculateServerTimeOffset(response: SyncResponseMessage) {
    const now = Date.now()
    const roundTripTime = now - response.client_time
    // check the round trip time is OK
    if (
      !(
        roundTripTime >= 0 &&
        roundTripTime <= WEBSOCKET_CONFIG.ACCEPTABLE_ROUNDTRIP_MS
      )
    ) {
      console.log('*** AP Sync Client: dropping response, unacceptable RTT')
      return WEBSOCKET_CONFIG.UNACCEPTABLE_RESPONSE
    }
    const offset =
      response.server_time - roundTripTime / 2 - response.client_time
    // console.log(`calculating offset, clientTime: ${response.client_time}, serverTime: ${response.server_time}, now: ${now}`);
    // console.log(`round trip time: ${roundTripTime}, offset: ${offset}`);
    return offset
  }

  private syncHandler(parsedObject: ChangeObject) {
    // this is a clock sync response, so handle offset calculation
    const response = Object.assign(new SyncResponseMessage(), parsedObject)
    const responseOffset = this.calculateServerTimeOffset(response)
    if (responseOffset === WEBSOCKET_CONFIG.UNACCEPTABLE_RESPONSE) return

    this.offsetSampler.add(responseOffset)
    const medianOffset = this.offsetSampler.median(responseOffset)
    // console.log(`responseOffset ${responseOffset} - medianOffset ${medianOffset} = ${responseOffset - medianOffset}`);

    if (
      (this.offsetSampler.getSize() >= WEBSOCKET_CONFIG.MIN_SAMPLES_NUMBER &&
        Math.abs(responseOffset - medianOffset) <=
          WEBSOCKET_CONFIG.SETTLING_THRESHOLD) ||
      this.offsetSampler.getSize() >= WEBSOCKET_CONFIG.MAX_SAMPLES_NUMBER
    ) {
      const hasSynced = this.clockSynced
      this.clockOffset = medianOffset
      if (!hasSynced) {
        this.clockSynced = true
        this.readyHandler()
        console.info('Clock Sync: minimum response threshold achieved, ready')
      }
      // if we've received the maximum number of samples, stop syncing and reschedule
      if (
        this.offsetSampler.getSize() >= WEBSOCKET_CONFIG.MAX_SAMPLES_NUMBER
      ) {
        this.updatingClock = false
        // schedule resyncing
        setTimeout(() => {
          this.updateClock()
        }, WEBSOCKET_CONFIG.RESYNC_DELAY_MS)

        console.info(
          `Clock Sync: clock synced using ${
            WEBSOCKET_CONFIG.MAX_SAMPLES_NUMBER
          } samples, rescheduling in ${
            WEBSOCKET_CONFIG.RESYNC_DELAY_MS / 1000
          } seconds`,
        )
      }
    }
  }

  public reconnect() {
    if (this.connecting) {
      console.log('Already connecting')
    }
    this.connecting = true
    console.log('*** AP Sync Client: Reconnecting...')
    if (this.reconnectTimer) clearTimeout(this.reconnectTimer)
    this.reconnectTimeout *= 2
    if (this.client != null) {
      this.client.close()
    }
    this.client = null
    this.reconnectTimer = setTimeout(
      () => this.connect(),
      this.reconnectTimeout,
    )
    console.log(
      `*** AP Sync Client: Reconnecting in ${
        this.reconnectTimeout / 1000
      } seconds`,
    )
  }

  private socketOpenHandler = () => {
    console.log('*** AP Sync Client: Client is now connected')
    this.connecting = false
    this.reconnectTimeout = 5000
    if (this.reconnectTimer != null) {
      clearTimeout(this.reconnectTimer)
    }
    this.updateClock()
  }

  private socketErrorHandler = (err: Event) => {
    console.log('*** AP Sync Client Error: ', err)
    if (this.client != null && this.client.readyState !== WebSocket.OPEN) {
      this.reconnect()
    }
  }

  private socketCloseHandler = () => {
    console.log('*** AP Sync Client: Client Closed')
    if (!this.connecting) {
      this.reconnect()
    }
  }

  private socketMessageHandler = (msg: MessageEvent) => {
    try {
      const parsedObject = JSON.parse(msg.data)
      switch (parsedObject.type) {
        case 'sync':
          this.syncHandler(parsedObject)
          break
        case 'change':
          this.changeHandler(parsedObject)
          break
        case 'state':
          this.stateHandler(parsedObject)
          break
        case 'system':
          this.systemHandler(parsedObject)
          break
        default:
          console.log('*** AP Sync Client: Received unknown message.')
          break
      }
    } catch (e) {
      console.log('*** AP Sync Client: Failed to parse message JSON. \n', e)
    }
  }

  public connect() {
    if (this.client != null) {
      this.client.close()
      this.client = null
    }
    console.log('*** AP Sync Client: Connecting...')
    this.client = new WebSocket(this.connectionString)
    this.client.onopen = this.socketOpenHandler
    this.client.onerror = this.socketErrorHandler
    this.client.onclose = this.socketCloseHandler
    this.client.onmessage = this.socketMessageHandler
  }

  public close() {
    if (this.client != null) {
      this.client.close()
    }
  }

  getEpochTime() {
    return Date.now() + this.clockOffset
  }

  send(msg: string) {
    try {
      // console.log("*** AP Sync Client: WS Client sending:", msg);
      if (this.client != null && this.client.readyState === WebSocket.OPEN) {
        this.client.send(msg)
      } else {
        console.log('*** AP Sync Client: Connection Closed - Cannot Send')
      }
    } catch (err) {
      console.log('*** AP Sync Client: Error sending: ', err)
    }
  }
}
