import type { RefObject } from 'react'
import { useCallback, useRef, useState } from 'react'

import { ANDROID_DEGRADATION_VERSION, BLANK_TRACK } from '../constants'

import { useAppContext } from 'context/AppContext'
import { getAudioData } from 'helpers/player'
import { getAndroidVersion } from 'helpers/ua'
import type { EventAudioBuffer, EventTrack } from 'types/player'
import { PlayerState } from 'types/player'

export function useAudioBuffer(audioCtx: RefObject<AudioContext>) {
  const audioBuffer = useRef<AudioBuffer>()
  const { tracks } = useAppContext()
  const [bufferSrc, setBufferSrc] = useState<AudioBufferSourceNode>()

  const [bufferSrcArray, setBufferSrcArray] = useState<
    Array<EventAudioBuffer>
  >([])

  const [longAudioTime, setLongAudioTime] = useState<number>(0)

  const loadBuffers = async () => {
    console.log('loadBuffers', tracks)

    if (!audioCtx.current || !tracks) return

    // Enable synchronous loading for Android versions below `ANDROID_DEGRADATION_VERSION`.
    // We do this due to old android phones crashing when loading many audio buffers asynchronously.
    const androidVersion = await getAndroidVersion()
    const runSynchronous =
      androidVersion && androidVersion <= ANDROID_DEGRADATION_VERSION
    if (runSynchronous) {
      console.info(
        '📢 Running synchronously due to Android version',
        androidVersion,
      )
    }

    // Load tracks into separate buffers synchronously or asynchronously
    if (!runSynchronous) {
      const ctx = audioCtx.current

      const buffers = await Promise.all(
        tracks.map(async eventTrack => {
          console.info('Load track URL', eventTrack.url)
          const buffer = await getAudioData(ctx, eventTrack.url)
          console.info(`Track: ${eventTrack.url} loaded and stored`)

          // Get duration of buffer
          const duration = buffer.duration

          return {
            ...eventTrack,
            duration,
            buffer,
          }
        }),
      )
      return buffers
    } else {
      const buffers = []
      for (const eventTrack of tracks) {
        console.info('Load track URL', eventTrack.url)
        const buffer = await getAudioData(audioCtx.current, eventTrack.url)
        console.info(`Track: ${eventTrack.url} loaded and stored`)

        // Get duration of buffer
        const duration = buffer.duration

        buffers.push({
          ...eventTrack,
          duration,
          buffer,
        })
      }
      return buffers
    }
  }

  // Returns a buffer and the relative time position of that buffer in the long audio sequence
  const getLongAudioSequenceByTime = useCallback(
    (eventId: string, time: number) => {
      // All tracks for long audio matching the id sorted by sequence
      const tracks = bufferSrcArray
        .filter(t => t.eventId === eventId)
        .sort((a, b) => {
          return a.sequence - b.sequence
        })

      console.info(`Long audio tracks for id: ${eventId}`, tracks)

      let sequenceId = 0
      let duration = 0
      // Determine the which part of the long audio to use based on time
      for (let i = 0; i < tracks.length; i++) {
        if (time > duration && time < duration + tracks[i].duration) {
          sequenceId = tracks[i].sequence
          break
        }
        // Increase the duration if this part of the long audio has  already been passed.
        duration += tracks[i].duration
      }

      // Select the buffer for the matching sequence
      const buffer = tracks.find(t => t.sequence === sequenceId)

      return {
        timePosition: duration,
        buffer,
      }
    },
    [bufferSrcArray],
  )

  const setupBuffer = useCallback(
    async (eventTrack: EventTrack | null, time?: number) => {
      // For initial loading if we do not have a content_id that sets a track we then
      // load the blank track. This is to allow users to start playback before a show is set.
      // The blank track is a silent audio file for safety reasons only, but it will never be played.
      let track = eventTrack
      if (!track) {
        track = BLANK_TRACK
      }

      if (!audioCtx.current) return
      console.info('🚛 🎶  setupBuffer', track, audioBuffer.current, time)

      if (!audioBuffer.current && time !== undefined) {
        console.info(
          'Initial Buffer Setup',
          track.longAudio,
          track.sequence,
          time,
        )

        // Load all the available buffers
        const loadedTracks = await loadBuffers()

        if (!loadedTracks) {
          throw new Error('No buffers loaded')
        }

        // Store the alternate buffers
        setBufferSrcArray(loadedTracks)
        console.info('All Track Buffers loaded and stored')

        // Get the matching buffer to starting track
        const buffer = loadedTracks.find(
          t =>
            track.eventId === t.eventId &&
            (track.longAudio ? t.sequence === track.sequence : true), // Sequence match if a long audio track
        )?.buffer
        audioBuffer.current = buffer
      }

      if (!audioCtx.current || !audioBuffer.current) return

      // Long audio check and adjust track based on time
      if (track.longAudio && track.sequence && time) {
        // Check for correct buffer based on time for long audio
        const { buffer: longAudioBuffer, timePosition } =
          getLongAudioSequenceByTime(track.eventId, time)
        // Update buffer with long audio buffer if different
        if (longAudioBuffer && longAudioBuffer.url !== track.url) {
          audioBuffer.current = longAudioBuffer.buffer

          // store the time of the long audio segments that have played
          setLongAudioTime(timePosition)
        }
      }

      const newBufferSource = audioCtx.current.createBufferSource()
      newBufferSource.buffer = audioBuffer.current

      bufferSrc?.disconnect()
      setBufferSrc(newBufferSource)
    },
    [bufferSrcArray, tracks],
  )

  const setBuffer = useCallback(
    async (track: EventTrack, time?: number) => {
      if (!audioCtx.current || !audioBuffer.current) return

      // Long audio check and adjust track based on time
      if (track.longAudio && track.sequence && time) {
        // Check for correct buffer based on time for long audio
        const { buffer: longAudioBuffer, timePosition } =
          getLongAudioSequenceByTime(track.eventId, time)
        // Update buffer with long audio buffer if different
        if (longAudioBuffer && longAudioBuffer.url !== track.url) {
          audioBuffer.current = longAudioBuffer.buffer

          // store the time of the long audio segments that have played
          setLongAudioTime(timePosition)
        }
      }

      const newBufferSource = audioCtx.current.createBufferSource()
      newBufferSource.buffer = audioBuffer.current

      bufferSrc?.disconnect()
      setBufferSrc(newBufferSource)
    },
    [bufferSrcArray],
  )

  const replaceLongAudioBuffer = useCallback(
    (track: EventTrack, time: number, playState: PlayerState) => {
      if (!audioCtx.current) return

      console.info('Replace Long Audio Buffer', track, time)

      // Long audio check and adjust track based on time
      if (track.longAudio && track.sequence) {
        // Check for correct buffer based on time for long audio
        const { buffer: longAudioBuffer, timePosition } =
          getLongAudioSequenceByTime(track.eventId, time)

        // Update buffer with long audio buffer if different to current buffer/long buffer
        console.log(
          'longAudioBuffer.url',
          longAudioBuffer?.url,
          'track.url',
          track.url,
        )
        if (longAudioBuffer && longAudioBuffer.url !== track.url) {
          console.info('Long Audio Buffer updating')
          // Store the time of where the long audio buffer starts relative to the full long audio.
          setLongAudioTime(timePosition)

          // Create new buffer and replace the buffer
          const newBufferSource = audioCtx.current.createBufferSource()
          newBufferSource.buffer = longAudioBuffer.buffer
          audioBuffer.current = longAudioBuffer.buffer
          setBufferSrc(newBufferSource)

          // Return the matching Audio EventTrack for the newly loaded buffer.
          return tracks.find(
            t =>
              t.sequence === longAudioBuffer?.sequence &&
              t.eventId === track.eventId,
          )
        }
      }

      return
    },
    [bufferSrcArray],
  )

  const stopBuffer = () => {
    console.info('Stop Buffer')
    if (!audioCtx.current || !bufferSrc) return
    try {
      bufferSrc.stop()
    } catch (e) {
      console.log('Buffer already stop or never started', e)
    }
  }

  const replaceBuffer = useCallback(
    (eventId: string, playState: PlayerState) => {
      if (!audioCtx.current) return

      console.info('Replace Buffer')
      const buffer =
        bufferSrcArray &&
        bufferSrcArray.find(b => b.eventId === eventId)?.buffer
      if (!buffer) return

      console.info('Replacing with buffer:', buffer)

      // Stop the audio
      if (playState === PlayerState.PLAYING) stopBuffer()

      const newBufferSource = audioCtx.current.createBufferSource()
      newBufferSource.buffer = buffer

      // Reset Long audio timer
      setLongAudioTime(0)

      // Replace the buffer
      audioBuffer.current = buffer

      setBufferSrc(newBufferSource)

      console.info('Buffer replaced')
    },
    [bufferSrcArray],
  )

  return {
    bufferSrc,
    setupBuffer,
    setBuffer,
    stopBuffer,
    replaceBuffer,
    replaceLongAudioBuffer,
    getLongAudioSequenceByTime,
    longAudioTime,
    allBuffersLoaded: bufferSrcArray.length > 0,
  }
}
