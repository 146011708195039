import { forwardRef, useMemo, useState } from 'react'

export { ImageWrapper } from './styles'
export type { ImageWithFallbackProps } from './types'

import { Fallback } from './Fallback'
import { ImageWrapper, StyledLazyLoadedImage } from './styles'
import type { ImageWithFallbackProps } from './types'
import { ImageLoadState } from './types'

/**
 * This component is leveraged inside other components to load an image dynamically,
 * and fallback to an icon display if no image src is provided. It takes in optional parameters
 * to enable srcSet/sizes controlled images.
 *
 * This means we can leverage it in circumstances where an image would be used,
 * but we don't need to actually have a raster, it can simply present the fallback icon.
 *
 * If no source is passed, then it will fallback to the `fallback` prop,
 * which is either a string (eg. L1 for 'level 1') or a icon object, comprised of an
 * icon and optional icon size (defaults to medium)
 */
export const ImageWithFallback = forwardRef<
  HTMLDivElement,
  ImageWithFallbackProps
>(
  (
    {
      srcSet,
      sizes,
      src,
      fit,
      background,
      crossOrigin,
      className,
      fallback,
      width,
      height,
      alt,
      style,
      backgroundMode = 'all',
      noFallbackIcons,
    },
    ref,
  ): JSX.Element => {
    const [imgState, setImgState] = useState<ImageLoadState>(
      ImageLoadState.LOADING,
    )

    const loadHandler = (): void => {
      setImgState(ImageLoadState.LOADED)
    }

    const errorHandler = (): void => {
      // TODO: enable error logging once assets are served via orchestrated projection from the DMM.
      // captureException({ error:`[ Image ] Error loading asset from: ${imagePath}` });
      setImgState(ImageLoadState.ERROR)
    }

    const _background = useMemo(() => {
      if (!background) {
        return imgState === ImageLoadState.ERROR
          ? ImageLoadState.ERROR
          : undefined
      }

      const loaded = imgState === ImageLoadState.LOADED

      switch (backgroundMode) {
        case 'loaded-only':
          return loaded ? background : undefined
        case 'fallback-only':
          return !loaded ? background : undefined
        case 'all':
        default:
          return background
      }
    }, [background, backgroundMode, imgState])

    return (
      <ImageWrapper
        ref={ref}
        className={className}
        $background={_background}
        $loaded={imgState === ImageLoadState.LOADED}
        style={style}
      >
        {/**
         * Fallback to an icon or a string that relates to the image/list item/header in question.
         * By default, we use the media image icon.
         *
         * NOTE: Attribute order matters
         *  sizes, srcSet and src attributes must be set in that order
         *  otherwise Safari and Firefox will load
         *  the main `src` asset AND the relevant `srcSet` asset
         */}
        {src && imgState !== ImageLoadState.ERROR && (
          <StyledLazyLoadedImage
            sizes={sizes}
            srcSet={srcSet}
            src={src}
            crossOrigin={crossOrigin}
            $fit={fit}
            onLoad={loadHandler}
            onError={errorHandler}
            width={width}
            height={height}
            alt={alt}
          />
        )}
        {imgState !== ImageLoadState.LOADED && !noFallbackIcons && (
          <Fallback
            fallback={fallback}
            imageLoadError={imgState === ImageLoadState.ERROR}
          />
        )}
      </ImageWrapper>
    )
  },
)
