import { HtmlFormatting } from './styles'

import { Label, Text, Title } from 'components/typography'
import type { ComponentMap } from 'hooks/useHtmlContent'
import { useHtmlContent } from 'hooks/useHtmlContent'

type Props = {
  /**
   * The HTML enriched string to render.
   */
  value: string | undefined
  /**
   * An optional list of allowed HTML tags. If provided, only these tags will
   * be rendered. All other tags will be converted to a `span`.
   */
  allowedHtmlTags?: readonly string[]
}

/**
 * The list of allowed HTML tags for _basic formatting_, which is used in the
 * mobile VX to strip unwanted HTML tags from titles.
 *
 * This is less than would make sense for titles, but it keeps us consistent
 * with the mobile.
 */
export const BASIC_HTML_CONTENT_FORMATTING = [
  'b',
  'i',
  'em',
  'strong',
] as const

export const HTML_CONTENT_FORMATTING = [
  ...BASIC_HTML_CONTENT_FORMATTING,
  'p',
  'h3',
  'h4',
  'h5',
  'h6',
  'sup',
  'sub',
  'br',
] as const

/**
 * A mapping of elements into the typography
 */
const components: ComponentMap = {
  h3: props => <Title as="h3" $size="medium" $weight="semibold" {...props} />,
  h4: props => <Title as="h4" $size="small" $weight="semibold" {...props} />,
  h5: props => <Label as="h5" $size="large" $weight="semibold" {...props} />,
  // same as h5
  h6: props => <Label as="h6" $size="large" $weight="semibold" {...props} />,
  p: props => <Text as="p" $size="large" $weight="regular" {...props} />,
  br: () => <br />,
}

/**
 * Render a single HTML string. This is used for `html` fields, and also
 * by `html-block`.
 *
 * If you're rendering a `styled-name` or other Gazelle `html` field, this is
 * the field for you. If you're rendering a content layout, use
 * {@link ContentLayout}.
 */
export const HtmlContent = ({
  value,
  allowedHtmlTags,
}: Props): JSX.Element | null => {
  const getHtmlContent = useHtmlContent({
    allowedHtmlTags,
    components,
  })

  if (!value) return null

  const content = getHtmlContent(value)

  return <HtmlFormatting>{content}</HtmlFormatting>
}
