import { useEffect, useRef } from 'react'

import { useEffectOnce } from './useEffectOnce'

import { useSyncPlayerContext } from 'components/SyncPlayer/SyncPlayerContext'
import { getConfigData } from 'helpers/config'
import { PlayerState } from 'types/player'

export function useAnalyzer() {
  const { audioCtx, bufferSrc, state } = useSyncPlayerContext()
  const analyzer = useRef<AnalyserNode>()
  const audioConfig = getConfigData('audioConfig')

  useEffectOnce(() => {
    // Analyser setup for Vizusaliser
    if (audioConfig.showVisualizer) {
      analyzer.current = audioCtx.current.createAnalyser()
    }
  })

  useEffect(() => {
    if (
      state !== PlayerState.PLAYING ||
      !audioCtx.current ||
      !audioConfig.showVisualizer
    ) {
      return
    }

    analyzer.current = audioCtx.current.createAnalyser()
    bufferSrc?.connect(analyzer.current)
    analyzer.current.fftSize = audioConfig.fftSize
  }, [state])

  return analyzer.current
}
