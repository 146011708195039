import { useMemo } from 'react'

import intersection from 'lodash/intersection'
import { useLocation } from 'react-router-dom'

import { ButtonGroup } from './styles'

import { Button } from 'components/Button'
import { BASIC_HTML_CONTENT_FORMATTING } from 'components/HtmlContent'
import { IconExternalLinkOutline } from 'components/icons'
import { useHtmlContent } from 'hooks/useHtmlContent'
import type {
  ContentBlockProps,
  ContentBlockRegistration } from 'registrations/content-layout/content-stack'
import {
  CONTENT_BLOCK_REGISTRY,
} from 'registrations/content-layout/content-stack'
import { ExternalUrlCtaBlock } from 'schemas/ocelot-content'
import { useSettings } from 'utils/settings'

const Block = ({
  block,
  allowedHtmlTags: parentAllowedHtmlTags,
}: ContentBlockProps<ExternalUrlCtaBlock>): JSX.Element => {
  const { getTranslation } = useSettings()
  const location = useLocation()

  const allowedHtmlTags = useMemo(
    () => intersection(parentAllowedHtmlTags, BASIC_HTML_CONTENT_FORMATTING),
    [parentAllowedHtmlTags],
  )

  const getHtmlContent = useHtmlContent({
    allowedHtmlTags,
  })

  const text = getTranslation(block.text)

  const isExternal = useMemo(() => {
    const url = new URL(block.url)

    return url.origin !== window.location.origin
  }, [block.url])

  return (
    <ButtonGroup>
      <Button
        to={block.url}
        state={{ from: location }}
        target={isExternal ? '_blank' : undefined}
        size="medium"
        variant="neutral"
        Icon={isExternal ? IconExternalLinkOutline : undefined}
        iconPosition="right"
      >
        {getHtmlContent(text)}
      </Button>
    </ButtonGroup>
  )
}

CONTENT_BLOCK_REGISTRY.register(ExternalUrlCtaBlock.typename, {
  Block,
  hasContent: () => true, // required fields, always have content
} satisfies ContentBlockRegistration<ExternalUrlCtaBlock>)
