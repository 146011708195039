import { useEffect } from 'react'

import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import type { PageEventType } from './pageSchema'
import { useRudderstack } from './useRudderstack'

import { useSettings } from 'utils/settings'

const DEBOUNCE_TIMEOUT = 200 // ms

type Props = {
  /** The name of the page to track */
  pageName: PageEventType

  /**
   * If the page event is showing, should be used for modals.
   *
   * The default is to assume `isShowing` is true if there is o modal open
   * on the screen.
   */
  isShowing?: boolean
}

export const usePageEventTracking = ({
  pageName,
  isShowing = true,
  ...props
}: Props): void => {
  const { page } = useRudderstack()
  const {
    settings: { defaultLanguage },
  } = useSettings()
  const { i18n } = useTranslation()
  const location = useLocation()

  useEffect(() => {
    // Don't send the event if we're not supposed to
    if (!isShowing) return

    const source = setTimeout(() => {
      page({
        category: pageName,
        name: pageName,
        properties: {
          url: window.location.href,
          locale: i18n.language,
        },
      })
    }, DEBOUNCE_TIMEOUT)

    return () => {
      clearTimeout(source)
    }
  }, [
    // View is visible
    isShowing,
    // Router path has changed
    location.pathname,
    // Page name has changed (for multi-tabbed pages)
    pageName,
    // Language has changed
    i18n.language,
    // Static settings (won't change)
    page,
    defaultLanguage,
  ])
}
