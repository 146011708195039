import ReactGA from 'react-ga4'

import { getAnalyticsData } from './config'

const analytics = getAnalyticsData()

const isAnalyticsEnabled = !!analytics?.gaId && analytics?.enabled

export const gaInit = () => {
  if (!isAnalyticsEnabled) {
    return
  }

  console.info(`📈 GA: Init Analytics ${analytics?.gaId || ''}`)
  ReactGA.initialize(analytics?.gaId || '', {
    testMode: !import.meta.env.PROD,
  })
  ReactGA.set({
    app_name: 'AGB Events - Elevate Sydney',
  })
}

export const gaSendEvent = (name: string) => {
  console.info(`📉 GA: Send Event ${name}`)

  if (!isAnalyticsEnabled) {
    return
  }

  ReactGA.send({
    hitType: 'pageview',
    page_title: name,
  })
}

export const gaSendCustomEvent = (
  name: string,
  data: { [key: string]: unknown } = {
    app_name: 'AGB Events - Elevate Sydney',
  },
) => {
  console.info(`📉 GA: Custom Event ${name}`)
  if (!isAnalyticsEnabled) {
    return
  }
  ReactGA.event(name, data)
}

export const gaSendPageview = (path: string) => {
  console.info(`📉 GA: Send Page View ${path}`)
  if (!isAnalyticsEnabled) {
    return
  }
  ReactGA.send({
    hitType: 'pageview',
    page: path,
  })
}
