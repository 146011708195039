import styled from 'styled-components'

import { space, padding } from 'theme/utils'

export const ErrorSection = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: ${space(8)};

  height: 100%;
`

export const ErrorContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${space(2)};
`

export const ErrorNav = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;

  padding: ${padding(4, 0)};
`

export const ErrorFooter = styled.div``
