import { useMemo } from 'react'

import mapKeys from 'lodash/mapKeys'
import memoize from 'lodash/memoize'
import { useTranslation } from 'react-i18next'

/**
 * Config objects returned by {@link useSettings}.
 *
 * @category Settings
 */
export type ConfigOptions = {
  /** The default language  */
  defaultLanguage: string
  /** Other languages made available */
  languages: string[]

  /** Used for the logo click in the header on the detail/player view. */
  homepage: string
  /** UUID to force the es-config (defaults to the first config in the bundle) */
  esConfig?: string
}

type DefaultOptions = Partial<ConfigOptions>

const defaults: DefaultOptions = {}

class Settings {
  private initialized: boolean = false

  // @ts-ignore
  public options: ConfigOptions

  constructor(options: ConfigOptions) {
    if (!this.initialized) {
      this.initialized = true
      this.options = { ...defaults, ...options }
      return this
    }
    return this
  }

  public updateSettings(options: ConfigOptions): this {
    this.options = {
      ...this.options,
      ...options,
    }
    return this
  }
}

let SETTINGS: Settings

/**
 * Initialise the settings for {@link useSettings}.
 *
 * @category Settings
 */
export const setupSettings = (options: ConfigOptions): void => {
  if (SETTINGS) console.warn('Settings has already been defined')
  SETTINGS = new Settings(options)
}

/**
 * Return type for {@link useSettings}.
 *
 * @category Settings
 */
export type UseSettings = {
  /** Settings object. */
  settings: ConfigOptions

  /** Update the settings object */
  updateSettings: (options: ConfigOptions) => void

  /**
   * Get the translation for a given language.
   *
   * @param transMap translatable-map
   * @param lang language (defaults to the content language)
   * @template T type of the translatable-map
   */
  getTranslation: <T>(
    transMap: Record<string, T> | null | undefined,
  ) => T | undefined
}

/**
 * A hook to retrieve the settings from the settings file.
 *
 * Initialise the settings with {@link setupSettings}.
 *
 * @category Settings
 */
export const useSettings = (): UseSettings => {
  const {
    i18n: { language: i18nLanguage },
  } = useTranslation()

  return useMemo(() => {
    return {
      settings: SETTINGS.options,
      updateSettings: (options: ConfigOptions) =>
        SETTINGS.updateSettings(options),
      getTranslation: memoize(
        <T>(transMap: Record<string, T> | null | undefined) => {
          if (!transMap) return undefined

          const normalisedMap = mapKeys(transMap, (value, key) =>
            key.toLowerCase(),
          )

          return (
            normalisedMap[i18nLanguage.toLowerCase()] ??
            normalisedMap[SETTINGS.options.defaultLanguage.toLowerCase()]
          )
        },
      ),
    }
  }, [i18nLanguage])
}
