/* eslint-disable */
import * as z from 'zod'

import {
  Struct,
  Entity,
  Meta,
  recase,
  GazelleRef,
  GazelleRefSchema,
} from '@apsys/gazelle'
import { BundleLoader, GazelleEntityFactory, ValueOf } from '@apsys/gazelle'
import { ReffedEntity as ReffableEntity, WithRef } from '@apsys/gazelle'

import * as VixenCoreManifest from './vixen-core-manifest'

export const meta: Meta = {
  name: 'Vixen Spatial - Manifest',
  moduleName: 'vixen-spatial-manifest',
  version: '0.9.0',
  authors: ['Art Processors <developers@artprocessors.net>'],
}

// === enums ===
// === interfaces ===
// === structs ===
// === entities ===
export interface ImdfArchiveSchema
  extends VixenCoreManifest.ManifestEntrySchema {
  'venue-id': string
  'imdf-version': string
}

export interface IImdfArchive extends VixenCoreManifest.IManifestEntry {
  venueId: string
  imdfVersion: string
}

type ImdfArchiveSchemaType = z.Schema<
  ImdfArchive,
  z.ZodTypeDef,
  ImdfArchiveSchema
>

export const ImdfArchiveSchema = z
  .lazy(() =>
    z.object({
      ref: GazelleRefSchema,
      url: z.string(),
      'venue-id': z.string().nonempty(),
      'imdf-version': z.string(),
    }),
  )
  .transform(
    value => new ImdfArchive(recase(value)),
  ) satisfies ImdfArchiveSchemaType

export class ImdfArchive
  extends Entity<ImdfArchiveSchema>
  implements IImdfArchive
{
  static readonly typename = 'imdf-archive'
  static readonly schema: ImdfArchiveSchemaType = ImdfArchiveSchema
  static readonly parse = ImdfArchiveSchema.parse
  static readonly fields = ['ref', 'url', 'venue-id', 'imdf-version']

  readonly typename = 'imdf-archive'

  ref!: GazelleRef<ImdfArchive>
  url!: string
  venueId!: string
  imdfVersion!: string

  constructor(attrs: IImdfArchive & WithRef<ImdfArchive>) {
    super(attrs)
  }
}

export type INTERFACES = VixenCoreManifest.INTERFACES & {}

export const ENTITIES = {
  ...VixenCoreManifest.ENTITIES,
  'imdf-archive': ImdfArchive,
}
export type ENTITIES = {
  [K in keyof typeof ENTITIES]: InstanceType<(typeof ENTITIES)[K]>
}

export class Loader extends BundleLoader {
  static readonly ENTITIES = ENTITIES
  static readonly schemaVersion = '0.9.0'

  get<K extends keyof ENTITIES>(ref: {
    typename: K
    id: string
  }): ENTITIES[K] | undefined
  get<T extends ValueOf<ENTITIES> | ValueOf<INTERFACES> | Entity<any>>(
    ref: GazelleRef<T>,
  ): (T extends Entity<any> ? T : ReffableEntity<T> & T) | undefined
  get(ref: any) {
    return super.get(ref)
  }

  getAll<K extends keyof ENTITIES>(typename: K): Array<ENTITIES[K]>
  getAll<T extends ValueOf<ENTITIES>>(klass: GazelleEntityFactory<T>): Array<T>
  getAll(klass: any) {
    return super.getAll(klass)
  }

  getSingleton<K extends keyof ENTITIES>(typename: K): ENTITIES[K] | undefined
  getSingleton<T extends ValueOf<ENTITIES>>(
    klass: GazelleEntityFactory<T>,
  ): T | undefined
  getSingleton(klass: any) {
    return super.getSingleton(klass)
  }
}
