// GENERATED BY process-svg DO NOT EDIT
import { GeneratedIcon } from './Icon'
import type { GeneratedIconProps } from './Icon/types'
// @ts-ignore
import Svg from './svg/ic-x-close-outline.svg?react'

export const IconXCloseOutline = (props: GeneratedIconProps): JSX.Element => (
  <GeneratedIcon {...props}>
    <Svg preserveAspectRatio="xMidYMid meet" />
  </GeneratedIcon>
)

IconXCloseOutline.displayName = 'IconXCloseOutline'
