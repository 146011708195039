import type { PropsWithChildren } from 'react'
import { useState } from 'react'

import styled from 'styled-components'

import { AnimatedChildrenContainer } from './AnimatedChildrenContainer'

import { gaSendEvent } from 'helpers/analytics'
import { useEffectOnce } from 'hooks/useEffectOnce'
import { space } from 'theme/utils'

export type ScreenProps = PropsWithChildren & {
  gaEvent?: string
  className?: string
}

const AnimatedChildrenContainerStyled = styled(AnimatedChildrenContainer)`
  align-items: center;

  padding: ${space(14)} ${space(4)};

  text-align: center;

  flex-direction: column;

  gap: ${space(10)};
`

export function Screen({
  gaEvent,
  children,
  className,
}: ScreenProps): JSX.Element {
  const [loaded, setLoaded] = useState(false)

  useEffectOnce(() => {
    if (gaEvent) {
      gaSendEvent(gaEvent)
    }
    // Make sure the animations always run
    const tm = setTimeout(() => {
      setLoaded(true)
    }, 100)
    return () => clearTimeout(tm)
  })

  return (
    <AnimatedChildrenContainerStyled show={loaded} className={className}>
      {children}
    </AnimatedChildrenContainerStyled>
  )
}
