import { motion } from 'framer-motion'
import styled, { css } from 'styled-components'

import { fullMaxHeight, fullMinHeight } from 'theme/global'
import { borderRadius, color, padding, pxToRem, space } from 'theme/utils'
/**
 * Standardized breakpoints for the application.
 */
export const appBreakpointsMixin = css`
  margin: 0 auto;

  max-width: ${pxToRem(768)};
`

export const CollapsibleContainer = styled(motion.div)`
  box-sizing: border-box;
  position: relative;
  border-radius: ${borderRadius('large large none none')};
  background-color: ${color.all('onPrimary')};
  padding: ${padding(4)};
  width: 100%;

  ${appBreakpointsMixin};

  overflow: hidden;
`

/**
 * The frame of the application. This is the outermost container and should only appear once in the DOM.
 */
export const AppFrame = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: 'viewport';

  width: 100%;
  ${fullMinHeight};
  ${fullMaxHeight};
`

/**
 * The application viewport, it allows scrolling the content.
 * It is used to assist with transitioning pages and views into the visible viewport.
 * Like `AppFrame`, it should only appear once in the DOM.
 */
export const AppViewport = styled.div`
  grid-area: viewport;

  position: relative;
  flex: 1;
  width: 100%;
  height: 100%;
`

/**
 * A container for the main content of the application.
 * To be used inside of AppViewport, it limits the width of the content.
 * Like `AppFrame`, it should only appear once in the DOM.
 */
export const AppContainer = styled.main`
  position: relative;

  padding-top: ${space(16)};

  display: flex;
  flex-direction: column;
  height: 100%;

  ${appBreakpointsMixin};
`

/**
 * A container for the layout screen of content for the application.
 * To be used inside of AppContainer
 */
export const AppScreen = styled.div`
  display: grid;
  position: relative;

  // Ensures that the container takes up the space it needs to fill the viewport, but no more.
  // Without minmax, nested content may blow out the width of the container.
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: 1fr;
  height: 100%;
`
