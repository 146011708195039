/* eslint-disable */
import * as z from 'zod'

import {
  Struct,
  Entity,
  Meta,
  recase,
  GazelleRef,
  GazelleRefSchema,
} from '@apsys/gazelle'
import { BundleLoader, GazelleEntityFactory, ValueOf } from '@apsys/gazelle'
import { ReffedEntity as ReffableEntity, WithRef } from '@apsys/gazelle'

import * as VixenCore from './vixen-core'

export const meta: Meta = {
  name: 'Eileen VX Stylesheet',
  moduleName: 'eileen-schema-stylesheet',
  version: '1.0.0',
  authors: ['Art Processors <developers@artprocessors.net>'],
}

// === enums ===
// === interfaces ===
// === structs ===
// === entities ===
export interface EileenColorSchemeSchema extends VixenCore.NamedSchema {
  variants: Array<EileenColorScheme.VariantSchema>
}

export interface IEileenColorScheme extends VixenCore.INamed {
  variants: Array<EileenColorScheme.Variant>
}

type EileenColorSchemeSchemaType = z.Schema<
  EileenColorScheme,
  z.ZodTypeDef,
  EileenColorSchemeSchema
>

export const EileenColorSchemeSchema = z
  .lazy(() =>
    z.object({
      ref: GazelleRefSchema,
      name: z.record(z.string()),
      variants: z.array(EileenColorScheme.VariantSchema),
    }),
  )
  .transform(
    value => new EileenColorScheme(recase(value)),
  ) satisfies EileenColorSchemeSchemaType

export class EileenColorScheme
  extends Entity<EileenColorSchemeSchema>
  implements IEileenColorScheme
{
  static readonly typename = 'eileen-color-scheme'
  static readonly schema: EileenColorSchemeSchemaType = EileenColorSchemeSchema
  static readonly parse = EileenColorSchemeSchema.parse
  static readonly fields = ['ref', 'name', 'variants']

  readonly typename = 'eileen-color-scheme'

  ref!: GazelleRef<EileenColorScheme>
  name!: Record<string, string>
  variants!: Array<EileenColorScheme.Variant>

  constructor(attrs: IEileenColorScheme & WithRef<EileenColorScheme>) {
    super(attrs)
  }
}

export namespace EileenColorScheme {
  export interface VariantSchema {
    mode: EileenColorScheme.Mode
    'interactive-color': string
    'primary-background-color': string
    'secondary-background-color': string
    'primary-text-color': string
    'secondary-text-color': string
    'inverted-text-color': string
    'primary-fill-color': string
  }

  export interface IVariant {
    mode: EileenColorScheme.Mode
    interactiveColor: string
    primaryBackgroundColor: string
    secondaryBackgroundColor: string
    primaryTextColor: string
    secondaryTextColor: string
    invertedTextColor: string
    primaryFillColor: string
  }

  type VariantSchemaType = z.Schema<Variant, z.ZodTypeDef, VariantSchema>

  export const VariantSchema = z
    .lazy(() =>
      z.object({
        mode: z.nativeEnum(EileenColorScheme.Mode),
        'interactive-color': z.string(),
        'primary-background-color': z.string(),
        'secondary-background-color': z.string(),
        'primary-text-color': z.string(),
        'secondary-text-color': z.string(),
        'inverted-text-color': z.string(),
        'primary-fill-color': z.string(),
      }),
    )
    .transform(value => new Variant(recase(value))) satisfies VariantSchemaType

  export class Variant extends Struct<VariantSchema> implements IVariant {
    static readonly typename = 'variant'
    static readonly schema: VariantSchemaType = VariantSchema
    static readonly parse = VariantSchema.parse
    static readonly fields = [
      'mode',
      'interactive-color',
      'primary-background-color',
      'secondary-background-color',
      'primary-text-color',
      'secondary-text-color',
      'inverted-text-color',
      'primary-fill-color',
    ]

    readonly typename = 'variant'

    mode!: EileenColorScheme.Mode
    interactiveColor!: string
    primaryBackgroundColor!: string
    secondaryBackgroundColor!: string
    primaryTextColor!: string
    secondaryTextColor!: string
    invertedTextColor!: string
    primaryFillColor!: string

    constructor(attrs: IVariant) {
      super(attrs)
    }
  }

  export enum Mode {
    LIGHT = 'light',
    DARK = 'dark',
  }
}

export type INTERFACES = VixenCore.INTERFACES & {}

export const ENTITIES = {
  ...VixenCore.ENTITIES,
  'eileen-color-scheme': EileenColorScheme,
}
export type ENTITIES = {
  [K in keyof typeof ENTITIES]: InstanceType<(typeof ENTITIES)[K]>
}

export class Loader extends BundleLoader {
  static readonly ENTITIES = ENTITIES
  static readonly schemaVersion = '1.0.0'

  get<K extends keyof ENTITIES>(ref: {
    typename: K
    id: string
  }): ENTITIES[K] | undefined
  get<T extends ValueOf<ENTITIES> | ValueOf<INTERFACES> | Entity<any>>(
    ref: GazelleRef<T>,
  ): (T extends Entity<any> ? T : ReffableEntity<T> & T) | undefined
  get(ref: any) {
    return super.get(ref)
  }

  getAll<K extends keyof ENTITIES>(typename: K): Array<ENTITIES[K]>
  getAll<T extends ValueOf<ENTITIES>>(klass: GazelleEntityFactory<T>): Array<T>
  getAll(klass: any) {
    return super.getAll(klass)
  }

  getSingleton<K extends keyof ENTITIES>(typename: K): ENTITIES[K] | undefined
  getSingleton<T extends ValueOf<ENTITIES>>(
    klass: GazelleEntityFactory<T>,
  ): T | undefined
  getSingleton(klass: any) {
    return super.getSingleton(klass)
  }
}
