/* eslint-disable */
import * as z from 'zod'

import {
  Struct,
  Entity,
  Meta,
  recase,
  GazelleRef,
  GazelleRefSchema,
} from '@apsys/gazelle'
import { BundleLoader, GazelleEntityFactory, ValueOf } from '@apsys/gazelle'
import { ReffedEntity as ReffableEntity, WithRef } from '@apsys/gazelle'

import * as VixenCore from './vixen-core'
import * as VixenRules from './vixen-rules'
import * as VixenAssets from './vixen-assets'
import * as VixenSpatial from './vixen-spatial'
import * as OcelotCatalog from './ocelot-catalog'
import * as EsConfig from './eileen-sync-config'

export const meta: Meta = {
  name: 'Eileen Config',
  moduleName: 'eileen-config',
  version: '1.2.0',
  authors: ['Art Processors <developers@artprocessors.net>'],
}

// === enums ===
// === interfaces ===
// === structs ===
// === entities ===
export interface VxConfigSchema extends VixenCore.NamedSchema {
  venue: VixenSpatial.VenueRefSchema
  'visitor-map-set'?: GazelleRefSchema | null
  'debug-map-set'?: GazelleRefSchema | null
  'device-roles': Array<VxConfig.DeviceRole>
  description: Record<string, string>
  'preview-image'?: GazelleRefSchema | null
  experiences: Array<GazelleRefSchema>
  wifi?: VxConfig.WifiConfigSchema | null
  livesync?: VxConfig.LivesyncConfigSchema | null
  'feature-flags': Array<string>
  'class-name'?: string | null
  'reset-triggers': Array<GazelleRefSchema>
  'featured-tags'?: Array<GazelleRefSchema>
  'featured-features'?: Array<VixenSpatial.FeatureRefSchema>
}

export interface IVxConfig extends VixenCore.INamed {
  venue: VixenSpatial.VenueRef
  visitorMapSet?: GazelleRef<VixenSpatial.MapSet>
  debugMapSet?: GazelleRef<VixenSpatial.MapSet>
  deviceRoles: Array<VxConfig.DeviceRole>
  description: Record<string, string>
  previewImage?: GazelleRef<VixenAssets.Picture>
  experiences: Array<GazelleRef<VixenCore.ISubscribable>>
  wifi?: VxConfig.WifiConfig
  livesync?: VxConfig.LivesyncConfig
  featureFlags: Array<string>
  className?: string
  resetTriggers: Array<GazelleRef<VixenRules.ITrigger>>
  featuredTags: Array<GazelleRef<OcelotCatalog.CatalogTag>>
  featuredFeatures: Array<VixenSpatial.FeatureRef>
}

type VxConfigSchemaType = z.Schema<VxConfig, z.ZodTypeDef, VxConfigSchema>

export const VxConfigSchema = z
  .lazy(() =>
    z.object({
      ref: GazelleRefSchema,
      name: z.record(z.string()),
      venue: VixenSpatial.VenueRefSchema,
      'visitor-map-set': GazelleRefSchema.nullish(),
      'debug-map-set': GazelleRefSchema.nullish(),
      'device-roles': z.array(z.nativeEnum(VxConfig.DeviceRole)),
      description: z.record(z.string()),
      'preview-image': GazelleRefSchema.nullish(),
      experiences: z.array(GazelleRefSchema),
      wifi: VxConfig.WifiConfigSchema.nullish(),
      livesync: VxConfig.LivesyncConfigSchema.nullish(),
      'feature-flags': z.array(z.string()),
      'class-name': z.string().nullish(),
      'reset-triggers': z.array(GazelleRefSchema),
      'featured-tags': z.array(GazelleRefSchema).default([]),
      'featured-features': z.array(VixenSpatial.FeatureRefSchema).default([]),
    }),
  )
  .transform(value => new VxConfig(recase(value))) satisfies VxConfigSchemaType

export class VxConfig extends Entity<VxConfigSchema> implements IVxConfig {
  static readonly typename = 'vx-config'
  static readonly schema: VxConfigSchemaType = VxConfigSchema
  static readonly parse = VxConfigSchema.parse
  static readonly fields = [
    'ref',
    'name',
    'venue',
    'visitor-map-set',
    'debug-map-set',
    'device-roles',
    'description',
    'preview-image',
    'experiences',
    'wifi',
    'livesync',
    'feature-flags',
    'class-name',
    'reset-triggers',
    'featured-tags',
    'featured-features',
  ]

  readonly typename = 'vx-config'

  ref!: GazelleRef<VxConfig>
  name!: Record<string, string>
  venue!: VixenSpatial.VenueRef
  visitorMapSet?: GazelleRef<VixenSpatial.MapSet>
  debugMapSet?: GazelleRef<VixenSpatial.MapSet>
  deviceRoles!: Array<VxConfig.DeviceRole>
  description!: Record<string, string>
  previewImage?: GazelleRef<VixenAssets.Picture>
  experiences!: Array<GazelleRef<VixenCore.ISubscribable>>
  wifi?: VxConfig.WifiConfig
  livesync?: VxConfig.LivesyncConfig
  featureFlags!: Array<string>
  className?: string
  resetTriggers!: Array<GazelleRef<VixenRules.ITrigger>>
  featuredTags!: Array<GazelleRef<OcelotCatalog.CatalogTag>>
  featuredFeatures!: Array<VixenSpatial.FeatureRef>

  constructor(attrs: IVxConfig & WithRef<VxConfig>) {
    super(attrs)
  }
}

export namespace VxConfig {
  export interface WifiConfigSchema {
    essid: string
    bssids: Array<string>
    key?: string | null
  }

  export interface IWifiConfig {
    essid: string
    bssids: Array<string>
    key?: string
  }

  type WifiConfigSchemaType = z.Schema<
    WifiConfig,
    z.ZodTypeDef,
    WifiConfigSchema
  >

  export const WifiConfigSchema = z
    .lazy(() =>
      z.object({
        essid: z.string(),
        bssids: z.array(z.string()),
        key: z.string().nullish(),
      }),
    )
    .transform(
      value => new WifiConfig(recase(value)),
    ) satisfies WifiConfigSchemaType

  export class WifiConfig
    extends Struct<WifiConfigSchema>
    implements IWifiConfig
  {
    static readonly typename = 'wifi-config'
    static readonly schema: WifiConfigSchemaType = WifiConfigSchema
    static readonly parse = WifiConfigSchema.parse
    static readonly fields = ['essid', 'bssids', 'key']

    readonly typename = 'wifi-config'

    essid!: string
    bssids!: Array<string>
    key?: string

    constructor(attrs: IWifiConfig) {
      super(attrs)
    }
  }

  export interface LivesyncConfigSchema {
    'sync-server-url': string
  }

  export interface ILivesyncConfig {
    syncServerUrl: string
  }

  type LivesyncConfigSchemaType = z.Schema<
    LivesyncConfig,
    z.ZodTypeDef,
    LivesyncConfigSchema
  >

  export const LivesyncConfigSchema = z
    .lazy(() =>
      z.object({
        'sync-server-url': z.string(),
      }),
    )
    .transform(
      value => new LivesyncConfig(recase(value)),
    ) satisfies LivesyncConfigSchemaType

  export class LivesyncConfig
    extends Struct<LivesyncConfigSchema>
    implements ILivesyncConfig
  {
    static readonly typename = 'livesync-config'
    static readonly schema: LivesyncConfigSchemaType = LivesyncConfigSchema
    static readonly parse = LivesyncConfigSchema.parse
    static readonly fields = ['sync-server-url']

    readonly typename = 'livesync-config'

    syncServerUrl!: string

    constructor(attrs: ILivesyncConfig) {
      super(attrs)
    }
  }

  export enum DeviceRole {
    IN_HOUSE = 'in-house',
    BYOD = 'byod',
  }
}

export type INTERFACES = VixenCore.INTERFACES &
  VixenRules.INTERFACES &
  VixenAssets.INTERFACES &
  VixenSpatial.INTERFACES &
  OcelotCatalog.INTERFACES & {}

export const ENTITIES = {
  ...VixenCore.ENTITIES,
  ...VixenRules.ENTITIES,
  ...VixenAssets.ENTITIES,
  ...VixenSpatial.ENTITIES,
  ...OcelotCatalog.ENTITIES,
  ...EsConfig.ENTITIES,
  'vx-config': VxConfig,
}
export type ENTITIES = {
  [K in keyof typeof ENTITIES]: InstanceType<(typeof ENTITIES)[K]>
}

export class Loader extends BundleLoader {
  static readonly ENTITIES = ENTITIES
  static readonly schemaVersion = '1.2.0'

  get<K extends keyof ENTITIES>(ref: {
    typename: K
    id: string
  }): ENTITIES[K] | undefined
  get<T extends ValueOf<ENTITIES> | ValueOf<INTERFACES> | Entity<any>>(
    ref: GazelleRef<T>,
  ): (T extends Entity<any> ? T : ReffableEntity<T> & T) | undefined
  get(ref: any) {
    return super.get(ref)
  }

  getAll<K extends keyof ENTITIES>(typename: K): Array<ENTITIES[K]>
  getAll<T extends ValueOf<ENTITIES>>(klass: GazelleEntityFactory<T>): Array<T>
  getAll(klass: any) {
    return super.getAll(klass)
  }

  getSingleton<K extends keyof ENTITIES>(typename: K): ENTITIES[K] | undefined
  getSingleton<T extends ValueOf<ENTITIES>>(
    klass: GazelleEntityFactory<T>,
  ): T | undefined
  getSingleton(klass: any) {
    return super.getSingleton(klass)
  }
}
