import { motion } from 'framer-motion'
import styled from 'styled-components'

import { plane, space, padding, color } from 'theme/utils'

export const GradientOverlay = styled(motion.div)`
  position: absolute;
  inset: 0;
  z-index: -1;
  pointer-events: none;

  background: linear-gradient(
    to bottom,
    ${color.surface('surface0')},
    ${color.all('transparent')}
  );
`

export const BaseLayoutNav = styled.div`
  display: flex;
  // This ensures the children are spaced out to each edge of the container.
  // This should cover most cases out of the box. For more complex layouts,
  // group related elements (preferred) or overwrite this property.
  justify-content: space-between;
  align-items: center;

  position: sticky;
  top: 0;
  z-index: ${plane('overlay')};

  width: 100%;
  min-height: ${space(20)};
  padding: ${padding(6, 4, 3)};

  background: ${color.all('transparent')};
`
