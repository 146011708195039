import type { FC } from 'react'

import type { Entity } from '@apsys/gazelle'

import type { Loader as ContentLoader } from 'schemas/eileen-service'
import type { IContent } from 'schemas/vixen-core'
import { Registry } from 'utils/registry'
import type { ConfigOptions } from 'utils/settings'

/**
 * Any content layout entity (implements `content`).
 */
export type ContentLayoutEntity = Entity<any> & IContent

export type ContentLayoutProps<T extends ContentLayoutEntity> = {
  /** The layout being rendered */
  layout: T
  /** A list of allowed HTML tags (for html blocks) */
  allowedHtmlTags?: readonly string[]
}

export type HasContentFunc<T extends ContentLayoutEntity> = (props: {
  /** The contentBundle (for resolving refs) */
  contentBundle: ContentLoader
  /** The settings block */
  settings: ConfigOptions
  /** The layout being rendered */
  layout: T
}) => boolean

/**
 * A registration into {@link CONTENT_LAYOUT_REGISTRY} for a single content
 * layout.
 */
export interface ContentLayoutRegistration<T extends ContentLayoutEntity> {
  /** Component to render the layout */
  Layout: FC<ContentLayoutProps<T>>

  /** Returns true if the layout has content */
  hasContent: HasContentFunc<T>
}

/**
 * A registry of content layout types. These are types that can be used for
 * rendering a {@link ContentLayoutEntity}, such as is used in the slotted
 * content model.
 *
 * Content layouts registered into this interface should live in
 * `registrations/content-layout/`. Known layouts are `html-block` (single
 * block of content) and `content-stack` (a stack of content blocks).
 *
 * Instead of using this registry directly, you probably want
 * {@link ContentLayout}, which will render a named content slot for you.
 */
export const CONTENT_LAYOUT_REGISTRY = new Registry<
  ContentLayoutRegistration<any>
>()
