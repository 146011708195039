import type { CorePalette } from '@material/material-color-utilities'

import {
  getColor as _getColor,
  getTextColor as _getTextColor,
  getTransparentColor as _getTransparentColor,
  isGrayscale,
} from './utils'

/**
 * Base – Some bare essentials to help us when working with color.
 */
export type BaseColors = {
  inherit: string
  transparent: string
}

/**
 * Primary, Secondary, Error, etc. – Accent color roles used to emphasize or de-emphasize foreground elements.
 */
export type AccentColors = {
  primary: string
  outline: string
  outlineVariant: string
  error: string
}

/**
 * Surface – A role used for backgrounds and large, low-emphasis areas of the screen.
 */
export type SurfaceColors = {
  surface0: string
  surface1: string
  surface2: string
  transparentSurface0: string
  transparentSurface1: string
  transparentSurface2: string
  inverseSurface: string
  inverseTransparentSurface: string
  fixedSurface: string
  fixedTransparentSurface: string
  primaryContainer: string
  primaryFixed: string
  errorContainer: string
}

/**
 * OnSurface – Roles starting with this term indicate a color for text or icons on top of its paired parent color.
 * For example, on primary is used for text and icons against the primary fill/accent color.
 */
export type OnSurfaceColors = {
  onSurfaceHigh: string
  onSurfaceMedium: string
  onSurfaceLow: string
  onPrimary: string
  onPrimaryContainer: string
  onPrimaryFixed: string
  onPrimaryFixedVariant: string
  onError: string
  onErrorContainer: string
  inverseOnSurface: string
  fixedOnSurface: string
}

type PaletteColors = AccentColors & SurfaceColors & OnSurfaceColors

type ColorEngineReturn = {
  getColor: (tone: number) => string
  getInteractiveColor: (tone: number) => string
  getTextColor: (tone: number) => string
  getInteractiveTextColor: (tone: number) => string
  getTransparentColor: (tone: number, opacity: number) => string
}

const colorEngine = (
  color: string,
  interactiveColor: string,
  palette: CorePalette,
  interactivePalette: CorePalette,
): ColorEngineReturn => {
  const isColorGrayscale = isGrayscale(color)
  const isInteractiveColorGrayscale = isGrayscale(interactiveColor)

  const getColor = (tone: number): string =>
    _getColor(tone, palette.n1, isColorGrayscale)

  const getInteractiveColor = (tone: number): string =>
    _getColor(tone, interactivePalette.a1, isInteractiveColorGrayscale)

  const getTextColor = (tone: number): string =>
    _getTextColor(tone, palette.n1)

  const getInteractiveTextColor = (tone: number): string =>
    _getTextColor(tone, interactivePalette.a1)

  const getTransparentColor = (tone: number, opacity: number): string =>
    _getTransparentColor(tone, palette.n1, isColorGrayscale, opacity)

  return {
    getColor,
    getInteractiveColor,
    getTextColor,
    getInteractiveTextColor,
    getTransparentColor,
  }
}

export const darkColors = (
  color: string,
  interactiveColor: string,
  palette: CorePalette,
  interactivePalette: CorePalette,
): PaletteColors => {
  const { getColor, getInteractiveColor, getTransparentColor } = colorEngine(
    color,
    interactiveColor,
    palette,
    interactivePalette,
  )

  return {
    surface0: getColor(0),
    surface1: getColor(10),
    surface2: getColor(20),
    transparentSurface0: getTransparentColor(0, 0.6),
    transparentSurface1: getTransparentColor(0, 0.3),
    transparentSurface2: getTransparentColor(0, 0.15),
    onSurfaceHigh: getColor(100),
    onSurfaceMedium: getTransparentColor(100, 0.8),
    onSurfaceLow: getTransparentColor(100, 0.6),
    outline: getColor(60),
    outlineVariant: getColor(30),
    inverseSurface: getColor(95),
    inverseTransparentSurface: getTransparentColor(100, 0.15),
    inverseOnSurface: getColor(6),
    fixedSurface: getColor(6),
    fixedTransparentSurface: getTransparentColor(0, 0.6),
    fixedOnSurface: getColor(100),
    primary: interactiveColor,
    onPrimary: getInteractiveColor(10),
    primaryContainer: getInteractiveColor(30),
    onPrimaryContainer: getInteractiveColor(90),
    primaryFixed: getInteractiveColor(90),
    onPrimaryFixed: getInteractiveColor(10),
    onPrimaryFixedVariant: getInteractiveColor(30),
    // Static colors
    error: '#FFB5A1',
    onError: '#601400',
    errorContainer: '#882000',
    onErrorContainer: '#FFDBD1',
  }
}

export const lightColors = (
  color: string,
  interactiveColor: string,
  palette: CorePalette,
  interactivePalette: CorePalette,
): PaletteColors => {
  const { getColor, getInteractiveColor, getTransparentColor } = colorEngine(
    color,
    interactiveColor,
    palette,
    interactivePalette,
  )

  return {
    surface0: getColor(100),
    surface1: getColor(95),
    surface2: getColor(90),
    transparentSurface0: getTransparentColor(100, 0.6),
    transparentSurface1: getTransparentColor(100, 0.3),
    transparentSurface2: getTransparentColor(100, 0.15),
    onSurfaceHigh: getColor(6),
    onSurfaceMedium: getTransparentColor(6, 0.8),
    onSurfaceLow: getTransparentColor(0, 0.6),
    outline: getColor(50),
    outlineVariant: getColor(80),
    inverseSurface: getColor(6),
    inverseTransparentSurface: getTransparentColor(0, 0.15),
    inverseOnSurface: getColor(100),
    fixedSurface: getColor(6),
    fixedTransparentSurface: getTransparentColor(0, 0.6),
    fixedOnSurface: getColor(100),
    primary: interactiveColor,
    onPrimary: getInteractiveColor(100),
    primaryContainer: getInteractiveColor(90),
    onPrimaryContainer: getInteractiveColor(10),
    primaryFixed: getInteractiveColor(90),
    onPrimaryFixed: getInteractiveColor(10),
    onPrimaryFixedVariant: getInteractiveColor(30),
    // Static colors
    error: '#AA3614',
    onError: '#FFFFFF',
    errorContainer: '#FFDBD1',
    onErrorContainer: '#3B0900',
  }
}
