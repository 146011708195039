/* eslint-disable */
import * as z from 'zod'

import {
  Struct,
  Entity,
  Meta,
  recase,
  GazelleRef,
  GazelleRefSchema,
} from '@apsys/gazelle'
import { BundleLoader, GazelleEntityFactory, ValueOf } from '@apsys/gazelle'
import { ReffedEntity as ReffableEntity, WithRef } from '@apsys/gazelle'

import * as VixenCore from './vixen-core'
import * as VixenRules from './vixen-rules'
import * as VixenAssets from './vixen-assets'
import * as OcelotCatalog from './ocelot-catalog'
import * as OcelotContent from './ocelot-content'
import * as OcelotHibiki from './ocelot-hibiki'

export const meta: Meta = {
  name: 'Eileen Immersive Audio Experience',
  moduleName: 'eileen-immersive',
  version: '1.1.0',
  authors: ['Art Processors <developers@artprocessors.net>'],
}

// === enums ===
// === interfaces ===
// --- immersive-scene ---
export interface ImmersiveSceneSchema
  extends VixenCore.StyledNamedSchema,
    VixenCore.VisitableSchema {
  'class-name'?: string | null
  trigger?: GazelleRefSchema | null
  'wall-id'?: string | null
  player?: GazelleRefSchema | null
  'only-transition-from'?: Array<GazelleRefSchema> | null
  'never-transition-from'?: Array<GazelleRefSchema> | null
  'never-transition-to'?: Array<GazelleRefSchema> | null
  'only-transition-to'?: Array<GazelleRefSchema> | null
  'inbound-debounces'?: Array<ImmersiveScene.TransitionDebounceSchema>
}

export interface IImmersiveScene
  extends VixenCore.IStyledNamed,
    VixenCore.IVisitable {
  className?: string
  trigger?: GazelleRef<VixenRules.ITrigger>
  wallId?: string
  player?: GazelleRef<OcelotHibiki.IHibikiPlayer>
  onlyTransitionFrom?: Array<GazelleRef<IImmersiveScene>>
  neverTransitionFrom?: Array<GazelleRef<IImmersiveScene>>
  neverTransitionTo?: Array<GazelleRef<IImmersiveScene>>
  onlyTransitionTo?: Array<GazelleRef<IImmersiveScene>>
  inboundDebounces: Array<ImmersiveScene.TransitionDebounce>
}

export namespace ImmersiveScene {
  export interface TransitionDebounceSchema {
    source: GazelleRefSchema
    interval: number
  }

  export interface ITransitionDebounce {
    source: GazelleRef<IImmersiveScene>
    interval: number
  }

  type TransitionDebounceSchemaType = z.Schema<
    TransitionDebounce,
    z.ZodTypeDef,
    TransitionDebounceSchema
  >

  export const TransitionDebounceSchema = z
    .lazy(() =>
      z.object({
        source: GazelleRefSchema,
        interval: z.number(),
      }),
    )
    .transform(
      value => new TransitionDebounce(recase(value)),
    ) satisfies TransitionDebounceSchemaType

  export class TransitionDebounce
    extends Struct<TransitionDebounceSchema>
    implements ITransitionDebounce
  {
    static readonly typename = 'transition-debounce'
    static readonly schema: TransitionDebounceSchemaType =
      TransitionDebounceSchema
    static readonly parse = TransitionDebounceSchema.parse
    static readonly fields = ['source', 'interval']

    readonly typename = 'transition-debounce'

    source!: GazelleRef<IImmersiveScene>
    interval!: number

    constructor(attrs: ITransitionDebounce) {
      super(attrs)
    }
  }
}

// === structs ===
export interface ImmersiveObjectSchema {
  'catalog-object': GazelleRefSchema
  featured: boolean
  autoplay: boolean
  player?: GazelleRefSchema | null
  selectable: boolean
  trigger?: GazelleRefSchema | null
  interruptors: ImmersiveObject.InterruptorsSchema
}

export interface IImmersiveObject {
  catalogObject: GazelleRef<OcelotCatalog.CatalogObject>
  featured: boolean
  autoplay: boolean
  player?: GazelleRef<OcelotHibiki.IHibikiPlayer>
  selectable: boolean
  trigger?: GazelleRef<VixenRules.ITrigger>
  interruptors: ImmersiveObject.Interruptors
}

type ImmersiveObjectSchemaType = z.Schema<
  ImmersiveObject,
  z.ZodTypeDef,
  ImmersiveObjectSchema
>

export const ImmersiveObjectSchema = z
  .lazy(() =>
    z.object({
      'catalog-object': GazelleRefSchema,
      featured: z.boolean(),
      autoplay: z.boolean(),
      player: GazelleRefSchema.nullish(),
      selectable: z.boolean(),
      trigger: GazelleRefSchema.nullish(),
      interruptors: ImmersiveObject.InterruptorsSchema,
    }),
  )
  .transform(
    value => new ImmersiveObject(recase(value)),
  ) satisfies ImmersiveObjectSchemaType

export class ImmersiveObject
  extends Struct<ImmersiveObjectSchema>
  implements IImmersiveObject
{
  static readonly typename = 'immersive-object'
  static readonly schema: ImmersiveObjectSchemaType = ImmersiveObjectSchema
  static readonly parse = ImmersiveObjectSchema.parse
  static readonly fields = [
    'catalog-object',
    'featured',
    'autoplay',
    'player',
    'selectable',
    'trigger',
    'interruptors',
  ]

  readonly typename = 'immersive-object'

  catalogObject!: GazelleRef<OcelotCatalog.CatalogObject>
  featured!: boolean
  autoplay!: boolean
  player?: GazelleRef<OcelotHibiki.IHibikiPlayer>
  selectable!: boolean
  trigger?: GazelleRef<VixenRules.ITrigger>
  interruptors!: ImmersiveObject.Interruptors

  constructor(attrs: IImmersiveObject) {
    super(attrs)
  }
}

export namespace ImmersiveObject {
  export interface InterruptorsSchema {
    'player-end': boolean
    'object-triggered': boolean
    'object-trigger-end': boolean
    'scene-triggered': boolean
    'scene-trigger-end': boolean
  }

  export interface IInterruptors {
    playerEnd: boolean
    objectTriggered: boolean
    objectTriggerEnd: boolean
    sceneTriggered: boolean
    sceneTriggerEnd: boolean
  }

  type InterruptorsSchemaType = z.Schema<
    Interruptors,
    z.ZodTypeDef,
    InterruptorsSchema
  >

  export const InterruptorsSchema = z
    .lazy(() =>
      z.object({
        'player-end': z.boolean(),
        'object-triggered': z.boolean(),
        'object-trigger-end': z.boolean(),
        'scene-triggered': z.boolean(),
        'scene-trigger-end': z.boolean(),
      }),
    )
    .transform(
      value => new Interruptors(recase(value)),
    ) satisfies InterruptorsSchemaType

  export class Interruptors
    extends Struct<InterruptorsSchema>
    implements IInterruptors
  {
    static readonly typename = 'interruptors'
    static readonly schema: InterruptorsSchemaType = InterruptorsSchema
    static readonly parse = InterruptorsSchema.parse
    static readonly fields = [
      'player-end',
      'object-triggered',
      'object-trigger-end',
      'scene-triggered',
      'scene-trigger-end',
    ]

    readonly typename = 'interruptors'

    playerEnd!: boolean
    objectTriggered!: boolean
    objectTriggerEnd!: boolean
    sceneTriggered!: boolean
    sceneTriggerEnd!: boolean

    constructor(attrs: IInterruptors) {
      super(attrs)
    }
  }
}

// === entities ===
export interface ImmersiveTourSchema
  extends VixenCore.StyledNamedSchema,
    VixenCore.SubscribableSchema,
    OcelotContent.SlottedContentSchema {
  'preview-image'?: GazelleRefSchema | null
  'class-name'?: string | null
  'estimated-duration'?: number | null
  'holding-scene'?: GazelleRefSchema | null
  scenes: Array<GazelleRefSchema>
}

export interface IImmersiveTour
  extends VixenCore.IStyledNamed,
    VixenCore.ISubscribable,
    OcelotContent.ISlottedContent {
  previewImage?: GazelleRef<VixenAssets.Picture>
  className?: string
  estimatedDuration?: number
  holdingScene?: GazelleRef<IImmersiveScene>
  scenes: Array<GazelleRef<IImmersiveScene>>
}

type ImmersiveTourSchemaType = z.Schema<
  ImmersiveTour,
  z.ZodTypeDef,
  ImmersiveTourSchema
>

export const ImmersiveTourSchema = z
  .lazy(() =>
    z.object({
      ref: GazelleRefSchema,
      content: z.array(OcelotContent.SlottedContent.ContentSlotSchema),
      name: z.record(z.string()),
      'styled-name': z.record(z.string()),
      'preview-image': GazelleRefSchema.nullish(),
      'class-name': z.string().nullish(),
      'estimated-duration': z.number().int().nullish(),
      'holding-scene': GazelleRefSchema.nullish(),
      scenes: z.array(GazelleRefSchema),
    }),
  )
  .transform(
    value => new ImmersiveTour(recase(value)),
  ) satisfies ImmersiveTourSchemaType

export class ImmersiveTour
  extends Entity<ImmersiveTourSchema>
  implements IImmersiveTour
{
  static readonly typename = 'immersive-tour'
  static readonly schema: ImmersiveTourSchemaType = ImmersiveTourSchema
  static readonly parse = ImmersiveTourSchema.parse
  static readonly fields = [
    'ref',
    'content',
    'name',
    'styled-name',
    'preview-image',
    'class-name',
    'estimated-duration',
    'holding-scene',
    'scenes',
  ]

  readonly typename = 'immersive-tour'

  ref!: GazelleRef<ImmersiveTour>
  content!: Array<OcelotContent.SlottedContent.ContentSlot>
  name!: Record<string, string>
  styledName!: Record<string, string>
  previewImage?: GazelleRef<VixenAssets.Picture>
  className?: string
  estimatedDuration?: number
  holdingScene?: GazelleRef<IImmersiveScene>
  scenes!: Array<GazelleRef<IImmersiveScene>>

  constructor(attrs: IImmersiveTour & WithRef<ImmersiveTour>) {
    super(attrs)
  }
}

export interface ImmersiveOffscreenSceneSchema extends ImmersiveSceneSchema {
  player?: GazelleRefSchema | null
}

export interface IImmersiveOffscreenScene extends IImmersiveScene {
  player?: GazelleRef<OcelotHibiki.IHibikiPlayer>
}

type ImmersiveOffscreenSceneSchemaType = z.Schema<
  ImmersiveOffscreenScene,
  z.ZodTypeDef,
  ImmersiveOffscreenSceneSchema
>

export const ImmersiveOffscreenSceneSchema = z
  .lazy(() =>
    z.object({
      ref: GazelleRefSchema,
      name: z.record(z.string()),
      'styled-name': z.record(z.string()),
      'class-name': z.string().nullish(),
      trigger: GazelleRefSchema.nullish(),
      'wall-id': z.string().nullish(),
      player: GazelleRefSchema.nullish(),
      'only-transition-from': z.array(GazelleRefSchema).nullish(),
      'never-transition-from': z.array(GazelleRefSchema).nullish(),
      'never-transition-to': z.array(GazelleRefSchema).nullish(),
      'only-transition-to': z.array(GazelleRefSchema).nullish(),
      'inbound-debounces': z
        .array(ImmersiveScene.TransitionDebounceSchema)
        .default([]),
    }),
  )
  .transform(
    value => new ImmersiveOffscreenScene(recase(value)),
  ) satisfies ImmersiveOffscreenSceneSchemaType

export class ImmersiveOffscreenScene
  extends Entity<ImmersiveOffscreenSceneSchema>
  implements IImmersiveOffscreenScene
{
  static readonly typename = 'immersive-offscreen-scene'
  static readonly schema: ImmersiveOffscreenSceneSchemaType =
    ImmersiveOffscreenSceneSchema
  static readonly parse = ImmersiveOffscreenSceneSchema.parse
  static readonly fields = [
    'ref',
    'name',
    'styled-name',
    'class-name',
    'trigger',
    'wall-id',
    'player',
    'only-transition-from',
    'never-transition-from',
    'never-transition-to',
    'only-transition-to',
    'inbound-debounces',
  ]

  readonly typename = 'immersive-offscreen-scene'

  ref!: GazelleRef<ImmersiveOffscreenScene>
  name!: Record<string, string>
  styledName!: Record<string, string>
  className?: string
  trigger?: GazelleRef<VixenRules.ITrigger>
  wallId?: string
  player?: GazelleRef<OcelotHibiki.IHibikiPlayer>
  onlyTransitionFrom?: Array<GazelleRef<IImmersiveScene>>
  neverTransitionFrom?: Array<GazelleRef<IImmersiveScene>>
  neverTransitionTo?: Array<GazelleRef<IImmersiveScene>>
  onlyTransitionTo?: Array<GazelleRef<IImmersiveScene>>
  inboundDebounces!: Array<ImmersiveScene.TransitionDebounce>

  constructor(
    attrs: IImmersiveOffscreenScene & WithRef<ImmersiveOffscreenScene>,
  ) {
    super(attrs)
  }
}

export interface ImmersiveOnscreenSceneSchema
  extends ImmersiveSceneSchema,
    OcelotContent.SlottedContentSchema {
  selectable: boolean
  'preview-image'?: GazelleRefSchema | null
  'immersive-objects': Array<ImmersiveObjectSchema>
  player?: GazelleRefSchema | null
}

export interface IImmersiveOnscreenScene
  extends IImmersiveScene,
    OcelotContent.ISlottedContent {
  selectable: boolean
  previewImage?: GazelleRef<VixenAssets.Picture>
  immersiveObjects: Array<ImmersiveObject>
  player?: GazelleRef<OcelotHibiki.IHibikiPlayer>
}

type ImmersiveOnscreenSceneSchemaType = z.Schema<
  ImmersiveOnscreenScene,
  z.ZodTypeDef,
  ImmersiveOnscreenSceneSchema
>

export const ImmersiveOnscreenSceneSchema = z
  .lazy(() =>
    z.object({
      ref: GazelleRefSchema,
      content: z.array(OcelotContent.SlottedContent.ContentSlotSchema),
      name: z.record(z.string()),
      'styled-name': z.record(z.string()),
      'class-name': z.string().nullish(),
      trigger: GazelleRefSchema.nullish(),
      'wall-id': z.string().nullish(),
      player: GazelleRefSchema.nullish(),
      'only-transition-from': z.array(GazelleRefSchema).nullish(),
      'never-transition-from': z.array(GazelleRefSchema).nullish(),
      'never-transition-to': z.array(GazelleRefSchema).nullish(),
      'only-transition-to': z.array(GazelleRefSchema).nullish(),
      'inbound-debounces': z
        .array(ImmersiveScene.TransitionDebounceSchema)
        .default([]),
      selectable: z.boolean(),
      'preview-image': GazelleRefSchema.nullish(),
      'immersive-objects': z.array(ImmersiveObjectSchema),
    }),
  )
  .transform(
    value => new ImmersiveOnscreenScene(recase(value)),
  ) satisfies ImmersiveOnscreenSceneSchemaType

export class ImmersiveOnscreenScene
  extends Entity<ImmersiveOnscreenSceneSchema>
  implements IImmersiveOnscreenScene
{
  static readonly typename = 'immersive-onscreen-scene'
  static readonly schema: ImmersiveOnscreenSceneSchemaType =
    ImmersiveOnscreenSceneSchema
  static readonly parse = ImmersiveOnscreenSceneSchema.parse
  static readonly fields = [
    'ref',
    'content',
    'name',
    'styled-name',
    'class-name',
    'trigger',
    'wall-id',
    'player',
    'only-transition-from',
    'never-transition-from',
    'never-transition-to',
    'only-transition-to',
    'inbound-debounces',
    'selectable',
    'preview-image',
    'immersive-objects',
  ]

  readonly typename = 'immersive-onscreen-scene'

  ref!: GazelleRef<ImmersiveOnscreenScene>
  content!: Array<OcelotContent.SlottedContent.ContentSlot>
  name!: Record<string, string>
  styledName!: Record<string, string>
  className?: string
  trigger?: GazelleRef<VixenRules.ITrigger>
  wallId?: string
  player?: GazelleRef<OcelotHibiki.IHibikiPlayer>
  onlyTransitionFrom?: Array<GazelleRef<IImmersiveScene>>
  neverTransitionFrom?: Array<GazelleRef<IImmersiveScene>>
  neverTransitionTo?: Array<GazelleRef<IImmersiveScene>>
  onlyTransitionTo?: Array<GazelleRef<IImmersiveScene>>
  inboundDebounces!: Array<ImmersiveScene.TransitionDebounce>
  selectable!: boolean
  previewImage?: GazelleRef<VixenAssets.Picture>
  immersiveObjects!: Array<ImmersiveObject>

  constructor(
    attrs: IImmersiveOnscreenScene & WithRef<ImmersiveOnscreenScene>,
  ) {
    super(attrs)
  }
}

export interface ImmersiveOnscreenMessageSceneSchema
  extends ImmersiveSceneSchema,
    OcelotContent.SlottedContentSchema {
  message?: Record<string, string> | null
  icon?: string | null
  'preview-image'?: GazelleRefSchema | null
  player?: GazelleRefSchema | null
}

export interface IImmersiveOnscreenMessageScene
  extends IImmersiveScene,
    OcelotContent.ISlottedContent {
  message?: Record<string, string>
  icon?: string
  previewImage?: GazelleRef<VixenAssets.Picture>
  player?: GazelleRef<OcelotHibiki.IHibikiPlayer>
}

type ImmersiveOnscreenMessageSceneSchemaType = z.Schema<
  ImmersiveOnscreenMessageScene,
  z.ZodTypeDef,
  ImmersiveOnscreenMessageSceneSchema
>

export const ImmersiveOnscreenMessageSceneSchema = z
  .lazy(() =>
    z.object({
      ref: GazelleRefSchema,
      content: z.array(OcelotContent.SlottedContent.ContentSlotSchema),
      name: z.record(z.string()),
      'styled-name': z.record(z.string()),
      'class-name': z.string().nullish(),
      trigger: GazelleRefSchema.nullish(),
      'wall-id': z.string().nullish(),
      player: GazelleRefSchema.nullish(),
      'only-transition-from': z.array(GazelleRefSchema).nullish(),
      'never-transition-from': z.array(GazelleRefSchema).nullish(),
      'never-transition-to': z.array(GazelleRefSchema).nullish(),
      'only-transition-to': z.array(GazelleRefSchema).nullish(),
      'inbound-debounces': z
        .array(ImmersiveScene.TransitionDebounceSchema)
        .default([]),
      message: z.record(z.string()).nullish(),
      icon: z.string().nullish(),
      'preview-image': GazelleRefSchema.nullish(),
    }),
  )
  .transform(
    value => new ImmersiveOnscreenMessageScene(recase(value)),
  ) satisfies ImmersiveOnscreenMessageSceneSchemaType

export class ImmersiveOnscreenMessageScene
  extends Entity<ImmersiveOnscreenMessageSceneSchema>
  implements IImmersiveOnscreenMessageScene
{
  static readonly typename = 'immersive-onscreen-message-scene'
  static readonly schema: ImmersiveOnscreenMessageSceneSchemaType =
    ImmersiveOnscreenMessageSceneSchema
  static readonly parse = ImmersiveOnscreenMessageSceneSchema.parse
  static readonly fields = [
    'ref',
    'content',
    'name',
    'styled-name',
    'class-name',
    'trigger',
    'wall-id',
    'player',
    'only-transition-from',
    'never-transition-from',
    'never-transition-to',
    'only-transition-to',
    'inbound-debounces',
    'message',
    'icon',
    'preview-image',
  ]

  readonly typename = 'immersive-onscreen-message-scene'

  ref!: GazelleRef<ImmersiveOnscreenMessageScene>
  content!: Array<OcelotContent.SlottedContent.ContentSlot>
  name!: Record<string, string>
  styledName!: Record<string, string>
  className?: string
  trigger?: GazelleRef<VixenRules.ITrigger>
  wallId?: string
  player?: GazelleRef<OcelotHibiki.IHibikiPlayer>
  onlyTransitionFrom?: Array<GazelleRef<IImmersiveScene>>
  neverTransitionFrom?: Array<GazelleRef<IImmersiveScene>>
  neverTransitionTo?: Array<GazelleRef<IImmersiveScene>>
  onlyTransitionTo?: Array<GazelleRef<IImmersiveScene>>
  inboundDebounces!: Array<ImmersiveScene.TransitionDebounce>
  message?: Record<string, string>
  icon?: string
  previewImage?: GazelleRef<VixenAssets.Picture>

  constructor(
    attrs: IImmersiveOnscreenMessageScene &
      WithRef<ImmersiveOnscreenMessageScene>,
  ) {
    super(attrs)
  }
}

export type INTERFACES = VixenCore.INTERFACES &
  VixenRules.INTERFACES &
  VixenAssets.INTERFACES &
  OcelotCatalog.INTERFACES &
  OcelotContent.INTERFACES &
  OcelotHibiki.INTERFACES & {
    'immersive-scene': IImmersiveScene
  }

export const ENTITIES = {
  ...VixenCore.ENTITIES,
  ...VixenRules.ENTITIES,
  ...VixenAssets.ENTITIES,
  ...OcelotCatalog.ENTITIES,
  ...OcelotContent.ENTITIES,
  ...OcelotHibiki.ENTITIES,
  'immersive-tour': ImmersiveTour,
  'immersive-offscreen-scene': ImmersiveOffscreenScene,
  'immersive-onscreen-scene': ImmersiveOnscreenScene,
  'immersive-onscreen-message-scene': ImmersiveOnscreenMessageScene,
}
export type ENTITIES = {
  [K in keyof typeof ENTITIES]: InstanceType<(typeof ENTITIES)[K]>
}

export class Loader extends BundleLoader {
  static readonly ENTITIES = ENTITIES
  static readonly schemaVersion = '1.1.0'

  get<K extends keyof ENTITIES>(ref: {
    typename: K
    id: string
  }): ENTITIES[K] | undefined
  get<T extends ValueOf<ENTITIES> | ValueOf<INTERFACES> | Entity<any>>(
    ref: GazelleRef<T>,
  ): (T extends Entity<any> ? T : ReffableEntity<T> & T) | undefined
  get(ref: any) {
    return super.get(ref)
  }

  getAll<K extends keyof ENTITIES>(typename: K): Array<ENTITIES[K]>
  getAll<T extends ValueOf<ENTITIES>>(klass: GazelleEntityFactory<T>): Array<T>
  getAll(klass: any) {
    return super.getAll(klass)
  }

  getSingleton<K extends keyof ENTITIES>(typename: K): ENTITIES[K] | undefined
  getSingleton<T extends ValueOf<ENTITIES>>(
    klass: GazelleEntityFactory<T>,
  ): T | undefined
  getSingleton(klass: any) {
    return super.getSingleton(klass)
  }
}
