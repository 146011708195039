import { useMemo } from 'react'

import type { Transition, Variants } from 'framer-motion'
import { LayoutGroup, motion } from 'framer-motion'
import intersection from 'lodash/intersection'

import { SingleImage } from './SingleImage'
import {
  CarouselBottom,
  CarouselTopInner,
  CarouselPill,
  CarouselTop,
  CarouselWrapper,
  MotionImage,
  CarouselBottomInner,
} from './styles'
import type { HeroImageClassName, PictureProps } from './types'
import { useCarousel } from './useCarousel'

import { IconButton } from 'components/Button'
import { BASIC_HTML_CONTENT_FORMATTING } from 'components/HtmlContent'
import { IconArrowLeftOutline, IconArrowRightOutline } from 'components/icons'
import { Text } from 'components/typography'
import { useHtmlContent } from 'hooks/useHtmlContent'
import { useLocaleTranslation } from 'hooks/useLocaleTranslation'
import { useSettings } from 'utils/settings'

type Props = {
  /** The pictures for the carousel block */
  pictures: PictureProps[]

  /** The allowed HTML tags (passed in from {@link ContentLayout}) */
  allowedHtmlTags?: ReadonlyArray<string>
  /** The fit mode passed in from {@link ContentLayout} */
  fit?: HeroImageClassName
}

/** Animation variants */
const variants: Variants = {
  hidden: {
    opacity: 0,
  },

  faint: {
    opacity: 0.5,
  },

  solid: {
    opacity: 1,
  },
}

/** Animation transitions */
const transition: Transition = {
  ease: 'easeOut',
  duration: 0.3,
}

/** A dynamic layout of pictures (a carousel) */
export const DynamicBlock = ({ pictures, ...props }: Props): JSX.Element => {
  const { _ } = useLocaleTranslation('carousel')
  const { getTranslation } = useSettings()

  const {
    prev,
    next,
    farLeft,
    left,
    center,
    right,
    farRight,
    index,
    page,
    direction,
  } = useCarousel(pictures)

  const allowedHtmlTags = useMemo(
    () => intersection(props.allowedHtmlTags, BASIC_HTML_CONTENT_FORMATTING),
    [props.allowedHtmlTags],
  )
  const getHtmlContent = useHtmlContent({ allowedHtmlTags })

  return (
    <CarouselWrapper>
      <CarouselPill>
        <Text as="span" $size="small" $weight="bold">
          {page} / {pictures.length}
        </Text>
      </CarouselPill>

      <CarouselTop>
        <IconButton
          Icon={IconArrowLeftOutline}
          aria-label={_('previous.ax')}
          size="small"
          variant="neutral"
          appearance="solid"
          className="left"
          onClick={prev}
        />

        <CarouselTopInner
          aria-live="polite"
          drag="x"
          dragConstraints={{ left: 0, right: 0 }}
          dragElastic={1}
          onDragEnd={(event, { offset, velocity }) => {
            const swipe = Math.abs(offset.x) * velocity.x
            const swipeConfidenceThreshold = 10000

            if (swipe < -swipeConfidenceThreshold) {
              next()
            } else if (swipe > swipeConfidenceThreshold) {
              prev()
            }
          }}
        >
          <LayoutGroup>
            <MotionImage
              key={index - 2}
              aria-hidden
              custom={direction}
              variants={variants}
              animate="hidden"
              layout // Animate layout transitions
              transition={transition}
              onClick={prev}
            >
              <SingleImage picture={farLeft} showCaption={false} {...props} />
            </MotionImage>

            <MotionImage
              key={index - 1}
              aria-hidden
              custom={direction}
              variants={variants}
              animate="faint"
              layout // Animate layout transitions
              transition={transition}
              onClick={prev}
            >
              <SingleImage picture={left} showCaption={false} {...props} />
            </MotionImage>

            <MotionImage
              key={index}
              custom={direction}
              variants={variants}
              animate="solid"
              layout // Animate layout transitions
              transition={transition}
            >
              <SingleImage picture={center} showCaption={false} {...props} />
            </MotionImage>

            <MotionImage
              key={index + 1}
              aria-hidden
              custom={direction}
              variants={variants}
              animate="faint"
              layout // Animate layout transitions
              transition={transition}
              onClick={next}
            >
              <SingleImage picture={right} showCaption={false} {...props} />
            </MotionImage>
            <MotionImage
              key={index + 2}
              aria-hidden
              custom={direction}
              variants={variants}
              animate="hidden"
              layout // Animate layout transitions
              transition={transition}
              onClick={next}
            >
              <SingleImage picture={farRight} showCaption={false} {...props} />
            </MotionImage>
          </LayoutGroup>
        </CarouselTopInner>

        <IconButton
          Icon={IconArrowRightOutline}
          aria-label={_('next.ax')}
          size="small"
          variant="neutral"
          appearance="solid"
          className="right"
          onClick={next}
        />
      </CarouselTop>

      {/* Animated caption */}
      <CarouselBottom>
        <CarouselBottomInner aria-live="polite">
          <LayoutGroup>
            <motion.div
              key={index - 2}
              aria-hidden
              custom={direction}
              variants={variants}
              animate="hidden"
              layout
              transition={transition}
            >
              <Text $size="small" $weight="regular">
                {getHtmlContent(getTranslation(farLeft.caption))}
              </Text>
            </motion.div>

            <motion.div
              key={index - 1}
              aria-hidden
              custom={direction}
              variants={variants}
              animate="hidden"
              layout
              transition={transition}
            >
              <Text $size="small" $weight="regular">
                {getHtmlContent(getTranslation(left.caption))}
              </Text>
            </motion.div>

            <motion.div
              key={index}
              custom={direction}
              variants={variants}
              animate="solid"
              layout
              transition={transition}
            >
              <Text $size="small" $weight="regular">
                {getHtmlContent(getTranslation(center.caption))}
              </Text>
            </motion.div>

            <motion.div
              key={index + 1}
              aria-hidden
              custom={direction}
              variants={variants}
              animate="hidden"
              layout
              transition={transition}
            >
              <Text $size="small" $weight="regular">
                {getHtmlContent(getTranslation(right.caption))}
              </Text>
            </motion.div>

            <motion.div
              key={index + 2}
              aria-hidden
              custom={direction}
              variants={variants}
              animate="hidden"
              layout
              transition={transition}
            >
              <Text $size="small" $weight="regular">
                {getHtmlContent(getTranslation(farRight.caption))}
              </Text>
            </motion.div>
          </LayoutGroup>
        </CarouselBottomInner>

        {/* Render all the captions (hidden) to set the box height,
        so that the layout doesn't change size based on the captions */}
        {pictures.map(({ picture, caption }, idx) => (
          <Text
            // A picture can be repeated so include the index in the key
            // This is static data from the bundle, so including the index is
            // stable.
            key={picture.ref.id + idx}
            aria-hidden
            $size="small"
            $weight="regular"
            className="hidden"
          >
            {getHtmlContent(getTranslation(caption))}
          </Text>
        ))}
      </CarouselBottom>
    </CarouselWrapper>
  )
}
