import { useMemo } from 'react'

import { useTranslation } from 'react-i18next'

import type { GazelleRef } from '@apsys/gazelle'

import { useAppContext } from 'context/AppContext'
import type { HtmlBlock } from 'schemas/ocelot-content'
import { useSettings } from 'utils/settings'

/**
 * Take a Html block ref and return the html string.
 */
export function useHtmlBlock(
  ref: GazelleRef<HtmlBlock> | undefined,
): string | undefined {
  const { contentBundle } = useAppContext()
  const { settings } = useSettings()
  const {
    i18n: { language },
  } = useTranslation()

  return useMemo(() => {
    if (!contentBundle || !ref) return undefined

    const block = contentBundle.get(ref)

    if (!block) return undefined

    return (
      block.html[language] ?? block.html[settings.defaultLanguage] ?? undefined
    )
  }, [contentBundle, ref])
}
