import { useNavigate } from 'react-router-dom'

import { ErrorSection, ErrorContent, ErrorFooter } from './styles'

import { Button } from 'components/Button'
import { Screen } from 'components/Screen'
import { Headline, Text } from 'components/typography'
import { useLocaleTranslation } from 'hooks/useLocaleTranslation'
import { AppContainer, AppFrame, AppViewport } from 'layouts/styles'

/**
 * A 404 style view for when a route is not found.
 */
export const NotFound = (): JSX.Element => {
  const { _, t } = useLocaleTranslation('error.not-found')
  const navigate = useNavigate()

  return (
    <Screen>
      <ErrorSection>
        <ErrorContent>
          <Headline>{_('title')}</Headline>
          <Text>{_('description')}</Text>
        </ErrorContent>

        <ErrorFooter>
          <Button onClick={() => navigate('/')}>
            {t('error.home-button')}
          </Button>
        </ErrorFooter>
      </ErrorSection>
    </Screen>
  )
}

export const NotFoundFullpage = (): JSX.Element => (
  <AppFrame>
    <AppViewport>
      <AppContainer>
        <NotFound />
      </AppContainer>
    </AppViewport>
  </AppFrame>
)
