import type { HTMLMotionProps } from 'framer-motion'
import { motion } from 'framer-motion'
import styled from 'styled-components'

import { HtmlFormatting } from 'components/HtmlContent/styles'
import { CollapsibleContainer } from 'layouts/styles'
import { fontSize, padding, plane, pxToRem, space } from 'theme/utils'

export const HEADER_HEIGHT = 76

// This is is necessary because framer-motion
// overrides all of the inline styles
export const Wrapper = styled.div`
  overflow: visible;
  height: ${pxToRem(HEADER_HEIGHT)};
  max-height: ${pxToRem(HEADER_HEIGHT)};
`

export type VariantsInput = {
  isOpen: boolean
  openHeight: number
}

export const TranscriptTextWrapper = styled.div`
  * {
    text-align: left;

    p {
      font-size: ${fontSize('large')};
    }
  }
`

export const transcriptContainerAnimation: HTMLMotionProps<'div'> = {
  variants: {
    initial: ({ openHeight, isOpen }: VariantsInput) => ({
      height: pxToRem(isOpen ? openHeight : HEADER_HEIGHT),
      opacity: isOpen ? 1 : 0,
    }),

    animate: ({ openHeight, isOpen }) => ({
      height: pxToRem(isOpen ? openHeight : HEADER_HEIGHT),
      opacity: 1,
    }),

    exit: ({ openHeight, isOpen }) => ({
      height: pxToRem(isOpen ? openHeight : HEADER_HEIGHT),
      opacity: isOpen ? 1 : 0,
    }),
  },
  initial: 'initial',
  animate: 'animate',
  exit: 'exit',
  transition: {
    opacity: {
      type: 'tween',
      ease: 'easeOut',
      duration: 0.3,
    },
    height: {
      type: 'spring',
      stiffness: 300,
      damping: 28,
      mass: 1,
      ease: 'easeOut',
      when: 'beforeChildren',
    },
    // We don't want the bounce on exit.
    exit: {
      type: 'tween',
      ease: 'easeOut',
      duration: 0.3,
      when: 'afterChildren',
    },
  },
}

export const TranscriptContainer = styled(CollapsibleContainer).attrs(
  transcriptContainerAnimation,
)`
  position: absolute;
  z-index: ${plane('overlay')};
  bottom: 0;
  height: ${pxToRem(HEADER_HEIGHT)};
  /*
  left: 50%;
  transform: translateX(-50%); */
  left: 0;

  display: grid;
  grid-template-rows: auto 1fr;
  gap: ${space(4)};
  padding: 0;

  ${HtmlFormatting} {
    p {
      font-size: ${fontSize('large')};
    }
  }
`

export const HeaderWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  padding: ${padding(4, 4, 0, 4)};
`

export const ContentWrapper = styled(motion.div).attrs({
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
  transition: {
    type: 'tween',
    ease: 'easeOut',
    duration: 0.3,
  },
})`
  padding: ${padding(0, 4, 4, 4)};
  overflow: hidden auto;
`
