import styled from 'styled-components'

import { pxToRem } from 'theme/utils'

export const HtmlFormatting = styled.div`
  p,
  h3,
  h4,
  h5,
  h6 {
    &:not(:first-child),
    * {
      // Inter-paragraph spacing
      margin-top: ${pxToRem(12)};
    }
  }
`
