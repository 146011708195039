/* eslint-disable */
import * as z from 'zod'

import {
  Struct,
  Entity,
  Meta,
  recase,
  GazelleRef,
  GazelleRefSchema,
} from '@apsys/gazelle'
import { BundleLoader, GazelleEntityFactory, ValueOf } from '@apsys/gazelle'
import { ReffedEntity as ReffableEntity, WithRef } from '@apsys/gazelle'

import * as VixenCore from './vixen-core'
import * as VixenRules from './vixen-rules'
import * as VixenSpatial from './vixen-spatial'

export const meta: Meta = {
  name: 'Vixen Beacons',
  moduleName: 'vixen-beacons',
  version: '1.0.0',
  authors: ['Art Processors <developers@artprocessors.net>'],
}

// === enums ===
// === interfaces ===
// --- beacon ---
export interface BeaconSchema
  extends VixenSpatial.PositionableSchema,
    VixenCore.NamedSchema {
  'snap-to-position'?: Beacon.SnapToPositionSchema | null
}

export interface IBeacon extends VixenSpatial.IPositionable, VixenCore.INamed {
  snapToPosition?: Beacon.SnapToPosition
}

export namespace Beacon {
  export interface SnapToPositionSchema {
    'entry-distance'?: number | null
  }

  export interface ISnapToPosition {
    entryDistance?: number
  }

  type SnapToPositionSchemaType = z.Schema<
    SnapToPosition,
    z.ZodTypeDef,
    SnapToPositionSchema
  >

  export const SnapToPositionSchema = z
    .lazy(() =>
      z.object({
        'entry-distance': z.number().nullish(),
      }),
    )
    .transform(
      value => new SnapToPosition(recase(value)),
    ) satisfies SnapToPositionSchemaType

  export class SnapToPosition
    extends Struct<SnapToPositionSchema>
    implements ISnapToPosition
  {
    static readonly typename = 'snap-to-position'
    static readonly schema: SnapToPositionSchemaType = SnapToPositionSchema
    static readonly parse = SnapToPositionSchema.parse
    static readonly fields = ['entry-distance']

    readonly typename = 'snap-to-position'

    entryDistance?: number

    constructor(attrs: ISnapToPosition) {
      super(attrs)
    }
  }
}

// === structs ===
// === entities ===
export interface KontaktBeaconSchema extends BeaconSchema {
  'device-id': string
}

export interface IKontaktBeacon extends IBeacon {
  deviceId: string
}

type KontaktBeaconSchemaType = z.Schema<
  KontaktBeacon,
  z.ZodTypeDef,
  KontaktBeaconSchema
>

export const KontaktBeaconSchema = z
  .lazy(() =>
    z.object({
      ref: GazelleRefSchema,
      name: z.record(z.string()),
      position: VixenSpatial.PositionSchema.nullish(),
      'snap-to-position': Beacon.SnapToPositionSchema.nullish(),
      'device-id': z.string(),
    }),
  )
  .transform(
    value => new KontaktBeacon(recase(value)),
  ) satisfies KontaktBeaconSchemaType

export class KontaktBeacon
  extends Entity<KontaktBeaconSchema>
  implements IKontaktBeacon
{
  static readonly typename = 'kontakt-beacon'
  static readonly schema: KontaktBeaconSchemaType = KontaktBeaconSchema
  static readonly parse = KontaktBeaconSchema.parse
  static readonly fields = [
    'ref',
    'name',
    'position',
    'snap-to-position',
    'device-id',
  ]

  readonly typename = 'kontakt-beacon'

  ref!: GazelleRef<KontaktBeacon>
  name!: Record<string, string>
  position?: VixenSpatial.Position
  snapToPosition?: Beacon.SnapToPosition
  deviceId!: string

  constructor(attrs: IKontaktBeacon & WithRef<KontaktBeacon>) {
    super(attrs)
  }
}

export interface IbeaconSchema extends BeaconSchema {
  uuid: string
  major: number
  minor: number
}

export interface IIbeacon extends IBeacon {
  uuid: string
  major: number
  minor: number
}

type IbeaconSchemaType = z.Schema<Ibeacon, z.ZodTypeDef, IbeaconSchema>

export const IbeaconSchema = z
  .lazy(() =>
    z.object({
      ref: GazelleRefSchema,
      name: z.record(z.string()),
      position: VixenSpatial.PositionSchema.nullish(),
      'snap-to-position': Beacon.SnapToPositionSchema.nullish(),
      uuid: z.string().nonempty(),
      major: z.number().int(),
      minor: z.number().int(),
    }),
  )
  .transform(value => new Ibeacon(recase(value))) satisfies IbeaconSchemaType

export class Ibeacon extends Entity<IbeaconSchema> implements IIbeacon {
  static readonly typename = 'ibeacon'
  static readonly schema: IbeaconSchemaType = IbeaconSchema
  static readonly parse = IbeaconSchema.parse
  static readonly fields = [
    'ref',
    'name',
    'position',
    'snap-to-position',
    'uuid',
    'major',
    'minor',
  ]

  readonly typename = 'ibeacon'

  ref!: GazelleRef<Ibeacon>
  name!: Record<string, string>
  position?: VixenSpatial.Position
  snapToPosition?: Beacon.SnapToPosition
  uuid!: string
  major!: number
  minor!: number

  constructor(attrs: IIbeacon & WithRef<Ibeacon>) {
    super(attrs)
  }
}

export interface BeaconTriggerSchema extends VixenRules.TriggerSchema {
  beacon: GazelleRefSchema
  'entry-distance': number
  'exit-distance'?: number | null
}

export interface IBeaconTrigger extends VixenRules.ITrigger {
  beacon: GazelleRef<IBeacon>
  entryDistance: number
  exitDistance?: number
}

type BeaconTriggerSchemaType = z.Schema<
  BeaconTrigger,
  z.ZodTypeDef,
  BeaconTriggerSchema
>

export const BeaconTriggerSchema = z
  .lazy(() =>
    z.object({
      ref: GazelleRefSchema,
      'leading-debounce': z.number(),
      'trailing-debounce': z.number(),
      beacon: GazelleRefSchema,
      'entry-distance': z.number(),
      'exit-distance': z.number().nullish(),
    }),
  )
  .transform(
    value => new BeaconTrigger(recase(value)),
  ) satisfies BeaconTriggerSchemaType

export class BeaconTrigger
  extends Entity<BeaconTriggerSchema>
  implements IBeaconTrigger
{
  static readonly typename = 'beacon-trigger'
  static readonly schema: BeaconTriggerSchemaType = BeaconTriggerSchema
  static readonly parse = BeaconTriggerSchema.parse
  static readonly fields = [
    'ref',
    'leading-debounce',
    'trailing-debounce',
    'beacon',
    'entry-distance',
    'exit-distance',
  ]

  readonly typename = 'beacon-trigger'

  ref!: GazelleRef<BeaconTrigger>
  leadingDebounce!: number
  trailingDebounce!: number
  beacon!: GazelleRef<IBeacon>
  entryDistance!: number
  exitDistance?: number

  constructor(attrs: IBeaconTrigger & WithRef<BeaconTrigger>) {
    super(attrs)
  }
}

export type INTERFACES = VixenCore.INTERFACES &
  VixenRules.INTERFACES &
  VixenSpatial.INTERFACES & {
    beacon: IBeacon
  }

export const ENTITIES = {
  ...VixenCore.ENTITIES,
  ...VixenRules.ENTITIES,
  ...VixenSpatial.ENTITIES,
  'kontakt-beacon': KontaktBeacon,
  ibeacon: Ibeacon,
  'beacon-trigger': BeaconTrigger,
}
export type ENTITIES = {
  [K in keyof typeof ENTITIES]: InstanceType<(typeof ENTITIES)[K]>
}

export class Loader extends BundleLoader {
  static readonly ENTITIES = ENTITIES
  static readonly schemaVersion = '1.0.0'

  get<K extends keyof ENTITIES>(ref: {
    typename: K
    id: string
  }): ENTITIES[K] | undefined
  get<T extends ValueOf<ENTITIES> | ValueOf<INTERFACES> | Entity<any>>(
    ref: GazelleRef<T>,
  ): (T extends Entity<any> ? T : ReffableEntity<T> & T) | undefined
  get(ref: any) {
    return super.get(ref)
  }

  getAll<K extends keyof ENTITIES>(typename: K): Array<ENTITIES[K]>
  getAll<T extends ValueOf<ENTITIES>>(klass: GazelleEntityFactory<T>): Array<T>
  getAll(klass: any) {
    return super.getAll(klass)
  }

  getSingleton<K extends keyof ENTITIES>(typename: K): ENTITIES[K] | undefined
  getSingleton<T extends ValueOf<ENTITIES>>(
    klass: GazelleEntityFactory<T>,
  ): T | undefined
  getSingleton(klass: any) {
    return super.getSingleton(klass)
  }
}
