import { SingleImage } from './SingleImage'
import { ImageGrid } from './styles'
import type { HeroImageClassName, PictureProps } from './types'

type Props = {
  picture1: PictureProps
  picture2?: PictureProps

  allowedHtmlTags?: ReadonlyArray<string>
  fit?: HeroImageClassName
}

/** A static layout of pictures (not a carousel) */
export const StaticBlock = ({
  picture1,
  picture2,
  ...props
}: Props): JSX.Element => {
  return (
    <ImageGrid>
      <SingleImage picture={picture1} {...props} />

      {picture2 && <SingleImage picture={picture2} {...props} />}
    </ImageGrid>
  )
}
